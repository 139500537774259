export interface IFormData {
  name: string;
  email: string;
  phone: string;
  subject: string;
  requestType: string;
  priority: string;
  type: string;
  dueDate: string;
  comments: string;
}

export const defaultFormData: IFormData = {
  name: '',
  email: '',
  phone: '',
  subject: '',
  requestType: '',
  priority: '',
  dueDate: '',
  type: '',
  comments: '',
};

export interface IFormErrors {
  name: string;
  email: string;
  phone: string;
  subject: string;
  priority: string;
  type: string;
  dueDate: string;
  requestType: string;
  comments: string;
  fileDrop: string;
}

export const defaultFormErrors: IFormErrors = {
  name: '',
  email: '',
  phone: '',
  subject: '',
  priority: '',
  type: '',
  dueDate: '',
  requestType: '',
  comments: '',
  fileDrop: '',
};

export const fakeFormData: IFormData = {
  name: 'Mark Richman',
  email: 'sierra@iceboxmail.com',
  phone: '123-123-1234',
  subject: 'TEST - Feel free to Delete me - Sorry for the disturbance',
  requestType: 'recurring',
  priority: 'High',
  dueDate: '2020-07-28',
  type: '',
  comments: `I can show you the world.
  Shining, shimmering, splendid.
  Tell me, princess, now when did.
  You last let your heart decide?
  I can open your eyes.
  Take you wonder by wonder.
  Over, sideways and under.
  On a magic carpet ride.`,
};
