import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import DropdownField from '../../../components/form/DropdownField';
import InputTextField from '../../../components/form/InputTextField';
import { IDropDownFieldData } from './Interfaces';
import IGetNameAndIdResponse from '@theicebox/external-api-integrations-interfaces/distInterfaces/business_layer/interfaces/Compass/IGetNameAndIdResponse';
import IGetSiteSettingShipViasResponse from '@theicebox/external-api-integrations-interfaces/distInterfaces/business_layer/interfaces/Compass/IGetSiteSettingShipViasResponse';
import ISetSiteSettings from '@theicebox/external-api-integrations-interfaces/distInterfaces/interfaces/compass/ISetSiteSettings';
import IGetSiteSettingsResponse from '@theicebox/external-api-integrations-interfaces/distInterfaces/business_layer/interfaces/Compass/IGetSiteSettingsResponse';
import { getApi } from '../../../shared/endpointAccess/helper';
import { ToastContainer, toast } from 'react-toastify';
import { serverURL } from '../../../shared/utilities';
import 'react-toastify/dist/ReactToastify.css';
import jwt from 'jsonwebtoken';
import './SettingsPage.scss';
import '../../../components/form/form.scss';

const SettingsPage: React.FC = () => {
  const [settingsResponse, setSettingsResponse] = useState<IGetSiteSettingsResponse>();
  const [catalogResponse, setCatalogResponse] = useState<IDropDownFieldData[]>([]);
  const [locationsResponse, setLocationResponse] = useState<IDropDownFieldData[]>([]);
  const [salesResponse, setSalesResponse] = useState<IDropDownFieldData[]>([]);
  const [sitesResponse, setSitesResponse] = useState<IDropDownFieldData[]>([]);
  const [siteSurveysResponse, setSiteSurveysResponse] = useState<IDropDownFieldData[]>([]);
  const [orderClassesResponse, setOrderClassesResponse] = useState<IDropDownFieldData[]>([]);
  const [defaultShipViaChargeTypeResponse, setdefaultShipViaChargeTypeResponse] = useState<IDropDownFieldData[]>([]);
  const [PaymentTermsResponse, setPaymentTermsResponse] = useState<IDropDownFieldData[]>([]);
  const [getSiteSettingShipViasResponse, setGetSiteSettingShipViasResponse] = useState<IDropDownFieldData[]>([]);
  const [WebSiteClientGroupsResponse, setWebSiteClientGroupsResponse] = useState<IDropDownFieldData[]>([]);
  const [branchsResponse, setBranchsResponse] = useState<IDropDownFieldData[]>([]);
  const [merchantsResponse, setMerchantsResponse] = useState<IDropDownFieldData[]>([]);

  const [siteId, setSiteId] = useState<string>('');
  const [formData, setFormData] = useState<Partial<ISetSiteSettings>>({});
  const notify = (): number | string => toast.success('Your Changes Have Been Saved');
  const handleSiteChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    propertyToChange: string,
  ): void => {
    setFormData({ ...formData, [propertyToChange]: e.target.value });
  };

  const setZero = (value: string): string => {
    return value == '0' ? '' : value;
  };

  const getSiteCatalogs = (): void => {
    getApi('compass/sites/catalogs').then((Data: IGetNameAndIdResponse[]) => {
      const newArray = Data.map((siteInfo) => {
        const siteCatalog = { id: siteInfo.Id, value: siteInfo.Name };
        return siteCatalog;
      });
      setCatalogResponse(newArray);
      console.log(newArray);
    });
  };

  const getSites = (): void => {
    getApi('compass/sites').then((Data: IGetNameAndIdResponse[]) => {
      const newArray = Data.map((siteInfo) => {
        const siteDetails = { id: siteInfo.Id, value: siteInfo.Name };
        return siteDetails;
      });
      setSitesResponse(newArray);
    });
  };

  const getOrderClasses = (): void => {
    getApi('compass/sites/orderclasses').then((Data: IGetNameAndIdResponse[]) => {
      const newArray = Data.map((siteInfo) => {
        const siteDetails = { id: siteInfo.Id, value: siteInfo.Name };
        return siteDetails;
      });
      setOrderClassesResponse(newArray);
    });
  };

  const getWebSiteClientGroups = (): void => {
    getApi('compass/sites/clientGroups').then((Data: IGetNameAndIdResponse[]) => {
      const newArray = Data.map((siteInfo) => {
        const siteDetails = { id: siteInfo.Id, value: siteInfo.Name };
        return siteDetails;
      });
      setWebSiteClientGroupsResponse(newArray);
    });
  };

  const getBranches = (): void => {
    getApi('compass/sites/branches').then((data: IGetNameAndIdResponse[]) => {
      const branchData = data.map((siteInfo) => ({ id: siteInfo.Id, value: siteInfo.Name }));
      setBranchsResponse(branchData);
    });
  };

  const getMerchants = (): void => {
    getApi('compass/sites/merchants').then((data: IGetNameAndIdResponse[]) => {
      const merchantsData = data.map((siteInfo) => ({ id: siteInfo.Id, value: siteInfo.Name }));
      setMerchantsResponse(merchantsData);
    });
  };

  useEffect(() => {
    getSites();
  }, []);

  const getSiteLocations = (): void => {
    getApi('compass/sites/inventorylocations').then((Data: IGetNameAndIdResponse[]) => {
      const newArray = Data.map((siteInfo) => {
        const siteDetails = { id: siteInfo.Id, value: siteInfo.Name };
        return siteDetails;
      });
      setLocationResponse(newArray);
    });
  };
  const getSalesReps = (): void => {
    getApi('compass/sites/salesReps').then((Data: IGetNameAndIdResponse[]) => {
      const newArray = Data.map((siteInfo) => {
        const siteDetails = { id: siteInfo.Id, value: siteInfo.Name };
        return siteDetails;
      });
      setSalesResponse(newArray);
    });
  };
  const getSiteSurveys = (): void => {
    getApi('compass/sites/siteSurveys').then((Data: IGetNameAndIdResponse[]) => {
      const newArray = Data.map((siteInfo) => {
        const siteDetails = { id: siteInfo.Id, value: siteInfo.Name };
        return siteDetails;
      });
      setSiteSurveysResponse(newArray);
    });
  };

  const defaultShipViaChargeType = (): void => {
    getApi('compass/sites/shippingchargetypes').then((Data: IGetNameAndIdResponse[]) => {
      const newArray = Data.map((siteInfo) => {
        const siteDetails = { id: siteInfo.Id, value: siteInfo.Name };
        return siteDetails;
      });
      setdefaultShipViaChargeTypeResponse(newArray);
    });
  };

  const getSiteSettingShipVias = (): void => {
    getApi(`compass/sites/${siteId}/sitesettingshipvias`).then((Data: IGetSiteSettingShipViasResponse[]) => {
      const newArray = Data.map((siteInfo) => {
        const siteDetails = { id: siteInfo.ShipViaId, value: siteInfo.ShipViaName };
        return siteDetails;
      });
      setGetSiteSettingShipViasResponse(newArray);
    });
  };

  const getPaymentTerms = (): void => {
    getApi(`compass/sites/${siteId}/paymentterms`).then((Data: IGetNameAndIdResponse[]) => {
      const newArray = Data.map((siteInfo) => {
        const siteDetails = { id: siteInfo.Id, value: siteInfo.Name };
        return siteDetails;
      });
      setPaymentTermsResponse(newArray);
    });
  };

  const getSiteSettings = (): void => {
    fetch(`${serverURL}/api/compass/sites/${siteId}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt.sign({}, process.env.REACT_APP_SERVER_SECRET as string, { expiresIn: '5m' })}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          return res.json().then((data) => {
            throw new Error(data.error);
          });
        }
        res.json().then((Data) => {
          console.log(Data);
          setSettingsResponse(Data);
        });
        setFormData({});
      })
      .catch((error) => {
        throw `Something went wrong... Error: ${error.message}`;
      });
    getSiteCatalogs();
    getSiteLocations();
    getSalesReps();
    getOrderClasses();
    getSiteSurveys();
    defaultShipViaChargeType();
    getSiteSettingShipVias();
    getPaymentTerms();
    getWebSiteClientGroups();
    getBranches();
    getMerchants();
  };

  useEffect(() => {
    if (siteId !== '') getSiteSettings();
  }, [siteId]);

  const postSiteSettings = (): void => {
    console.log(formData);
    fetch(`${serverURL}/api/compass/sites/${siteId}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt.sign({}, process.env.REACT_APP_SERVER_SECRET as string, { expiresIn: '5m' })}`,
      },
      body: JSON.stringify(formData),
    })
      .then((res) => {
        console.log(res);
        if (!res.ok) {
          return res.json().then((data) => {
            throw new Error(data.error);
          });
        }
        notify();
        console.log(res.ok);
      })
      .catch((error) => {
        throw `Something went wrong... Error: ${error.message}`;
      });
  };

  return (
    <>
      <div className="site-container">
        <div className="site-settings-group">
          <div className="site-settings-header">Site Settings</div>
          <DropdownField
            label="Select A Site:"
            placeholder="Site Name"
            value={siteId}
            handleChange={(e): void => setSiteId(e.target.value)}
            options={sitesResponse}
            error={''}
            isFull
          />
        </div>
        {!settingsResponse?.SiteName && (
          <div className="site-settings-carts">
            <div className="fa fa fa-cog"></div>
          </div>
        )}
        {settingsResponse?.SiteName && (
          <div className="form-container">
            <div className="form-title">Edit Site Settings</div>
            <div className="field-container">
              <InputTextField
                handleChange={(e): void => handleSiteChange(e, 'SiteName')}
                label="Name:"
                value={formData.SiteName || settingsResponse.SiteName}
                error={''}
              />
              <DropdownField
                label="Catalog:"
                value={formData.CatalogId || settingsResponse.CatalogId}
                handleChange={(e): void => handleSiteChange(e, 'CatalogId')}
                options={catalogResponse}
                error={''}
              />
              <InputTextField
                handleChange={(e): void => handleSiteChange(e, 'SiteEmail')}
                label="Site Email:"
                value={formData.SiteEmail || settingsResponse.SiteEmail}
                error={''}
              />
              <InputTextField
                handleChange={(e): void => handleSiteChange(e, 'URL')}
                label="Site Url:"
                value={formData.URL || settingsResponse.URL}
                error={''}
              />
              <DropdownField
                label="Default Sales Rep:"
                value={formData.DefaultSalesRepId || settingsResponse.DefaultSalesRepId}
                handleChange={(e): void => handleSiteChange(e, 'DefaultSalesRepId')}
                options={salesResponse}
                error={''}
              />
              <DropdownField
                label="Location:"
                value={formData.LocationId || settingsResponse.LocationId}
                handleChange={(e): void => handleSiteChange(e, 'LocationId')}
                options={locationsResponse}
                error={''}
              />
              <DropdownField
                label="Order Class:"
                value={formData.OrderClassId || settingsResponse.OrderClassId}
                handleChange={(e): void => handleSiteChange(e, 'OrderClassId')}
                options={orderClassesResponse}
                error={''}
              />
              <DropdownField
                label="Stock Back Order Rule?"
                value={formData.StockBackOrderRule || settingsResponse.StockBackOrderRule}
                handleChange={(e): void => handleSiteChange(e, 'StockBackOrderRule')}
                options={[
                  { id: '1', value: 'Yes' },
                  { id: '2', value: 'No' },
                ]}
                error={''}
              />
              <DropdownField
                label="Site Survey:"
                value={setZero(formData.SiteSurveyId || settingsResponse.SiteSurveyId)}
                handleChange={(e): void => handleSiteChange(e, 'SiteSurveyId')}
                options={siteSurveysResponse}
                error={''}
                placeholder="No Survey"
              />
              <DropdownField
                label="Default Ship Via:"
                value={formData.DefaultShipViaId || settingsResponse.DefaultShipViaId}
                handleChange={(e): void => handleSiteChange(e, 'DefaultShipViaId')}
                options={getSiteSettingShipViasResponse}
                error={''}
              />
              <DropdownField
                label="Default Ship Via Charge Type:"
                value={
                  formData.DefaultShipViaShippingChargeTypeId || settingsResponse.DefaultShipViaShippingChargeTypeId
                }
                handleChange={(e): void => handleSiteChange(e, 'DefaultShipViaShippingChargeTypeId')}
                options={defaultShipViaChargeTypeResponse}
                error={''}
              />
              <DropdownField
                label="Submit Sales Orders:"
                value={formData.SubmitSalesOrders || settingsResponse.SubmitSalesOrders}
                handleChange={(e): void => handleSiteChange(e, 'SubmitSalesOrders')}
                options={[
                  { id: '1', value: 'Yes' },
                  { id: '2', value: 'No' },
                ]}
                error={''}
              />
              <DropdownField
                label="Custom Payment List:"
                value={formData.CustomPaymentList || settingsResponse.CustomPaymentList}
                handleChange={(e): void => handleSiteChange(e, 'CustomPaymentList')}
                options={[
                  { id: '1', value: 'Yes' },
                  { id: '2', value: 'No' },
                ]}
                error={''}
              />
              <DropdownField
                className={cx({
                  'grey-out': formData.CustomPaymentList == '2' || settingsResponse.CustomPaymentList == '2',
                })}
                label="Custom Payment List Default:"
                value={formData.CustomPaymentListDefaultId || settingsResponse.CustomPaymentListDefaultId}
                handleChange={(e): void => handleSiteChange(e, 'CustomPaymentListDefaultId')}
                options={PaymentTermsResponse}
                error={''}
              />
              <DropdownField
                label="Client Group:"
                value={setZero(formData.ClientGroupId || settingsResponse.ClientGroupId)}
                handleChange={(e): void => handleSiteChange(e, 'ClientGroupId')}
                options={WebSiteClientGroupsResponse}
                error={''}
                placeholder="No Client Group"
              />
              <DropdownField
                label="Branches:"
                value={setZero(formData.Branch || settingsResponse.Branch)}
                handleChange={(e): void => handleSiteChange(e, 'Branch')}
                options={branchsResponse}
                error={''}
                placeholder="No Branch"
              />
              <DropdownField
                label="Payment Gateway Merchant Account:"
                value={setZero(
                  formData.PaymentGatewayMerchantAccountId || settingsResponse.PaymentGatewayMerchantAccountId,
                )}
                handleChange={(e): void => handleSiteChange(e, 'PaymentGatewayMerchantAccountId')}
                options={merchantsResponse}
                error={''}
                placeholder="No Merchants"
              />
            </div>
            <ToastContainer />
            <button className="site-submit-button" onClick={postSiteSettings}>
              Submit Changes
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default SettingsPage;
