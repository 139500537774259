import React from 'react';
import { Link } from 'react-router-dom';
import bg from '../../../images/drawn-bg.png';
import '../RequestsDefaultPage/RequestsDefaultPage.scss';

const RequestsPage: React.FC = () => {
  return (
    <>
      <div className="bg-div" style={{ backgroundImage: `url(${bg})` }}></div>
      <div className="services-container">
        <div className="services-group">
          <Link to="/requests/art">
            <button className="service-btn">
              <i className="fa fa-paint-brush fa-10x requests-icon" />
              <span>Art Requests</span>
            </button>
          </Link>
          <Link to="/requests/flyer">
            <button className="service-btn">
              <i className="fa fa-file-pdf-o fa-10x requests-icon" />
              <span>Flyer Requests</span>
            </button>
          </Link>
          {/* <Link to="/requests/it">
            <button className="service-btn">
              <i className="fa fa-laptop fa-10x requests-icon" />
              <span>IT Requests</span>
            </button>
          </Link> */}
          <Link to="/requests/report">
            <button className="service-btn">
              <i className="fa fa-line-chart fa-10x requests-icon" />
              <span>Reports Requests</span>
            </button>
          </Link>
          <Link to="/requests/sales">
            <button className="service-btn">
              <i className="fa fa-briefcase fa-10x requests-icon" />
              <span>Sales Requests</span>
            </button>
          </Link>
          <Link to="/requests/webart">
            <button className="service-btn">
              <i className="fa fa-camera-retro fa-10x requests-icon" />
              <span>Web Image Requests</span>
            </button>
          </Link>
          <Link to="/requests/web">
            <button className="service-btn">
              <i className="fa fa-window-restore fa-10x requests-icon" />
              <span>Web Requests</span>
            </button>
          </Link>
          <a href="https://cjb5grrt.paperform.co/" target="_blank" rel="noreferrer">
            <button className="service-btn">
              <i className="fa fa-shopping-bag fa-10x requests-icon" />
              <span>Products Team Requests</span>
            </button>
          </a>
        </div>
      </div>
    </>
  );
};

export default RequestsPage;
