import React from 'react';
import Field from './Field/Field';

interface IProps {
  automationId?: string; // todo: make this not optional
  className?: string;
  label: string;
  error?: string;
  placeholder?: string;
  value: string;
  isFull?: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputDateField: React.FC<IProps> = (props) => {
  return (
    <Field className={props.className} isFull={props.isFull} label={props.label} error={props.error}>
      <input
        className="field-input"
        placeholder={props.placeholder}
        type="date"
        onChange={props.handleChange}
        value={props.value}
      />
    </Field>
  );
};

export default InputDateField;
