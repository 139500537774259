export const nameObject = {
  penguinNameObject: {
    a: 'Pecky',
    b: 'Chubbers',
    c: 'Beaky',
    d: 'Fishbreath',
    e: 'Fuzzbutt',
    f: 'Bigbeak',
    g: 'Pebbles',
    h: 'Fishcakes',
    i: 'Tumtums',
    j: 'Shrimpy',
    k: 'Featherface',
    l: 'Honkers',
    m: 'Blubbers',
    n: 'Squawky',
    o: 'Waddles',
    p: 'Puddles',
    q: 'Squidhead',
    r: 'Flippers',
    s: 'Fishface',
    t: 'Squeaks',
    u: 'Hoppy',
    v: 'Wobbles',
    w: 'Flappity',
    x: 'Chinstrap',
    y: 'Feathers',
    z: 'Stubby',
  } as { [letter: string]: string },
  penguinLastNameObject: {
    a: 'Flapperson',
    b: 'Waddlebottom',
    c: 'Flapdoodle',
    d: 'Hoppington',
    e: 'Beakerson',
    f: 'Squeakybeak',
    g: 'Krillington',
    h: 'Featherbottom',
    i: 'Beady Eyes',
    j: 'Roly Poly',
    k: 'Bellyflop',
    l: 'Peckington',
    m: 'Pecks-a-lot',
    n: 'Squidbreath',
    o: 'Squawksalot',
    p: 'Fanyflippers',
    q: 'Hopsalot',
    r: 'Flappyfeet',
    s: 'Blubberbuns',
    t: 'Pebblepincher',
    u: 'Wigglefeet',
    v: 'Flapperson',
    w: 'Snowballs',
    x: 'Swimmington',
    y: 'Flipperstein',
    z: 'Slip n Slide',
  } as { [letter: string]: string },
};
