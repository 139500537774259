import React from 'react';
import Field from '../Field/Field';
import RemoveableList from '../RemoveableList/RemoveableList';
import './ListItemField.scss';

interface IProps {
  error?: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  placeholder?: string;
  isFull?: boolean;
  listToDisplay: Array<any>;
  handleRemoveListItem: (item: string) => void;
  handleAddToList: () => void;
  value: any;
}

const ListItemField: React.FC<IProps> = (props) => {
  return (
    <Field isFull={props.isFull} label={props.label} error={props.error}>
      <div className="field-input-button-combo">
        <input
          className="field-input combo-input"
          value={props.value}
          placeholder={props.placeholder}
          type="text"
          onChange={props.handleChange}
          onKeyDown={(e): void => {
            if ((e.key === 'NumpadEnter' || e.key === 'Enter') && props.value) {
              props.handleAddToList();
            }
          }}
        />
        <button className="field-input combo-button" disabled={!props.value} onClick={props.handleAddToList}>
          Add
        </button>
      </div>
      <RemoveableList listToDisplay={props.listToDisplay} handleRemoveListItem={props.handleRemoveListItem} />
    </Field>
  );
};

export default ListItemField;
