import React from 'react';
import { useGlobalError } from '../../providers/ErrorProvider';
import './GlobalErrorDisplay.scss';

const GlobalErrorDisplay: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { globalError, setGlobalError } = useGlobalError()!;

  const handleRemoveError = (): void => {
    setGlobalError('');
  };

  if (!globalError) return <></>;
  return (
    <div className="global-error-section" onClick={handleRemoveError}>
      <div className="container">
        {globalError}
        <div className="global-error-x">✖︎</div>
      </div>
    </div>
  );
};

export default GlobalErrorDisplay;
