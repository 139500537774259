import React from 'react';

import DancingPenguin from '../../images/dancingPenguin.gif';
import './Submitted.scss';

const Submitted: React.FC = () => {
  return (
    <div className="form-container">
      <div className="form-title">Your request has been submitted!</div>
      <div className="field-container">
        <div className="form-subheader">Please check your inbox for a confirmation email</div>
        <img className="submitted-img" src={DancingPenguin} alt="Request Submitted" />
        <div className="form-subheader">
          <a href="/requests"> Go back to all service requests</a>
        </div>
      </div>
    </div>
  );
};

export default Submitted;
