import { IFormData } from './Interfaces';

export const flyerNotes = (formData: IFormData): string => {
  const productInfo = formData.flyerProducts
    .map((product) => {
      return `Product Name: ${product.productName}
    Product Colors: ${product.productColors}
    Min Order Quanity: ${product.minimumQuantity}
    Product Sku: ${product.productSku}
    Pricing: ${product.pricing} 
    Additional Pricing Info: ${product.additionalPricing}
    Sizes ${product.size}
    `;
    })
    .join();

  return `<body>
  <b>Point Of Contact:</b>
  ${formData.name}
  
  <b>Email:</b>
  ${formData.email}

  <b>Phone:</b>
  ${formData.phone}

  <b>Delivery Method:</b>
  ${formData.deliveryMethod}

  <b>Flyer Title:</b>
  ${formData.title}

  <u><b>Products:</b></u>
  ${productInfo}

  <b>Flyer Text:</b>
   ${formData.flyerText}

  <b>Order Form:</b>
  ${formData.orderForm}

  <b>Compass Website Update:</b>
  ${formData.websiteUpdate}
  
  <b>Comments:</b>
  ${formData.comments}
  </body>`;
};
