import { webToolsServerURL } from '../utilities';
import IGetSiteSettingsResponse from '@theicebox/external-api-integrations-interfaces/distInterfaces/business_layer/interfaces/Compass/IGetSiteSettingsResponse';
import { ISite, ISiteSettings } from './interfaces/siteInterfaces';
import jwt from 'jsonwebtoken';

export const getSites = (): Promise<ISite[]> => {
  return new Promise((resolve, reject) => {
    fetch(`${webToolsServerURL}/api/compass/sites`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt.sign({}, process.env.REACT_APP_SERVER_SECRET as string, { expiresIn: '5m' })}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          return res.text().then((data) => {
            throw new Error(data);
          });
        }
        res.json().then((data) => {
          resolve(data);
        });
      })
      .catch((error) => {
        reject(`Something went wrong... Error: ${error.message || 'Unauthorized'}`);
      });
  });
};

export const getSiteSettings = (siteId: string): Promise<IGetSiteSettingsResponse> => {
  return new Promise((resolve, reject) => {
    fetch(`${webToolsServerURL}/api/compass/sites/${siteId}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt.sign({}, process.env.REACT_APP_SERVER_SECRET as string, { expiresIn: '5m' })}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          return res.text().then((data) => {
            throw new Error(data);
          });
        }
        res.json().then((data) => {
          resolve(data);
        });
      })
      .catch((error) => {
        reject(`Something went wrong... Error: ${error.message || 'Unauthorized'}`);
      });
  });
};
