import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { useAuthState } from '../providers/AuthProvider';

export interface IPrivateRouteProps extends RouteProps {
  isAllowed: boolean;
  restrictedPath: string;
  unauthenticatedPath: string;
}

export const PrivateRoute: React.FC<IPrivateRouteProps> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { authState, isLoaded, setPostLoginRoute } = useAuthState()!;
  const webDevGroupId = process.env.REACT_APP_WEB_DEV_GROUP_ID;

  if (!isLoaded) return <div>Loading...</div>;

  let redirectPath = '';
  if (!authState.isAuthenticated) {
    redirectPath = props.unauthenticatedPath;
  }
  if (authState.isAuthenticated && !props.isAllowed) {
    redirectPath = props.restrictedPath;
  }

  if (redirectPath) {
    if (props.location) setPostLoginRoute(props.location.pathname);
    const renderComponent: React.FC = () => <Redirect to={{ pathname: redirectPath }} />;
    return <Route {...props} component={renderComponent} render={undefined} />;
  } else {
    return <Route {...props} />;
  }
};

export default PrivateRoute;
