/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import bg from '../../images/drawn-bg.png';
import './HomePage.scss';
import penguin from '../../images/penguin.png';

const HomePage: React.FC = () => {
  return (
    <>
      <div className="bg-div" style={{ backgroundImage: `url(${bg})` }}></div>
      <div className="home-container">
        <div className="home-group">
          <div className="home-header">Welcome to The Nest</div>
          <div className="home-message">
            This is The Icebox Cool Stuff's Tool Box. This application is here to assist in the Icebox's daily tasks.
            All updates will be live and available here.
          </div>
          <div className="home-message">
            If have any questions about this application please direct them to
            <a href="mailto:Technology@iceboxmail.com"> technology@iceboxmail.com</a>
          </div>
          <img className="home-penguin" src={penguin} />
        </div>
      </div>
    </>
  );
};

export default HomePage;
