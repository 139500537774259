export interface IFormData {
  name: string;
  email: string;
  phone: string;
  subject: string;
  sku: string;
  web: string;
  comments: string;
}

export const defaultFormData: IFormData = {
  name: '',
  email: '',
  phone: '',
  subject: '',
  sku: '',
  web: '',
  comments: '',
};

export const fakeFormData: IFormData = {
  name: 'Camembert Seer',
  email: 'bao@iceboxmail.com',
  phone: '867-5309',
  subject: 'TEST - Feel free to Delete me - Sorry for the disturbance',
  sku: 'EX-SKU-ME',
  web: 'Yes',
  comments: 'Call Me Maybe',
};

export interface IFormErrors {
  name: string;
  email: string;
  phone: string;
  subject: string;
  web: string;
  comments: string;
  fileDrop: string;
}

export const defaultFormErrors: IFormErrors = {
  name: '',
  email: '',
  phone: '',
  subject: '',
  web: '',
  comments: '',
  fileDrop: '',
};
