export const formData = [
  {
    type: 'subTitle',
    label: 'Contact Information',
  },
  {
    id: 'name',
    label: 'Name:',
    type: 'textField',
    fakeData: 'John Doe',
    errorMessage: 'Please enter a name',
  },
  {
    id: 'email',
    label: 'Email:',
    type: 'textField',
    fakeData: 'technology@iceboxmail.com',
    errorMessage: 'Please enter a valid email',
    errorValidation: (email: string): boolean => !email || !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email),
  },
  {
    id: 'phone',
    label: 'Phone:',
    type: 'textField',
    fakeData: '12345678910',
    errorMessage: 'Please enter a phone nummber',
  },
  {
    id: 'subject',
    label: 'Subject:',
    type: 'textField',
    fakeData: 'Test Subject',
    errorMessage: 'Please enter a subject',
    isFull: true,
  },
  {
    type: 'subTitle',
    label: 'Request Information',
  },
  {
    id: 'customer',
    label: 'Customer:',
    type: 'textField',
    fakeData: 'Test Customer',
    errorMessage: 'Please enter a customer',
    isFull: true,
  },
  {
    id: 'itemLink',
    label: 'Item Link:',
    type: 'textArea',
    fakeData: 'This is an Item Link',
    errorMessage: 'Please supply an item link',
    isFull: true,
  },
  {
    id: 'itemColor',
    label: 'Item Color(s):',
    type: 'textArea',
    fakeData: 'This is an Item Color',
    errorMessage: 'Please supply an item color',
    isFull: true,
  },
  {
    id: 'youth',
    label: 'Are there Youth Sizes?',
    type: 'dropdownField',
    fakeData: 'No',
    placeholder: 'Youth Size?',
    options: ['Yes', 'No'],
    errorMessage: 'Please select whether or not there are youth sizes',
  },
  {
    id: 'garment',
    label: 'Garment Sizes:',
    type: 'textField',
    fakeData: 'Small',
    errorMessage: 'Please enter garment sizes',
  },
  {
    id: 'web',
    label: 'Will product(s) be on web?',
    type: 'dropdownField',
    fakeData: 'Yes',
    placeholder: 'Web Image?',
    options: ['Yes', 'No'],
    errorMessage: 'Please select whether or not products will be on web',
  },
  {
    type: 'templateLinks',
    label: 'Template Links:',
  },
  {
    id: 'design',
    label: 'Design to be Printed:',
    type: 'textArea',
    fakeData: 'This is the design to be printed',
    errorMessage: 'Please supply the design to be printed',
    isFull: true,
  },
  {
    id: 'location',
    label: 'Imprint Locations:',
    type: 'textField',
    fakeData: 'Fake Location',
    errorMessage: 'Please enter imprint locations',
  },
  {
    id: 'imprintSize',
    label: 'Imprint Size:',
    type: 'textField',
    fakeData: 'Fake Size',
    errorMessage: 'Please enter imprint size',
  },
  {
    id: 'imprintColor',
    label: 'Imprint Color(s):',
    type: 'textField',
    fakeData: 'Imprint color codes',
    placeholder: 'list PMS color codes if known',
    errorMessage: 'Please enter imprint imprint color codes',
  },
  {
    id: 'decor',
    label: 'Decoration Type:',
    type: 'textField',
    fakeData: 'silkscreen',
    placeholder: 'silkscreen, embroidery, heat transfer, 4CP, etc',
    errorMessage: 'Please enter decoration type',
  },
  {
    id: 'customerConcern',
    label: 'Customer Concerns:',
    type: 'dropdownField',
    fakeData: 'Soft-Hand Feel',
    placeholder: 'Customer Concerns?',
    options: ['Soft-Hand Feel', 'PMS Color Match'],
    errorMessage: 'Please select if there are customer concerns',
  },
  {
    id: 'production',
    label: 'Quantity of Production:',
    type: 'textField',
    fakeData: 'Fake Quantity',
    errorMessage: 'Please enter quantity',
  },
  {
    id: 'inHandsDate',
    label: 'In Hands Date:',
    type: 'dateField',
    fakeData: '2100-01-01',
    optional: true,
  },
  {
    id: 'turnAround',
    label: 'Turn Around Time:',
    type: 'dropdownField',
    fakeData: '1 Day Rush',
    placeholder: 'Art/Mocks Needed By',
    options: ['Normal Turn Time', '1 Day Rush', '2 Day Rush'],
    errorMessage: 'Please select the turn around time',
  },
  {
    id: 'comments',
    label: 'Additional Comments/Notes?',
    type: 'textArea',
    fakeData: 'This is test data.',
    isFull: true,
    optional: true,
  },
  {
    type: 'fileDrop',
  },
];

export const extraFields = {
  email: {
    asanaId: '937447804889185',
  },
  web: {
    asanaId: '1200074375870579',
    getIdFromValue: (value: string): string => {
      const values = {
        yes: '1200074375870582',
        no: '',
      } as { [field: string]: string };
      return values[value.toLowerCase()];
    },
  },
  turnAround: {
    asanaId: '1201594787722279',
    getIdFromValue: (value: string): string => {
      const values = {
        'normal turn time': '1201594787723315',
        '1 day rush': '1201594787723460',
        '2 day rush': '1201594787723481',
      } as { [field: string]: string };
      return values[value.toLowerCase()];
    },
  },
};
