import React, { useState, useEffect } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import NotFoundPage from '../NotFoundPage';
import OrderUploadPage from './OrderUploadPage/OrderUploadPage';
import OrderFulfillmentPage from './FulfillOrderPage/FulfillOrderPage';
import { useAuthState } from '../../providers/AuthProvider';
import { verifyPermission } from '../../shared/endpointAccess/permissions';
import SettingsPage from './SettingsPage/SettingsPage';
import OrderPaymentTermsPage from './OrderPaymentTermsPage/OrderPaymentTermsPage';

interface MatchParams {
  name: string;
}

type MatchProps = RouteComponentProps<MatchParams>;

const WebTeamRoutes: React.FC<MatchProps> = (params) => {
  const { match } = params;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { authState } = useAuthState()!;

  const [hasCheckedPermissions, setHasCheckedPermissions] = useState<boolean>(false);
  const [orderUploadAccess, setOrderUploadAccess] = useState<boolean>(false);
  const [siteSettingsAccess, setSiteSettingsAccess] = useState<boolean>(false);
  const [orderFulfillmentAccess, setOrderFulfillmentAccess] = useState<boolean>(false);
  const [orderPaymentTermAccess, setOrderPaymentTermAccess] = useState<boolean>(false);
  useEffect(() => {
    if (!authState || !authState.user || !authState.user.email) return;

    verifyPermission('orderUploader', authState.user.email)
      .then((res) => {
        setOrderUploadAccess(res);
      })
      .finally(() => {
        setHasCheckedPermissions(true);
      });
    verifyPermission('orderFulfillment', authState.user.email)
      .then((res) => {
        setOrderFulfillmentAccess(res);
      })
      .finally(() => {
        setHasCheckedPermissions(true);
      });
    verifyPermission('orderPaymentTerm', authState.user.email)
      .then((res) => {
        setOrderPaymentTermAccess(res);
      })
      .finally(() => {
        setHasCheckedPermissions(true);
      });
    verifyPermission('siteSettings', authState.user.email)
      .then((res) => {
        setSiteSettingsAccess(res);
      })
      .finally(() => {
        setHasCheckedPermissions(true);
      });
  }, [authState]);

  return (
    <Switch>
      {orderUploadAccess && <Route exact path={`${match.url}/orderupload`} component={OrderUploadPage} />}
      {orderFulfillmentAccess && (
        <Route exact path={`${match.url}/orderfulfillment`} component={OrderFulfillmentPage} />
      )}
      {orderPaymentTermAccess && (
        <Route exact path={`${match.url}/orderpaymentterms`} component={OrderPaymentTermsPage} />
      )}
      {siteSettingsAccess && <Route exact path={`${match.url}/sitesettings`} component={SettingsPage} />}
      {hasCheckedPermissions && <Route component={NotFoundPage} />}
    </Switch>
  );
};

export default WebTeamRoutes;
