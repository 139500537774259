import React from 'react';
import cx from 'classnames';
import FulfillmentsInfo from './FulfillmentsInfo';

import { IFulfillments, IFulfillment } from '../../../../shared/endpointAccess/interfaces/fulfillmentInterfaces';

interface Props {
  fulfillmentsValid: boolean;
  currentTab: string;
  fulfillments: IFulfillments;
  retryFulfillment: (fulfillment: IFulfillment) => void;
  fulfillmentsAttempted: any;
}

const OrderTab: React.FC<Props> = ({
  fulfillmentsValid: productsValid = false,
  currentTab = '',
  fulfillments = [],
  // eslint-disable-next-line
  retryFulfillment: retryFulfillment = (): void => { },
  fulfillmentsAttempted = {},
}) => {
  return (
    <>
      {productsValid && (
        <div className={cx('orders-height-setup', { hidden: currentTab !== 'Fulfillments' })}>
          <div className="orders-header-bar">
            <div className="fulfillment-order-id-header">
              <p>OrderId</p>
            </div>
            <div className="order-fulfilled-header">
              <p>Fulfillment Status?</p>
            </div>
            <div className="fulfilled-buttons-header"></div>
          </div>
          <div className="all-orders-height-setup">
            <div className="all-orders">
              {fulfillments.map((fulfillment) => (
                <FulfillmentsInfo
                  fulfillment={fulfillment}
                  retryFulfillment={(fulfillment: IFulfillment): void => {
                    retryFulfillment(fulfillment);
                  }}
                  fulfillmentsAttempted={fulfillmentsAttempted[fulfillment.orderId]}
                  key={fulfillment.orderId}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OrderTab;
