import React from 'react';
import cx from 'classnames';
import OrderInfo from './OrderInfo';

import { IOrder } from '../../../../shared/endpointAccess/interfaces/orderInterfaces';

interface Props {
  productsValid: boolean;
  currentTab: string;
  orders: IOrder[];
  setOrdersValidatedCount: () => void;
  setOrders: (orders: IOrder[]) => void;
  retryOrder: (order: IOrder) => void;
  removeCart: (cartTitle: string) => void;
  cartsCreated: any;
}

const OrderTab: React.FC<Props> = ({
  productsValid = false,
  currentTab = '',
  orders = [],
  // eslint-disable-next-line
  setOrdersValidatedCount = (): void => { },
  // eslint-disable-next-line
  setOrders = (): void => { },
  // eslint-disable-next-line
  retryOrder = (): void => { },
  // eslint-disable-next-line
  removeCart = (): void => { },
  cartsCreated = {},
}) => {
  const updateOrder = (updatedOrder: IOrder): void => {
    const updatedOrderList = [];
    for (let i = 0; i < orders.length; i++) {
      if (orders[i].OrderInformation.Title === updatedOrder.OrderInformation.Title) updatedOrderList.push(updatedOrder);
      else updatedOrderList.push(orders[i]);
    }
    setOrders(updatedOrderList);
  };

  const deleteOrder = (orderTitle: string): void => {
    const updatedOrderList = [];
    for (let i = 0; i < orders.length; i++) {
      if (orders[i].OrderInformation.Title === orderTitle) continue;
      else updatedOrderList.push(orders[i]);
    }
    setOrders(updatedOrderList);
  };

  return (
    <>
      {productsValid && (
        <div className={cx('orders-height-setup', { hidden: currentTab !== 'Orders' })}>
          <div className="orders-header-bar">
            <div className="orders-header-title">
              <p>Title of the Order</p>
            </div>
            <div className="orders-header-verified">
              <p>Verified Status?</p>
            </div>
            <div className="orders-header-survey">
              <p>Survey Answers Status?</p>
            </div>
            <div className="orders-header-submitted">
              <p>Cart Created Status?</p>
            </div>
            <div className="orders-header-buttons"></div>
          </div>
          <div className="all-orders-height-setup">
            <div className="all-orders">
              {orders.map((order) => (
                <OrderInfo
                  order={order}
                  orderVerified={(): void => {
                    setOrdersValidatedCount();
                  }}
                  updateOrder={(updatedOrder: IOrder): void => {
                    updateOrder(updatedOrder);
                  }}
                  retryOrder={(order: IOrder): void => {
                    retryOrder(order);
                  }}
                  deleteOrder={(orderTitle: string): void => {
                    removeCart(orderTitle);
                    deleteOrder(orderTitle);
                  }}
                  cartInfo={cartsCreated[order.OrderInformation.Title]}
                  key={order.OrderInformation.Title}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OrderTab;
