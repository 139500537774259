import { serverURL } from '../../shared/utilities';
import jwt from 'jsonwebtoken';
import { IBookingLocations } from './interfaces/booking/IBookingLocations';
import { IBookings } from './interfaces/booking/IBookings';
import { IBookingAdmins } from './interfaces/booking/IBookingAdmins';

export const getLocations = (): Promise<IBookingLocations[]> => {
  return new Promise((resolve, reject) => {
    fetch(`${serverURL}/api/booking/locations/`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt.sign({}, process.env.REACT_APP_SERVER_SECRET as string, { expiresIn: '5m' })}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          return res.text().then((data) => {
            throw new Error(data);
          });
        }
        res.json().then((data) => {
          resolve(data);
        });
      })
      .catch((error) => {
        reject(`Something went wrong... Error: ${error.message}`);
      });
  });
};

export const getLocationBookings = (locationId: string): Promise<{ [date: string]: IBookings[] }> => {
  return new Promise((resolve, reject) => {
    fetch(`${serverURL}/api/booking/locations/${locationId}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt.sign({}, process.env.REACT_APP_SERVER_SECRET as string, { expiresIn: '5m' })}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          return res.text().then((data) => {
            throw new Error(data);
          });
        }
        res.json().then((data) => {
          resolve(data);
        });
      })
      .catch((error) => {
        reject(`Something went wrong... Error: ${error.message}`);
      });
  });
};

export const getLocationAvailability = (locationId: string): Promise<{ [date: string]: number }> => {
  return new Promise((resolve, reject) => {
    fetch(`${serverURL}/api/booking/locations/${locationId}/availability`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt.sign({}, process.env.REACT_APP_SERVER_SECRET as string, { expiresIn: '5m' })}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          return res.text().then((data) => {
            throw new Error(data);
          });
        }
        res.json().then((data) => {
          resolve(data);
        });
      })
      .catch((error) => {
        reject(`Something went wrong... Error: ${error.message}`);
      });
  });
};

export const getEmployeeSchedule = (employeeId: string): Promise<IBookings[]> => {
  return new Promise((resolve, reject) => {
    fetch(`${serverURL}/api/booking/employee/${employeeId}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt.sign({}, process.env.REACT_APP_SERVER_SECRET as string, { expiresIn: '5m' })}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          return res.text().then((data) => {
            throw new Error(data);
          });
        }
        res.json().then((data) => {
          resolve(data);
        });
      })
      .catch((error) => {
        reject(`Something went wrong... Error: ${error.message}`);
      });
  });
};

export const book = (booking: IBookings): Promise<void> => {
  return new Promise((resolve, reject) => {
    fetch(`${serverURL}/api/booking/employee/${booking.employeeId}`, {
      method: 'POST',
      body: JSON.stringify(booking),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt.sign({}, process.env.REACT_APP_SERVER_SECRET as string, { expiresIn: '5m' })}`,
      },
    })
      .then((res) => {
        if (res.status === 204) {
          resolve();
          return;
        }

        if (!res.ok) {
          return res.text().then((data) => {
            throw new Error(data);
          });
        }

        resolve();
      })
      .catch((error) => {
        reject(`Something went wrong... Error: ${error.message}`);
      });
  });
};

export const removeBooking = (booking: IBookings): Promise<void> => {
  return new Promise((resolve, reject) => {
    fetch(`${serverURL}/api/booking/employee/${booking.employeeId}`, {
      method: 'DELETE',
      body: JSON.stringify(booking),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt.sign({}, process.env.REACT_APP_SERVER_SECRET as string, { expiresIn: '5m' })}`,
      },
    })
      .then((res) => {
        if (res.status === 204) {
          resolve();
          return;
        }

        if (!res.ok) {
          return res.text().then((data) => {
            throw new Error(data);
          });
        }

        resolve();
      })
      .catch((error) => {
        reject(`Something went wrong... Error: ${error.message}`);
      });
  });
};

export const getBookingAdmins = (): Promise<IBookingAdmins[]> => {
  return new Promise((resolve, reject) => {
    fetch(`${serverURL}/api/booking/admins`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt.sign({}, process.env.REACT_APP_SERVER_SECRET as string, { expiresIn: '5m' })}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          return res.text().then((data) => {
            throw new Error(data);
          });
        }
        res.json().then((data) => {
          resolve(data);
        });
      })
      .catch((error) => {
        reject(`Something went wrong... Error: ${error.message}`);
      });
  });
};
