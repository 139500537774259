import React, { useState, useEffect, useCallback } from 'react';
import { defaultFormData, defaultFormErrors, IFormData, IFormErrors, fakeFormData } from './Interfaces';
import { WebPriorityLevel, WebIssueType, WebProject } from '../../../shared/RequestsAsanaKeys';
import InputTextField from '../../../components/form/InputTextField';
import FileDrop from '../../../components/form/FileDrop/FileDrop';
import DropdownField from '../../../components/form/DropdownField';
import InputDateField from '../../../components/form/InputDateField';
import InputTextAreaField from '../../../components/form/InputTextAreaField/InputTextAreaField';
import { serverURL, totalAsanaUploadSizeLimit, bytesToMB } from '../../../shared/utilities';
import { Redirect } from 'react-router-dom';
import { useGlobalError } from '../../../providers/ErrorProvider';
import '../../../components/form/form.scss';

const RequestsITPage: React.FC = () => {
  const [formData, setFormData] = useState<IFormData>(defaultFormData);
  const [formErrors, setFormErrors] = useState<IFormErrors>(defaultFormErrors);
  const [uploadedFiles, sendFilesToAsana] = useState<Array<File>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [redirect, setRedirect] = useState<string>('');

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { setGlobalError } = useGlobalError()!;

  const handleUserKeyPress = useCallback((event: KeyboardEvent) => {
    const { key, shiftKey, ctrlKey } = event;
    if (key === 'T' && shiftKey && ctrlKey) {
      setFormData(fakeFormData);
    }
  }, []);
  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);

    return (): void => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setFormData({ ...formData, name: e.target.value });
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setFormData({ ...formData, email: e.target.value });
  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setFormData({ ...formData, phone: e.target.value });
  const handleSubjectChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setFormData({ ...formData, subject: e.target.value });
  const handlePriorityChange = (e: React.ChangeEvent<HTMLSelectElement>): void =>
    setFormData({ ...formData, priority: e.target.value });
  const handleRequestTypeChange = (e: React.ChangeEvent<HTMLSelectElement>): void =>
    setFormData({ ...formData, requestType: e.target.value });
  const handleCommentsChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void =>
    setFormData({ ...formData, comments: e.target.value });
  const handleDueDateChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setFormData({ ...formData, dueDate: e.target.value });

  const setUploadedFilesToState = (filesArray: File[]): void => {
    let totalFileSize = 0;
    const fileNamesFromState = uploadedFiles.map((file) => {
      totalFileSize += file.size;
      return file.name;
    });
    const filesToBeAdded = [...filesArray].filter((file) => {
      if (fileNamesFromState.includes(file.name)) return false;
      totalFileSize += file.size;
      return true;
    });
    if (totalFileSize > totalAsanaUploadSizeLimit) {
      return setFormErrors({
        ...formErrors,
        fileDrop: `Attachments(${bytesToMB(totalFileSize)}) will exceeded maximum allowed size of ${bytesToMB(
          totalAsanaUploadSizeLimit,
        )}.`,
      });
    }
    setFormErrors({ ...formErrors, fileDrop: '' });
    sendFilesToAsana([...uploadedFiles, ...filesToBeAdded]);
  };

  const uploadFile = async (taskID: string): Promise<boolean> => {
    let results = true;
    uploadedFiles.forEach(async (file) => {
      const fd = new FormData();
      fd.append('file', file);
      await fetch(`https://app.asana.com/api/1.0/tasks/${taskID}/attachments`, {
        method: 'POST',
        body: fd,
        headers: {
          Authorization: process.env.REACT_APP_ASANA_BEARER_TOKEN as string,
        },
      }).catch(() => {
        results = false;
      });
    });
    return results;
  };
  const handleFileRemove = (fileToRemove: string): void => {
    const newUploadedFiles = uploadedFiles.filter((file) => {
      return file.name !== fileToRemove;
    });
    sendFilesToAsana([...newUploadedFiles]);
  };

  const postToServer = async (): Promise<void> => {
    const fd = new FormData() as any;
    uploadedFiles.forEach((file) => {
      fd.append('file', file);
    });
    const data = JSON.stringify(formData);
    fd.append('data', data);
    const message = {
      data: {
        name: `${formData.subject}`,
        notes: `${formData.name}\nemail: ${formData.email}\nPhone: ${formData.phone}\nIssue:\n${formData.comments}`,
        // eslint-disable-next-line @typescript-eslint/camelcase
        due_on: `${formData.dueDate}`,
        // eslint-disable-next-line @typescript-eslint/camelcase
        custom_fields: {
          [WebProject.priority]: WebPriorityLevel[formData.priority.toLowerCase()],
          [WebProject.requesterEmail]: `${formData.email}`,
          [WebProject.TypeOfRequest]: WebIssueType[formData.requestType.toLowerCase().replace(/\s/g, '')],
        },
        projects: [WebProject.projects],
        followers: [formData.email, 'technology@iceboxmail.com'],
      },
    };
    await fetch(`https://app.asana.com/api/1.0/tasks`, {
      method: 'POST',
      body: JSON.stringify(message),
      headers: {
        Authorization: process.env.REACT_APP_ASANA_BEARER_TOKEN as string,
      },
    })
      .then((res) => {
        if (!res.ok) throw new Error(res.statusText);
        return res.json();
      })
      .then(async (res) => {
        const results = await uploadFile(res.data.gid);
        if (results) {
          const response = await fetch(`${serverURL}/api/requests/web`, {
            method: 'POST',
            body: fd,
          });
          if (response.ok) {
            setRedirect('/requests/submitted');
          } else {
            setGlobalError(`Asana task successfully created, but confirmation email failed to send`);
          }
        } else {
          setGlobalError(`The attachments have failed to load`);
        }
      })
      .catch((error) => {
        setGlobalError(`Something went wrong... Error: ${error.message}`);
      });
  };

  const handleSubmit = async (): Promise<void> => {
    setIsLoading(true);
    const newFormErrors = {} as IFormErrors;
    newFormErrors.email =
      // eslint-disable-next-line
      !formData.email || !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)
        ? 'Please enter a valid email'
        : '';

    newFormErrors.name = !formData.name ? 'Please enter your name' : '';
    newFormErrors.phone = !formData.phone ? 'Please enter your phone number' : '';
    newFormErrors.subject = !formData.subject ? 'Please enter a subject' : '';
    newFormErrors.email = !formData.email ? 'Please enter a email' : '';
    newFormErrors.priority = !formData.priority ? 'Please enter this tasks priority' : '';
    newFormErrors.dueDate = !formData.dueDate ? 'Please enter a due date' : '';
    newFormErrors.requestType = !formData.requestType ? 'Please enter the request type' : '';
    newFormErrors.comments = !formData.requestType ? 'Please tells us about the issue' : '';
    setFormErrors(newFormErrors);
    if (!Object.values(newFormErrors).find((error) => error !== '')) {
      await postToServer();
    } else {
      setGlobalError('Please fill out entire form.');
      window.scrollTo(0, 0);
    }
    setIsLoading(false);
  };

  return (
    <div className="form-container">
      {redirect && <Redirect to={redirect} />}
      <div className="form-title">Web Request Form</div>
      <div className="field-container">
        <div className="form-subtitle"></div>
        <InputTextField label="Name:" value={formData.name} handleChange={handleNameChange} error={formErrors.name} />
        <InputTextField
          label="Email:"
          value={formData.email}
          handleChange={handleEmailChange}
          error={formErrors.email}
        />
        <InputTextField
          handleChange={handlePhoneChange}
          label="Phone:"
          value={formData.phone}
          error={formErrors.phone}
        />
        <InputTextField
          handleChange={handleSubjectChange}
          label="Subject:"
          value={formData.subject}
          error={formErrors.subject}
        />
        <DropdownField
          label="How urgent is this request?"
          value={formData.priority}
          handleChange={handlePriorityChange}
          placeholder="Priority"
          options={['Low', 'Med', 'High', 'Urgent']}
          error={formErrors.priority}
        />
        <DropdownField
          label="What type of request is this?"
          value={formData.requestType}
          handleChange={handleRequestTypeChange}
          placeholder="Request Type"
          options={['Inquiry', 'Mockup', 'New Site Request', 'Current Site Edit']}
          error={formErrors.requestType}
        />
        <InputDateField
          label="Due Date:"
          placeholder={new Date().toDateString()}
          value={formData.dueDate}
          handleChange={handleDueDateChange}
          error={formErrors.dueDate}
        />
        <InputTextAreaField
          label="Description:"
          value={formData.comments}
          handleChange={handleCommentsChange}
          error={formErrors.comments}
          isFull
        />
        <FileDrop
          handleRemoveListItem={handleFileRemove}
          uploadedFiles={uploadedFiles.map((file) => file.name)}
          dropzoneOptions={{
            onDrop: (acceptedFiles: File[]): void => setUploadedFilesToState(acceptedFiles),
          }}
          error={formErrors.fileDrop}
        />
        {isLoading ? (
          <button className="form-submit-button loading" disabled>
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </button>
        ) : (
          <button className="form-submit-button" onClick={handleSubmit}>
            Submit Request
          </button>
        )}
      </div>
    </div>
  );
};

export default RequestsITPage;
