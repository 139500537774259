import React from 'react';
import './FieldError.scss';

interface IProps {
  error?: string;
}

const FieldError: React.FC<IProps> = (props) => {
  return <>{props.error && <p className="field-error">{props.error}</p>}</>;
};

export default FieldError;
