import React from 'react';
import Field from './Field/Field';

interface IProps {
  automationId?: string; // todo: make this not optional
  label: string;
  error?: string;
  value: string;
  handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  placeholder?: string;
  options: Array<string | { id: string; value: string }>;
  isFull?: boolean;
  className?: any;
}

const DropdownField: React.FC<IProps> = (props) => {
  return (
    <Field isFull={props.isFull} label={props.label} error={props.error}>
      <select className={`field-input ${props.className}`} value={props.value} onChange={props.handleChange}>
        <option value="" disabled>
          {props.placeholder}
        </option>
        {props.options.map((option: any) => {
          if (typeof option === 'object' && option !== null && 'id' in option && 'value' in option) {
            return (
              <option key={option.id} value={option.id}>
                {option.value}
              </option>
            );
          }
          return (
            <option key={option} value={option}>
              {option}
            </option>
          );
        })}
      </select>
    </Field>
  );
};

export default DropdownField;
