import { Dispatch } from 'react';
import { IFormErrors, IFormData } from '../pages/Requests/RequestsFlyer/Interfaces';
const serverPort = process.env.REACT_APP_SERVER_PORT ? `:${process.env.REACT_APP_SERVER_PORT}` : '';
const port = process.env.REACT_APP_PORT ? `:${process.env.REACT_APP_PORT}` : '';
const prefix = process.env.NODE_ENV === 'production' ? 'https://' : 'http://';
const websocketPrefix = process.env.NODE_ENV === 'production' ? 'wss://' : 'ws://';
export const serverURL = prefix + process.env.REACT_APP_SERVER_HOST + serverPort;
export const webToolsServerURL = prefix + process.env.REACT_APP_SERVER_WEB_TOOLS;
export const websocketURL = websocketPrefix + process.env.REACT_APP_SERVER_HOST + serverPort;
export const URL = prefix + process.env.REACT_APP_HOST + port;
export const isUrl = (str: string): boolean => {
  const regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  return regexp.test(str);
};
// eslint-disable-next-line
export const vaildEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const totalAsanaUploadSizeLimit = 90971520;
export const totalEmailUploadSizeLimit = 20971520;

export const bytesToMB = (bytes: number): string => {
  return `${(bytes / 1048576).toFixed(1)} MB`;
};

export const setUploadedFilesToState = (
  filesArray: File[],
  formErrors: IFormErrors,
  setFormErrors: React.Dispatch<React.SetStateAction<IFormErrors>>,
  filesForAsana: React.Dispatch<React.SetStateAction<File[]>>,
  uploadedFiles: File[],
): void => {
  let totalFileSize = 0;
  const fileNamesFromState = uploadedFiles.map((file: any) => {
    totalFileSize += file.size;
    return file.name;
  });
  const filesToBeAdded = [...filesArray].filter((file) => {
    if (fileNamesFromState.includes(file.name)) return false;
    totalFileSize += file.size;
    return true;
  });
  if (totalFileSize > totalAsanaUploadSizeLimit) {
    return setFormErrors({
      ...formErrors,
      fileDrop: `Attachments(${bytesToMB(totalFileSize)}) will exceeded maximum allowed size of ${bytesToMB(
        totalAsanaUploadSizeLimit,
      )}.`,
    });
  }
  setFormErrors({ ...formErrors, fileDrop: '' });
  filesForAsana([...uploadedFiles, ...filesToBeAdded]);
};

export const uploadFile = async (taskID: string, uploadedFiles: File[]): Promise<boolean> => {
  let results = true;
  uploadedFiles.forEach(async (file: any) => {
    const fd = new FormData();
    fd.append('file', file);
    await fetch(`https://app.asana.com/api/1.0/tasks/${taskID}/attachments`, {
      method: 'POST',
      body: fd,
      headers: {
        Authorization: process.env.REACT_APP_ASANA_BEARER_TOKEN as string,
      },
    }).catch(() => {
      results = false;
    });
  });
  return results;
};

export const postToServerToState = async (
  formData: IFormData,
  uploadedFiles: File[],
  setRedirect: React.Dispatch<React.SetStateAction<string>>,
  setGlobalError: any,
  message: object,
): Promise<void> => {
  const fd = new FormData();
  uploadedFiles.forEach((file: any) => {
    fd.append('file', file);
  });
  const data = JSON.stringify(formData);
  fd.append('data', data);
  await fetch(`https://app.asana.com/api/1.0/tasks`, {
    method: 'POST',
    body: JSON.stringify(message),
    headers: {
      Authorization: process.env.REACT_APP_ASANA_BEARER_TOKEN as string,
    },
  })
    .then((res) => {
      if (!res.ok) throw new Error(res.statusText);
      return res.json();
    })
    .then(async (res) => {
      const results = await uploadFile(res.data.gid, uploadedFiles);
      if (results) {
        const response = await fetch(`${serverURL}/api/requests/flyer`, {
          method: 'POST',
          body: fd,
        });
        if (response.ok) {
          setRedirect('/requests/submitted');
        } else {
          setGlobalError(`Asana task successfully created, but confirmation email failed to send`);
        }
      } else {
        setGlobalError(`The attachments have failed to load`);
      }
    })
    .catch((error) => {
      setGlobalError(`Something went wrong... Error: ${error.message}`);
    });
};
