import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { IOrder } from '../../../../shared/endpointAccess/interfaces/orderInterfaces';
import './OrderInfo.scss';
import { deleteCart } from '../../../../shared/endpointAccess/carts';
import { validateOrder } from '../../../../shared/endpointAccess/orders';
import { addSurveyResults } from '../../../../shared/endpointAccess/products';
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';
import OrderForm from '../Form/OrderForm';

interface Props {
  order: IOrder;
  orderVerified?: Function;
  updateOrder?: Function;
  retryOrder?: Function;
  deleteOrder?: Function;
  cartInfo:
    | undefined
    | { compassCartId: string; compassContactId: string; isCreated: boolean; attempted: boolean; error?: string };
}

const OrderInfo: React.FC<Props> = ({
  order,
  // eslint-disable-next-line
  orderVerified = (): void => { },
  // eslint-disable-next-line
  updateOrder = (): void => { },
  // eslint-disable-next-line
  retryOrder = (): void => { },
  // eslint-disable-next-line
  deleteOrder = (): void => { },
  cartInfo,
}) => {
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [isSurveyCreated, setIsSurveyCreated] = useState<boolean>(false);
  const [verificationErrors, setVerificationErrors] = useState<string[]>([]);
  const [surveyErrors, setSurveyErrors] = useState<string[]>([]);
  const [tabClosed, setTabClosed] = useState<boolean>(true);
  const [orderFormVisible, setOrderFormVisible] = useState<boolean>(false);

  const validateCurrentOrder = (): void => {
    validateOrder({ order })
      .then((data) => {
        if (data.isValid) {
          setIsVerified(true);
        } else {
          setVerificationErrors(data.messages);
        }
      })
      .catch((error) => {
        if (Array.isArray(error.messages)) {
          setVerificationErrors(error.messages);
        } else {
          validateOrder({ order })
            .then((data) => {
              if (data.isValid) {
                setIsVerified(true);
              } else {
                setVerificationErrors(data.messages);
              }
            })
            .catch((error) => {
              setVerificationErrors(error.messages);
            });
        }
      });
  };

  useEffect(() => {
    setVerificationErrors([]);
    validateCurrentOrder();
  }, [order]);

  useEffect(() => {
    const addSurveyResultsSync = async (
      productSKU: string,
      compassSiteId: string,
      surveyAnswers: string[],
    ): Promise<{
      didSucceed: boolean;
      id?: string;
      errorMessage?: string;
    }> => {
      const returnValue = await addSurveyResults(productSKU, compassSiteId, surveyAnswers)
        .then((x: string) => {
          return { didSucceed: true, id: x };
        })
        .catch((error) => {
          return {
            didSucceed: true,
            errorMessage: `Error proccessing ${productSKU}'s survey information; Message: ${error.message}`,
          };
        });

      return returnValue;
    };
    const processSurveysSync = async (): Promise<void> => {
      let hasProcessedSurveyResult;
      const newItemsArray = [] as {
        SKU: string;
        Quantity: number;
        Survey: string[];
        CompassSurveyResultsId: string;
      }[];
      const errorsMessages = [] as string[];

      if (isVerified) {
        for (let i = 0; i < order.Items.length; i++) {
          if (order.Items[i].Survey.length > 0) {
            const results = await addSurveyResultsSync(
              order.Items[i].SKU,
              order.OrderInformation.SiteId,
              order.Items[i].Survey,
            );
            if (results.didSucceed && !!results.id) {
              newItemsArray.push({
                ...order.Items[i],
                CompassSurveyResultsId: results.id,
              });
              hasProcessedSurveyResult = true;
              continue;
            } else if (!results.didSucceed) {
              errorsMessages.push(
                results.errorMessage ?? `Unexpected error when submitting product survey; SKU: ${order.Items[i].SKU}`,
              );
            }
          }
          newItemsArray.push({
            ...order.Items[i],
          });
        }
        setIsSurveyCreated(true);
        setSurveyErrors(errorsMessages);
        if (hasProcessedSurveyResult && errorsMessages.length <= 0) {
          updateOrder({ ...order, Items: newItemsArray });
        }
        if (errorsMessages.length <= 0) {
          orderVerified(true);
        }
      }
    };

    processSurveysSync();
  }, [isVerified]);

  let verifiedText;
  if (!Array.isArray(verificationErrors) || verificationErrors.length > 0) {
    verifiedText = (
      <p>
        <i className="fa fa-times inline"></i> Errors during verification. Click to see more...
      </p>
    );
  } else if (!isVerified) {
    verifiedText = <p>Waiting to verify...</p>;
  } else {
    verifiedText = <p>Verified</p>;
  }

  return (
    <div>
      <div
        className="order-container"
        onClick={(): void => {
          setTabClosed(!tabClosed);
        }}
      >
        <div className="order-title">
          <p>{order.OrderInformation.Title}</p>
        </div>
        <div
          className={cx('order-verified', {
            'error-color': !Array.isArray(verificationErrors) || verificationErrors.length > 0,
            'success-color':
              ((Array.isArray(verificationErrors) && verificationErrors.length <= 0) ||
                !Array.isArray(verificationErrors)) &&
              isVerified,
          })}
        >
          {verifiedText}
        </div>
        <div
          className={cx('order-survey', {
            'error-color': !Array.isArray(surveyErrors) || surveyErrors.length > 0,
            'success-color': isSurveyCreated,
          })}
        >
          <p>
            {!isSurveyCreated
              ? 'Waiting to create survey answers if there are any...'
              : 'Created survey answers if applicable'}
          </p>
        </div>
        <div
          className={cx('order-submitted', {
            'error-color': !cartInfo?.isCreated && !!cartInfo?.attempted,
            'success-color': !!cartInfo?.attempted && !!cartInfo?.isCreated,
          })}
        >
          {isVerified && !cartInfo?.isCreated && cartInfo?.attempted && <p>Error while creating cart</p>}
          {(!isVerified || !cartInfo?.attempted) && <p>Waiting to create cart...</p>}
          {isVerified && !!cartInfo?.isCreated && <p>{`Created Cart: #${cartInfo?.compassCartId}`}</p>}
        </div>
        <div className="order-buttons">
          {!cartInfo?.isCreated && (
            <span>
              <i
                className="fa fa-edit clickable"
                onClick={(e: any): void => {
                  setOrderFormVisible(true);
                  e.stopPropagation();
                }}
              ></i>
            </span>
          )}
          {!cartInfo?.isCreated && !!cartInfo?.attempted && (
            <span>
              <i
                className="fa fa-refresh clickable"
                onClick={(e: any): void => {
                  retryOrder(order);
                  e.stopPropagation();
                }}
              ></i>
            </span>
          )}
          <span>
            <i
              className="fa fa-trash clickable"
              onClick={(): void => {
                if (!!cartInfo?.isCreated) deleteCart(cartInfo?.compassCartId);
                deleteOrder(order.OrderInformation.Title);
              }}
            ></i>
          </span>
        </div>
      </div>
      <div className="order-info">
        <SlideDown closed={tabClosed}>
          {Array.isArray(verificationErrors) &&
            verificationErrors.map((error, index) => (
              <div className="validation-errors" key={index}>
                <p>{error}</p>
              </div>
            ))}
          {Array.isArray(surveyErrors) &&
            surveyErrors.map((error, index) => (
              <div className="validation-errors" key={index}>
                <p>{error}</p>
              </div>
            ))}
          {!cartInfo?.isCreated && !!cartInfo?.attempted && !!cartInfo?.error && (
            <div className="validation-errors">
              <p>{cartInfo.error}</p>
            </div>
          )}
        </SlideDown>
        {orderFormVisible && (
          <OrderForm
            order={order}
            onClose={(): void => {
              setOrderFormVisible(false);
            }}
            onSubmit={(order: IOrder): void => {
              updateOrder(order);
              setOrderFormVisible(false);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default OrderInfo;
