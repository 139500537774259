import React, { useState, useEffect } from 'react';

import DropdownField from '../../../../components/form/DropdownField';
import FileDrop from '../../../../components/form/FileDrop/FileDrop';
import ToggleSwitch from '../../../../components/form/ToggleSwitch/ToggleSwitch';
import { IFormData, defaultFormData, IFormErrors, defaultFormErrors } from '../Interfaces';
import { parseOrders } from '../../../../shared/endpointAccess/orders';
import { getSites, getSiteSettings } from '../../../../shared/endpointAccess/sites';
import { useGlobalError } from '../../../../providers/ErrorProvider';
import { IOrder } from '../../../../shared/endpointAccess/interfaces/orderInterfaces';
import IGetSiteSettingsResponse from '@theicebox/external-api-integrations-interfaces/distInterfaces/business_layer/interfaces/Compass/IGetSiteSettingsResponse';
interface IProps {
  clearOrders: () => void;
  setLoading: (loading: boolean) => void;
  setOrders: (orders: IOrder[]) => void;
  setFormData: (formData: IFormData) => void;
}

const LeftBar: React.FC<IProps> = (props) => {
  const [formData, setFormData] = useState<IFormData>(defaultFormData);
  const [formErrors, setFormErrors] = useState<IFormErrors>(defaultFormErrors);
  const [siteSettings, setSiteSettings] = useState<IGetSiteSettingsResponse>();
  const [uploadedFiles, setUploadedFiles] = useState<Array<File>>([]);
  const [sites, setSites] = useState<any[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { setGlobalError } = useGlobalError()!;

  const checkForErrors = ({ addedFile }: { addedFile: boolean } = { addedFile: false }): boolean => {
    const newFormErrors = {} as IFormErrors;
    newFormErrors.site = !formData.site ? ' Please Select a Site.' : '';
    newFormErrors.fileDrop = uploadedFiles.length <= 0 ? ' Please select a file.' : '';
    if (addedFile) newFormErrors.fileDrop = '';
    setFormErrors(newFormErrors);

    return !!formData.site && uploadedFiles.length > 0;
  };

  const handleSiteChange = (e: React.ChangeEvent<HTMLSelectElement>): void =>
    setFormData({ ...formData, site: e.target.value });

  const handleActualShipping = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setFormData({ ...formData, useActualShipping: e.target.checked });

  const handleResidentialShipping = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setFormData({ ...formData, useResidentialShip: e.target.checked });

  const handleCheckAgainstTradeport = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setFormData({ ...formData, checkAgainstTradeport: e.target.checked });

  const setUploadedFilesToState = (filesArray: File[]): void => {
    const fileNamesFromState = uploadedFiles.map((file) => {
      return file.name;
    });
    const filesToBeAdded = [...filesArray].filter((file) => {
      if (fileNamesFromState.includes(file.name)) return false;
      return true;
    });
    setUploadedFiles([...filesToBeAdded]);
    checkForErrors({ addedFile: true });
  };

  const handleFileRemove = (fileToRemove: string): void => {
    const newUploadedFiles = uploadedFiles.filter((file) => {
      return file.name !== fileToRemove;
    });
    setUploadedFiles([...newUploadedFiles]);
  };

  const clearOrders = (): void => {
    setSiteSettings(undefined);
    props.clearOrders();
  };

  const handleSubmit = async (): Promise<void> => {
    if (checkForErrors()) {
      clearOrders();
      props.setLoading(true);
      parseOrders({
        siteId: formData.site,
        useActualShipping: formData.useActualShipping,
        useResidentialShipping: formData.useResidentialShip,
        file: uploadedFiles[0],
      })
        .then((data) => {
          props.setOrders(data);
          props.setLoading(false);
        })
        .catch((error) => {
          setGlobalError(error);
          props.setLoading(false);
        });
      getSiteSettings(formData.site)
        .then((data) => {
          setSiteSettings(data);
        })
        .catch((error) => {
          setGlobalError(error);
          props.setLoading(false);
        });
    }
  };
  useEffect(() => {
    props.setFormData(formData);
  }, [formData]);

  useEffect(() => {
    getSites()
      .then((data) => {
        setSites(data.map((site) => ({ id: site.Id, value: site.Name || site.Id })));
      })
      .catch((error) => {
        setGlobalError(error);
      });
  }, []);
  return (
    <>
      <div className="order-header">Order Upload</div>
      {!siteSettings && (
        <>
          <div className="order-message">
            This is where you can upload new orders that are in an xls, xlsx, or csv format.
            <br />
            <br />
            The default empty form can be found{' '}
            <a href={`${process.env.PUBLIC_URL}/OrderUploaderForm.xlsx`} download>
              here,{' '}
            </a>
            and the minimal empty form with only required fields can be found{' '}
            <a href={`${process.env.PUBLIC_URL}/OrderUploaderForm-Min.xlsx`} download>
              here.
            </a>
          </div>
          <DropdownField
            label="Select a site:"
            value={formData.site}
            handleChange={handleSiteChange}
            placeholder="Sites"
            options={sites}
            error={formErrors.site}
            isFull
          />
          <FileDrop
            handleRemoveListItem={handleFileRemove}
            uploadedFiles={uploadedFiles.map((file) => file.name)}
            dropzoneOptions={{
              onDrop: (acceptedFiles: File[]): void => setUploadedFilesToState(acceptedFiles),
              accept:
                // eslint-disable-next-line max-len
                '.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xls, application/vnd.ms-excel',
            }}
            error={formErrors.fileDrop}
          />
          <ToggleSwitch
            label="Use Actual Shipping?"
            value={formData.useActualShipping}
            handleChange={handleActualShipping}
            error={formErrors.useActualShipping}
          />
          <ToggleSwitch
            label="Use Residential Shipping?"
            value={formData.useResidentialShip}
            handleChange={handleResidentialShipping}
            error={formErrors.useResidentialShip}
          />
          <ToggleSwitch
            label="Force Check Inventory Against Tradeport?"
            value={formData.checkAgainstTradeport}
            handleChange={handleCheckAgainstTradeport}
            error={formErrors.checkAgainstTradeport}
          />
          <button className="order-submit-button" onClick={handleSubmit}>
            Submit
          </button>
        </>
      )}
      {!!siteSettings && (
        <>
          <h2 className="site-information-header">Site Information</h2>
          <p className="site-information-text">Site Id: {formData.site}</p>
          <p className="site-information-text">Site Name: {siteSettings.SiteName}</p>
          <p className="site-information-text">Catalog Id: {siteSettings.CatalogName}</p>
          <p className="site-information-text">Warehouse: {siteSettings.LocationName}</p>
          <p className="site-information-text">Default Sales Rep: {siteSettings.DefaultSalesRepName}</p>
          <p className="site-information-text">Default Order Class: {siteSettings.OrderClassName}</p>
          <p className="site-information-text">
            Default Payment Term:{' '}
            {siteSettings.CustomPaymentList ? "Customer's Default" : siteSettings.CustomPaymentListDefaultId}
          </p>
          <button className="order-submit-button" onClick={clearOrders}>
            Submit Another Order
          </button>
        </>
      )}
    </>
  );
};

export default LeftBar;
