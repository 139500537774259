import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
// import RequestsITPage from './RequestsIT/RequestsITPage';
import RequestsReportPage from './RequestsReport/RequestsReportPage';
import RequestsWebPage from './RequestsWeb/RequestsWebPage';
import RequestsDefaultPage from './RequestsDefaultPage/RequestsDefaultPage';
import RequestsArtPage from './RequestsArt/RequestsArt';
import RequestsWebArtPage from './RequestsWebArt/RequestsWebArtPage';
import RequestsFlyerPage from './RequestsFlyer/RequestsFlyer';
import RequestsSales from './RequestsSales/RequestsSales';
import NotFoundPage from '../NotFoundPage';
import Submitted from '../Submitted/Submitted';

interface MatchParams {
  name: string;
}

type MatchProps = RouteComponentProps<MatchParams>;

const RequestsPage: React.FC<MatchProps> = (params) => {
  const { match } = params;

  return (
    <Switch>
      <Route exact path={`${match.url}/flyer`} component={RequestsFlyerPage} />
      <Route exact path={`${match.url}/report`} component={RequestsReportPage} />
      <Route exact path={`${match.url}/web`} component={RequestsWebPage} />
      {/* <Route exact path={`${match.url}/it`} component={RequestsITPage} /> */}
      <Route exact path={`${match.url}/art`} component={RequestsArtPage} />
      <Route exact path={`${match.url}/sales`} component={RequestsSales} />
      <Route exact path={`${match.url}/webart`} component={RequestsWebArtPage} />
      <Route exact path={`${match.url}/submitted`} component={Submitted} />
      <Route exact path={`${match.url}/`} component={RequestsDefaultPage} />
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default RequestsPage;
