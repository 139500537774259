import React from 'react';
import RequestsTemplate from '../RequestsTemplate/RequestsTemplate';
import { formData, extraFields } from './RequestsSalesSettings';

const RequestsSales: React.FC = () => {
  return (
    <RequestsTemplate
      formName="Sales Admin Request Form"
      formData={formData}
      endpoint="sales"
      projects="1201130417250592"
      getAsanaName={(formData: any): string => formData.subject}
      getAsanaNote={(formData: any): string =>
        `${formData.name}\nEmail: ${formData.email}\nDescription:\n${formData.comments}`
      }
      getAsanaDueOn={(formData: any): string => formData.dueDate}
      extraFields={extraFields}
    />
  );
};

export default RequestsSales;
