import React, { useState, useEffect, useCallback } from 'react';
import { defaultFormData, defaultFormErrors, IFormData, IFormErrors, fakeFormData, IFlyerProduct } from './Interfaces';
import InputTextField from '../../../components/form/InputTextField';
import FileDrop from '../../../components/form/FileDrop/FileDrop';
import DropdownField from '../../../components/form/DropdownField';
import InputDateField from '../../../components/form/InputDateField';
import RemoveableList from '../../../components/form/RemoveableList/RemoveableList';
import InputTextAreaField from '../../../components/form/InputTextAreaField/InputTextAreaField';
import SubmitBtn from '../../../components/form/SubmitBtn';
import { setUploadedFilesToState, postToServerToState, vaildEmail } from '../../../shared/utilities';
import { Redirect } from 'react-router-dom';
import { useGlobalError } from '../../../providers/ErrorProvider';
import '../../../components/form/form.scss';
import { FlyerProject } from '../../../shared/RequestsAsanaKeys';
import { flyerNotes } from './NotesFlyer';

const RequestsFlyerPage: React.FC = () => {
  const [formData, setFormData] = useState<IFormData>(defaultFormData);
  const [formErrors, setFormErrors] = useState<IFormErrors>(defaultFormErrors);
  const [uploadedFiles, filesForAsana] = useState<Array<File>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [redirect, setRedirect] = useState<string>('');

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { setGlobalError } = useGlobalError()!;

  const handleUserKeyPress = useCallback((event: KeyboardEvent) => {
    const { key, shiftKey, ctrlKey } = event;
    if (key === 'T' && shiftKey && ctrlKey) {
      setFormData(fakeFormData);
    }
  }, []);
  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);

    return (): void => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  const handleFormChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    propertyToChange: string,
  ): void => setFormData({ ...formData, [propertyToChange]: e.target.value });

  const setUploadedFiles = (filesArray: File[]): any =>
    setUploadedFilesToState(filesArray, formErrors, setFormErrors, filesForAsana, uploadedFiles);

  const postToServer = (): any => {
    const message = {
      data: {
        // eslint-disable-next-line
          "custom_fields": {
          [FlyerProject.account]: `${formData.account}`,
          // eslint-disable-next-line
          [FlyerProject.projectType]: FlyerProject.flyerProject
        },
        name: `${formData.title}`,
        // eslint-disable-next-line @typescript-eslint/camelcase
        html_notes: flyerNotes(formData),
        // eslint-disable-next-line @typescript-eslint/camelcase
        due_on: `${formData.requestDate}`,
        projects: [FlyerProject.projects],
        // eslint-disable-next-line
      }
    };
    return postToServerToState(formData, uploadedFiles, setRedirect, setGlobalError, message);
  };

  const handleFileRemove = (fileToRemove: string): void => {
    const newUploadedFiles = uploadedFiles.filter((file) => {
      return file.name !== fileToRemove;
    });
    filesForAsana([...newUploadedFiles]);
  };

  const flyerProducts: IFlyerProduct = {
    productName: formData.productName,
    productColors: formData.productColors,
    minimumQuantity: formData.minimumQuantity,
    productSku: formData.productSku,
    pricing: formData.pricing,
    additionalPricing: formData.additionalPricing,
    size: formData.size,
  };

  const handleAddProducts = (): void => {
    setFormData({
      ...formData,
      flyerProducts: [...formData.flyerProducts, flyerProducts],
      productName: '',
      productColors: '',
      minimumQuantity: '',
      productSku: '',
      pricing: '',
      additionalPricing: '',
      size: '',
    });
  };

  const handleRemoveProduct = (linkToRemove: string | number): void => {
    const newProduct = formData.flyerProducts.filter((product) => {
      return product.productName !== linkToRemove;
    });
    setFormData({ ...formData, flyerProducts: newProduct });
  };

  const handleSubmit = async (): Promise<void> => {
    setIsLoading(true);
    const newFormErrors = {} as IFormErrors;
    newFormErrors.email =
      // eslint-disable-next-line
      !formData.email || !vaildEmail.test(formData.email)
        ? 'Please enter a valid email'
        : '';

    newFormErrors.name = !formData.name ? 'Please enter your name' : '';
    newFormErrors.phone = !formData.phone ? 'Please enter your phone number' : '';
    newFormErrors.account = !formData.account ? 'Please enter a account' : '';
    newFormErrors.email = !formData.email ? 'Please enter a email' : '';
    newFormErrors.title = !formData.title ? 'Please enter a flyer title' : '';
    newFormErrors.requestDate = !formData.requestDate ? 'Please enter a request date' : '';

    setFormErrors(newFormErrors);
    if (!Object.values(newFormErrors).find((error) => error !== '')) {
      await postToServer();
    } else {
      setGlobalError('Please fill out entire form.');
      window.scrollTo(0, 0);
    }
    setIsLoading(false);
  };

  return (
    <div className="form-container">
      {redirect && <Redirect to={redirect} />}
      <div className="form-title">Flyer Request Form</div>
      <div className="field-container">
        <div className="form-subtitle">Contact Information</div>
        <InputTextField
          label="Name:"
          value={formData.name}
          handleChange={(e): void => handleFormChange(e, 'name')}
          error={formErrors.name}
        />
        <InputTextField
          label="Email:"
          value={formData.email}
          handleChange={(e): void => handleFormChange(e, 'email')}
          error={formErrors.email}
        />
        <InputTextField
          handleChange={(e): void => handleFormChange(e, 'phone')}
          label="Phone:"
          value={formData.phone}
          error={formErrors.phone}
        />
        <InputTextField
          handleChange={(e): void => handleFormChange(e, 'account')}
          label="Account:"
          value={formData.account}
          error={formErrors.account}
        />
        <DropdownField
          label="Delivery Method:"
          value={formData.deliveryMethod}
          handleChange={(e): void => handleFormChange(e, 'deliveryMethod')}
          placeholder="Delivery Method"
          options={['PDF', 'Email']}
          error={formErrors.deliveryMethod}
        />
        <div className="form-subtitle">General Information</div>
        <InputTextField
          label="Flyer Title"
          value={formData.title}
          handleChange={(e): void => handleFormChange(e, 'title')}
          error={formErrors.title}
        />
        <InputDateField
          label="Requested Send Date"
          value={formData.requestDate}
          handleChange={(e): void => handleFormChange(e, 'requestDate')}
          placeholder={new Date().toDateString()}
          error={formErrors.requestDate}
        />
        <DropdownField
          label="Compass Website Update?"
          value={formData.websiteUpdate}
          handleChange={(e): void => handleFormChange(e, 'websiteUpdate')}
          placeholder="Wesite Update"
          options={['Yes', 'No']}
          error={formErrors.websiteUpdate}
        />
        <DropdownField
          label="Type of order form?"
          value={formData.orderForm}
          handleChange={(e): void => handleFormChange(e, 'orderForm')}
          placeholder="Type of order form"
          options={['In Stock', 'At']}
        />
        <InputTextAreaField
          label="Requested Flyer Text"
          value={formData.flyerText}
          handleChange={(e): void => handleFormChange(e, 'flyerText')}
          isFull
        />
        <div className="form-subtitle">Product Information</div>
        <InputTextField
          label="Product Name"
          value={formData.productName}
          handleChange={(e): void => handleFormChange(e, 'productName')}
        />
        <InputTextField
          label="Product Colors"
          value={formData.productColors}
          handleChange={(e): void => handleFormChange(e, 'productColors')}
        />
        <InputTextField
          label="Minimum Order Quantity:"
          value={formData.minimumQuantity}
          handleChange={(e): void => handleFormChange(e, 'minimumQuantity')}
        />
        <InputTextField
          label="Product Sku"
          value={formData.productSku}
          handleChange={(e): void => handleFormChange(e, 'productSku')}
        />
        <InputTextField
          label="Pricing"
          value={formData.pricing}
          handleChange={(e): void => handleFormChange(e, 'pricing')}
        />
        <InputTextField
          label="Additional Pricing Info"
          value={formData.additionalPricing}
          handleChange={(e): void => handleFormChange(e, 'additionalPricing')}
        />
        <InputTextField
          label="Size(s)"
          value={formData.size}
          handleChange={(e): void => handleFormChange(e, 'size')}
          isFull
        />
        <button className="productButton" onClick={handleAddProducts}>
          Add Product
        </button>
        <RemoveableList
          listToDisplay={formData.flyerProducts.map((product) => {
            return product.productName;
          })}
          handleRemoveListItem={(productToRemove): void => {
            handleRemoveProduct(productToRemove);
          }}
        />
        <div className="note">
          NOTE: If product name shows in the list below; the rest of the product info has been updated as well.
        </div>
        <InputTextAreaField
          label="Additional Comments/Notes:"
          value={formData.comments}
          handleChange={(e): void => handleFormChange(e, 'comments')}
          isFull
        />
        <FileDrop
          handleRemoveListItem={handleFileRemove}
          uploadedFiles={uploadedFiles.map((file) => file.name)}
          dropzoneOptions={{
            onDrop: (acceptedFiles: File[]): void => setUploadedFiles(acceptedFiles),
          }}
          error={formErrors.fileDrop}
        />
        <SubmitBtn isLoading={isLoading} handleChange={handleSubmit} />
      </div>
    </div>
  );
};

export default RequestsFlyerPage;
