import React, { useState, useEffect } from 'react';
import { SlideDown } from 'react-slidedown';
import cx from 'classnames';

import './NavBar.scss';
import 'react-slidedown/lib/slidedown.css';
import { NavLink, Link } from 'react-router-dom';
import { useAuthState } from '../../providers/AuthProvider';
import BookIcon from '../../images/BookIcon';
import EasterEggs from '../../easterEggs/EasterEggs';
import { verifyPermission } from '../../shared/endpointAccess/permissions';

interface IProps {
  children: React.ReactNode;
}

const menuAreaRef = React.createRef<HTMLDivElement>();
const contentAreaRef = React.createRef<HTMLDivElement>();

const handleMenuMouseEnter = (): void => {
  if (menuAreaRef.current && contentAreaRef.current) {
    menuAreaRef.current.style.width = '25rem';
    contentAreaRef.current.style.opacity = '45%';
    contentAreaRef.current.style.zIndex = '5';
  }
};

const handleMenuMouseLeave = (): void => {
  if (menuAreaRef.current && contentAreaRef.current) {
    menuAreaRef.current.style.width = '6rem';
    contentAreaRef.current.style.opacity = '0';
    contentAreaRef.current.style.zIndex = '-1';
  }
};

const NavBar: React.FC<IProps> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { logout, authState, login } = useAuthState()!;
  const [requestMenuOpen, setRequestMenuOpen] = useState<boolean>(window.location.href.includes('/requests/'));
  const [templateMenuOpen, setTemplateMenuOpen] = useState<boolean>(false);
  const [webTeamMenuOpen, setWebTeamMenuOpen] = useState<boolean>(window.location.href.includes('/webteam/'));
  const [currentLink, setCurrentLink] = useState<string>(window.location.href);
  const [webTeamToolsAccess, setWebTeamToolsAccess] = useState<boolean>(false);
  const [orderUploadAccess, setOrderUploadAccess] = useState<boolean>(false);
  const [orderFulfillAccess, setOrderFulfillAccess] = useState<boolean>(false);
  const [siteSettingsAccess, setSiteSettingsAccess] = useState<boolean>(false);
  const [orderPaymentTermAccess, setOrderPaymentTermAccess] = useState<boolean>(false);

  const updateCurrentLink = (): void => {
    setCurrentLink(window.location.href);
  };

  useEffect(() => {
    if (!authState || !authState.user || !authState.user.email) return;

    verifyPermission('orderUploader', authState.user.email).then((res) => {
      setOrderUploadAccess(res);
      if (res) setWebTeamToolsAccess(true);
    });
    verifyPermission('orderFulfillment', authState.user.email).then((res) => {
      setOrderFulfillAccess(res);
      if (res) setWebTeamToolsAccess(true);
    });
    verifyPermission('siteSettings', authState.user.email).then((res) => {
      setSiteSettingsAccess(res);
      if (res) setWebTeamToolsAccess(true);
    });
    verifyPermission('orderPaymentTerm', authState.user.email).then((res) => {
      setOrderPaymentTermAccess(res);
      if (res) setWebTeamToolsAccess(true);
    });
  }, [authState]);

  return (
    <div className="global-container">
      <div className="menu-area" ref={menuAreaRef}></div>
      <div className="opacity" ref={contentAreaRef}></div>
      <div className="content-area">{props.children}</div>
      <nav className="main-menu" onMouseEnter={handleMenuMouseEnter} onMouseLeave={handleMenuMouseLeave}>
        <ul>
          <li className="home" onClick={updateCurrentLink}>
            <NavLink className="nav-link" activeClassName="active" to={authState.isAuthenticated ? '/home' : '/'}>
              <i className="fa fa-home fa-2x"></i>
              <span className="nav-text">{authState.isAuthenticated ? 'Home' : 'Welcome'}</span>
            </NavLink>
          </li>
          <li className="nav" onClick={updateCurrentLink}>
            <NavLink className="nav-link" activeClassName="active" to="/book">
              <BookIcon className={cx('nav-svg-icon', { 'nav-svg-icon-active': currentLink.includes('/book') })} />
              <span className="nav-text">Desk Book</span>
            </NavLink>
          </li>
          <li
            className="nav"
            onClick={(): void => {
              setRequestMenuOpen(!requestMenuOpen);
              updateCurrentLink();
            }}
          >
            <i
              className={cx('fa nav-caret', {
                'fa-caret-down': !requestMenuOpen,
                'fa-caret-up': requestMenuOpen,
                'nav-caret-active': currentLink.includes('/requests'),
              })}
            ></i>
            <NavLink className="nav-link" activeClassName="active" to="/requests">
              <i className="fa fa-send-o fa-2x"></i>
              <span className="nav-text">Requests</span>
            </NavLink>
          </li>
          <SlideDown closed={!requestMenuOpen} className={'nav-sub-menu'}>
            <li className="nav" onClick={updateCurrentLink}>
              <NavLink className="nav-link" activeClassName="active" to="/requests/art">
                <i className="fa fa-paint-brush fa-2x"></i>
                <span className="nav-text subnav">Art Request</span>
              </NavLink>
            </li>
            <li className="nav" onClick={updateCurrentLink}>
              <NavLink className="nav-link" activeClassName="active" to="/requests/flyer">
                <i className="fa fa-file-pdf-o fa-2x"></i>
                <span className="nav-text subnav">Flyer Request</span>
              </NavLink>
            </li>
            {/* <li className="nav" onClick={updateCurrentLink}>
              <NavLink className="nav-link" activeClassName="active" to="/requests/it">
                <i className="fa fa-laptop fa-2x"></i>
                <span className="nav-text subnav">IT Request</span>
              </NavLink>
            </li> */}
            <li className="nav" onClick={updateCurrentLink}>
              <NavLink className="nav-link" activeClassName="active" to="/requests/report">
                <i className="fa fa-line-chart fa-2x"></i>
                <span className="nav-text subnav">Reports Request</span>
              </NavLink>
            </li>
            <li className="nav" onClick={updateCurrentLink}>
              <NavLink className="nav-link" activeClassName="active" to="/requests/sales">
                <i className="fa fa-briefcase fa-2x"></i>
                <span className="nav-text subnav">Sales Admin Request</span>
              </NavLink>
            </li>
            <li className="nav" onClick={updateCurrentLink}>
              <NavLink className="nav-link" activeClassName="active" to="/requests/webart">
                <i className="fa fa-camera-retro fa-2x"></i>
                <span className="nav-text subnav">Web Image Request</span>
              </NavLink>
            </li>
            <li className="nav" onClick={updateCurrentLink}>
              <NavLink className="nav-link" activeClassName="active" to="/requests/web">
                <i className="fa fa-window-restore fa-2x"></i>
                <span className="nav-text subnav">Web Request</span>
              </NavLink>
            </li>
            <li className="nav">
              <a
                href={authState.isAuthenticated ? 'https://cjb5grrt.paperform.co/' : ''}
                target={authState.isAuthenticated ? '_blank' : ''}
                rel="noreferrer"
              >
                <i className="fa fa-shopping-bag fa-2x"></i>
                <span className="nav-text subnav">Products Team Request</span>
              </a>
            </li>
          </SlideDown>
          <li className="nav">
            <a
              href={authState.isAuthenticated ? 'https://xqahiyat.paperform.co/' : ''}
              target={authState.isAuthenticated ? '_blank' : ''}
              rel="noreferrer"
            >
              <i className="fa fa-edit fa-2x"></i>
              <span className="nav-text">Special Project Request</span>
            </a>
          </li>
          <li className="nav">
            <a
              href={authState.isAuthenticated ? 'http://questions.iceboxcoolstuff.com/' : ''}
              target={authState.isAuthenticated ? '_blank' : ''}
              rel="noreferrer"
            >
              <i className="fa fa-bullhorn fa-2x"></i>
              <span className="nav-text">Standing 10</span>
            </a>
          </li>
          <li
            className="nav"
            onClick={(): void => {
              setTemplateMenuOpen(!templateMenuOpen);
            }}
          >
            <i
              className={cx('fa nav-caret', {
                'fa-caret-down': !templateMenuOpen,
                'fa-caret-up': templateMenuOpen,
              })}
            ></i>
            <div className="nav-link">
              <i className="fa fa-copy fa-2x"></i>
              <span className="nav-text">Templates</span>
            </div>
          </li>
          <SlideDown closed={!templateMenuOpen} className={'nav-sub-menu'}>
            <li className="nav" onClick={updateCurrentLink}>
              <a
                href={
                  authState.isAuthenticated
                    ? `${process.env.PUBLIC_URL}/Template for New Non-Apparel Sku Request 09.09.22.xlsx`
                    : ''
                }
                download
              >
                <i className="fa fa-download fa-2x"></i>
                <span className="nav-text subnav">Promo SKU Setup</span>
              </a>
            </li>
            <li className="nav" onClick={updateCurrentLink}>
              <a
                href={
                  authState.isAuthenticated
                    ? `${process.env.PUBLIC_URL}/Template for New Apparel Sku Request 08.18.22.xlsx`
                    : ''
                }
                download
              >
                <i className="fa fa-download fa-2x"></i>
                <span className="nav-text subnav">Apparel SKU Setup</span>
              </a>
            </li>
          </SlideDown>
          {webTeamToolsAccess && (
            <>
              <li
                className="nav"
                onClick={(): void => {
                  setWebTeamMenuOpen(!webTeamMenuOpen);
                  updateCurrentLink();
                }}
              >
                <i
                  className={cx('fa nav-caret', {
                    'fa-caret-down': !webTeamMenuOpen,
                    'fa-caret-up': webTeamMenuOpen,
                    'nav-caret-active': currentLink.includes('/webteam'),
                  })}
                ></i>
                <a className={cx('nav-link', { active: currentLink.includes('/webteam') })}>
                  <i className="fa fa-desktop fa-2x"></i>
                  <span className="nav-text">WebTeam Tools</span>
                </a>
              </li>
              <SlideDown closed={!webTeamMenuOpen} className={'nav-sub-menu'}>
                {orderUploadAccess && (
                  <li className="nav" onClick={updateCurrentLink}>
                    <NavLink className="nav-link" activeClassName="active" to="/webteam/orderupload">
                      <i className="fa fa-upload fa-2x"></i>
                      <span className="nav-text">Order Upload</span>
                    </NavLink>
                  </li>
                )}
                {orderFulfillAccess && (
                  <li className="nav" onClick={updateCurrentLink}>
                    <NavLink className="nav-link" activeClassName="active" to="/webteam/orderfulfillment">
                      <i className="fa fa-truck fa-2x"></i>
                      <span className="nav-text">Fulfill Order</span>
                    </NavLink>
                  </li>
                )}
                {siteSettingsAccess && (
                  <li className="nav" onClick={updateCurrentLink}>
                    <NavLink className="nav-link" activeClassName="active" to="/webteam/sitesettings">
                      <i className="fa fa-sitemap fa-2x"></i>
                      <span className="nav-text">Site Settings</span>
                    </NavLink>
                  </li>
                )}
                {orderPaymentTermAccess && (
                  <li className="nav" onClick={updateCurrentLink}>
                    <NavLink className="nav-link" activeClassName="active" to="/webteam/orderpaymentterms">
                      <i className="fa fa-usd fa-2x"></i>
                      <span className="nav-text">Set Payment Terms</span>
                    </NavLink>
                  </li>
                )}
              </SlideDown>
            </>
          )}
        </ul>

        <ul className="logout" onClick={updateCurrentLink}>
          {authState.isAuthenticated ? (
            <li>
              <Link onClick={logout} to="#">
                <i className="fa fa-sign-out fa-2x"></i>
                <span className="nav-text">Logout</span>
              </Link>
            </li>
          ) : (
            <li>
              <Link onClick={login} to="#">
                <i className="fa fa-sign-in fa-2x"></i>
                <span className="nav-text">Log In</span>
              </Link>
            </li>
          )}
        </ul>
      </nav>
      <EasterEggs />
    </div>
  );
};

export default NavBar;
