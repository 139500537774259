import React, { useState, useEffect, useCallback } from 'react';
import InputTextField from '../../../components/form/InputTextField';
import FileDrop from '../../../components/form/FileDrop/FileDrop';
import DropdownField from '../../../components/form/DropdownField';
import InputTextAreaField from '../../../components/form/InputTextAreaField/InputTextAreaField';
import InputDateField from '../../../components/form/InputDateField';
import { serverURL, totalEmailUploadSizeLimit, bytesToMB } from '../../../shared/utilities';
import '../../../components/form/form.scss';
import { useGlobalError } from '../../../providers/ErrorProvider';
import { Redirect } from 'react-router-dom';
import { addAsanaTask, addAttachmentsToAsanaTask } from '../../../shared/endpointAccess/asana';
import ListItemField from '../../../components/form/ListItemField/ListItemField';

interface IProps {
  formName: string;
  formData: {
    id?: string;
    label?: string;
    default?: string;
    fakeData?: string;
    errorMessage?: string;
    errorValidation?: (fieldName: string) => boolean;
    placeholder?: string;
    options?: Array<string | { id: string; value: string }>;
    type: string;
    isFull?: boolean;
    optional?: boolean;
  }[];
  endpoint: string;
  projects: string;
  extraFields?: {
    [extraField: string]: {
      asanaId: string;
      getIdFromValue?: (data: string) => string;
    };
  };
  getAsanaName: (formData: any) => string;
  getAsanaNote: (formData: any) => string;
  getAsanaDueOn: (formData: any) => string;
}

const RequestsTemplate: React.FC<IProps> = (props) => {
  const [formData, setFormData] = useState<{ [fieldName: string]: string }>(
    props.formData.reduce((prev: any, cur) => {
      if (!cur.id) return prev;
      prev[cur.id] = cur.default || '';
      return prev;
    }, {}),
  );
  const [formErrors, setFormErrors] = useState<{ [fieldName: string]: string }>(
    props.formData.reduce((prev: any, cur) => {
      if (!cur.id) return prev;
      prev[cur.id] = '';
      return prev;
    }, {}),
  );
  const [uploadedFiles, setUploadedFiles] = useState<Array<File>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [redirect, setRedirect] = useState<string>('');

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { setGlobalError } = useGlobalError()!;

  const handleUserKeyPress = useCallback((event: KeyboardEvent) => {
    const { key, shiftKey, ctrlKey } = event;
    if (key === 'T' && shiftKey && ctrlKey) {
      setFormData(
        props.formData.reduce((prev: any, cur) => {
          if (!cur.id) return prev;
          prev[cur.id] = cur.fakeData;
          return prev;
        }, {}),
      );
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);

    return (): void => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  const handleFormChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    propertyToChange: string,
  ): void => {
    setFormData({ ...formData, [propertyToChange]: e.target.value });
  };

  const setUploadedFilesToState = (filesArray: File[]): void => {
    let totalFileSize = 0;
    const fileNamesFromState = uploadedFiles.map((file) => {
      totalFileSize += file.size;
      return file.name;
    });
    const filesToBeAdded = [...filesArray].filter((file) => {
      if (fileNamesFromState.includes(file.name)) return false;
      totalFileSize += file.size;
      return true;
    });
    if (totalFileSize > totalEmailUploadSizeLimit) {
      return setFormErrors({
        ...formErrors,
        fileDrop: `Attachments(${bytesToMB(totalFileSize)}) will exceeded maximum allowed size of ${bytesToMB(
          totalEmailUploadSizeLimit,
        )}.`,
      });
    }
    setFormErrors({ ...formErrors, fileDrop: '' });
    setUploadedFiles([...uploadedFiles, ...filesToBeAdded]);
  };

  const handleFileRemove = (fileToRemove: string): void => {
    const newUploadedFiles = uploadedFiles.filter((file) => {
      return file.name !== fileToRemove;
    });
    setUploadedFiles([...newUploadedFiles]);
  };

  const postToServer = async (): Promise<void> => {
    const fd = new FormData() as any;
    uploadedFiles.forEach((file) => {
      fd.append('file', file);
    });
    const data = JSON.stringify(formData);
    fd.append('data', data);

    const message = {
      data: {
        name: props.getAsanaName(formData),
        notes: props.getAsanaNote(formData),
        // eslint-disable-next-line @typescript-eslint/camelcase
        due_on: props.getAsanaDueOn(formData),
        // eslint-disable-next-line @typescript-eslint/camelcase
        custom_fields: {} as any,
        projects: [props.projects],
        followers: [formData.email, 'technology@iceboxmail.com'],
      },
    };
    if (props.extraFields) {
      for (const extraField in props.extraFields) {
        if (!props.extraFields[extraField].getIdFromValue) {
          message.data['custom_fields'][props.extraFields[extraField].asanaId] = formData[extraField];
        } else if ((props.extraFields[extraField].getIdFromValue as any)(formData[extraField]) !== '') {
          message.data['custom_fields'][props.extraFields[extraField].asanaId] = (props.extraFields[extraField]
            .getIdFromValue as any)(formData[extraField]);
        }
      }
    }
    const asanaResponse = await addAsanaTask(message).catch((error) => {
      setGlobalError(error.message);
    });
    const ableToUploadFiles = await addAttachmentsToAsanaTask(asanaResponse.data.gid, uploadedFiles);
    if (ableToUploadFiles) {
      const response = await fetch(`${serverURL}/api/requests/${props.endpoint}`, {
        method: 'POST',
        body: fd,
      });
      if (response.ok) {
        setRedirect('/requests/submitted');
      } else {
        setGlobalError(`Asana task successfully created, but confirmation email failed to send`);
      }
    } else {
      setGlobalError(`The attachments have failed to load`);
    }
  };

  const handleSubmit = async (): Promise<void> => {
    setIsLoading(true);
    setGlobalError('');
    const newFormErrors = {} as { [fieldName: string]: string };

    for (const field of props.formData) {
      if (!field.id) continue;

      if (field.errorValidation)
        newFormErrors[field.id] = field.errorValidation(formData[field.id]) ? field.errorMessage || '' : '';
      else newFormErrors[field.id] = !formData[field.id] ? field.errorMessage || '' : '';
    }

    setFormErrors(newFormErrors);
    if (!Object.values(newFormErrors).find((error) => error !== '')) {
      await postToServer();
    } else {
      setGlobalError('Please fill out entire form.');
      window.scrollTo(0, 0);
    }
    setIsLoading(false);
  };

  const handleAddTemplateLink = (): void => {
    setFormData({
      ...formData,
      templateLinks: [...(((formData.templateLinks as any) as string[]) || []), formData.templateLink] as any,
      templateLink: '',
    });
  };

  const handleRemoveTemplateLink = (linkToRemove: string | number): void => {
    const newTemplateLinks = ((formData.templateLinks as any) as string[]).filter((link) => {
      return link !== linkToRemove;
    });
    setFormData({ ...formData, templateLinks: newTemplateLinks as any });
  };

  return (
    <div className="form-container">
      {redirect && <Redirect to={redirect} />}
      <div className="form-title">{props.formName}</div>
      <div className="field-container">
        {props.formData.map((field) => {
          switch (field.type) {
            case 'subTitle':
              return (
                <div className="form-subtitle" key={field.label}>
                  {field.label}
                </div>
              );
            case 'textField':
              return (
                <InputTextField
                  automationId={field.id}
                  label={field.label || ''}
                  value={formData[field.id || 'default']}
                  handleChange={(e): void => handleFormChange(e, field.id || 'default')}
                  error={formErrors[field.id || 'default']}
                  placeholder={field.placeholder}
                  isFull={field.isFull || false}
                  key={field.label}
                />
              );
            case 'textArea':
              return (
                <InputTextAreaField
                  automationId={field.id}
                  label={field.label || ''}
                  value={formData[field.id || 'default']}
                  handleChange={(e): void => handleFormChange(e, field.id || 'default')}
                  error={field.optional ? undefined : formErrors[field.id || 'default']}
                  isFull={field.isFull || false}
                  key={field.label}
                />
              );
            case 'dateField':
              return (
                <InputDateField
                  automationId={field.id}
                  label={field.label || ''}
                  placeholder={field.placeholder || new Date().toDateString()}
                  value={formData[field.id || 'default']}
                  handleChange={(e): void => handleFormChange(e, field.id || 'default')}
                  error={field.optional ? undefined : formErrors[field.id || 'default']}
                  key={field.label}
                />
              );
            case 'dropdownField':
              return (
                <DropdownField
                  automationId={field.id}
                  label={field.label || ''}
                  placeholder={field.placeholder}
                  value={formData[field.id || 'default']}
                  options={field.options || []}
                  handleChange={(e): void => handleFormChange(e, field.id || 'default')}
                  error={formErrors[field.id || 'default']}
                  key={field.label}
                />
              );
            case 'fileDrop':
              return (
                <FileDrop
                  handleRemoveListItem={handleFileRemove}
                  uploadedFiles={uploadedFiles.map((file) => file.name)}
                  dropzoneOptions={{
                    onDrop: (acceptedFiles: File[]): void => setUploadedFilesToState(acceptedFiles),
                  }}
                  error={formErrors.fileDrop}
                  key="fileDrop"
                />
              );
            case 'templateLinks':
              return (
                <ListItemField
                  label={field.label || ''}
                  handleChange={(e): void => handleFormChange(e, 'templateLink')}
                  handleAddToList={handleAddTemplateLink}
                  value={formData.templateLink}
                  listToDisplay={(formData.templateLinks as any) || []}
                  handleRemoveListItem={(linkToRemove): void => {
                    handleRemoveTemplateLink(linkToRemove);
                  }}
                  isFull
                  placeholder="Press Enter or click Add to include item"
                  key={field.label}
                />
              );
          }
        })}
        {isLoading ? (
          <button className="form-submit-button loading" disabled>
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </button>
        ) : (
          <button className="form-submit-button" onClick={handleSubmit}>
            Submit Request
          </button>
        )}
      </div>
    </div>
  );
};

export default RequestsTemplate;
