import React from 'react';
import RequestsTemplate from '../RequestsTemplate/RequestsTemplate';
import { formData, extraFields } from './RequestReportPageSettings';

const RequestsReportPage: React.FC = () => {
  return (
    <RequestsTemplate
      formName="Report Request Form"
      formData={formData}
      endpoint="report"
      projects="1150089822614131"
      getAsanaName={(formData: any): string => formData.subject}
      getAsanaNote={(formData: any): string =>
        `${formData.name}\nEmail: ${formData.email}\nIssue:\n${formData.comments}`
      }
      getAsanaDueOn={(formData: any): string => formData.dueDate}
      extraFields={extraFields}
    />
  );
};

export default RequestsReportPage;
