import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PrivateRoute, { IPrivateRouteProps } from './PrivateRoute';

// Routes
import HomePage from '../pages/HomePage/HomePage';
import BookPage from '../pages/BookPage/BookPage';
import RequestsRoutes from '../pages/Requests/RequestsRoutes';
import NotFoundPage from '../pages/NotFoundPage';
import LandingPage from '../pages/LandingPage/LandingPage';
import RestrictedPage from '../pages/RestrictedPage';
import NavBar from '../components/navbar/NavBar';
import { ErrorProvider } from '../providers/ErrorProvider';
import GlobalErrorDisplay from '../components/error/GlobalErrorDisplay';
import { AuthProvider } from '../providers/AuthProvider';
import WebTeamRoutes from '../pages/WebTeamTools/WebTeamRoutes';

const isAllowed = (): boolean => {
  // TODO: Needs implementation
  return true;
};

const defaultPrivateRouteProps: IPrivateRouteProps = {
  isAllowed: isAllowed(),
  restrictedPath: '/',
  unauthenticatedPath: '/',
};

const App: React.SFC = () => (
  <BrowserRouter>
    <ErrorProvider>
      <GlobalErrorDisplay />
      <AuthProvider>
        <NavBar>
          <Switch>
            <Route exact path="/" component={LandingPage} />
            <Route path="/restricted" component={RestrictedPage} />
            <PrivateRoute {...defaultPrivateRouteProps} path="/home" component={HomePage} />
            <PrivateRoute {...defaultPrivateRouteProps} path="/webteam" component={WebTeamRoutes} />
            <PrivateRoute {...defaultPrivateRouteProps} path="/requests" component={RequestsRoutes} />
            <PrivateRoute {...defaultPrivateRouteProps} path="/book" component={BookPage} />
            <Route component={NotFoundPage} />
          </Switch>
        </NavBar>
      </AuthProvider>
    </ErrorProvider>
  </BrowserRouter>
);

export default App;
