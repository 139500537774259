import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import './ProductsTab.scss';

import { IProduct } from '../../../../shared/endpointAccess/interfaces/productInterfaces';

interface Props {
  currentTab: string;
  products: IProduct[];
  productsValid: boolean;
  productValidationErrors: string[];
}

const ProductsTab: React.FC<Props> = ({
  currentTab = '',
  products = [],
  productsValid = false,
  productValidationErrors = [],
}) => {
  const [productValidationErrorDictionary, setProductValidationErrorDictionary] = useState<any>({});

  useEffect(() => {
    if (Array.isArray(productValidationErrors) && productValidationErrors.length > 0) {
      const errorDictionary = {} as any;
      productValidationErrors.forEach((error) => {
        const regex = /Product Sku: ([^,;\n]*)/g;
        const found = regex.exec(error);
        if (found && found.length > 1) {
          errorDictionary[found[1]] = error;
        }
      });
      setProductValidationErrorDictionary(errorDictionary);
    } else {
      setProductValidationErrorDictionary({});
    }
  }, [productValidationErrors]);

  let verifiedText: any;
  if (!Array.isArray(productValidationErrors) || productValidationErrors.length > 0) {
    verifiedText = (
      <p>
        <i className="fa fa-times inline"></i> Errors during verification.
      </p>
    );
  } else if (!productsValid && productValidationErrors.length === 0) {
    verifiedText = <p>Waiting to verify...</p>;
  } else {
    verifiedText = (
      <p>
        <i className="fa fa-check inline"></i> Verified
      </p>
    );
  }
  return (
    <>
      {currentTab === 'Products' && (
        <>
          <div className="products-header-bar">
            <div className="products-header-sku">
              <p>Product SKU</p>
            </div>
            <div className="products-header-quantity">
              <p>Quantity</p>
            </div>
            <div className="products-header-validity">
              <p>Is Valid?</p>
            </div>
          </div>
          <div className="all-products-height-setup">
            <div className="all-products">
              {products.map((product) => (
                <div className="product-container" key={product.productSKU}>
                  <div
                    className={cx('product-sku', {
                      'error-color':
                        product.productSKU in productValidationErrorDictionary &&
                        productValidationErrors.length > 0 &&
                        productValidationErrorDictionary[product.productSKU].includes('Product SKU does not match SKU'),
                    })}
                  >
                    <p>{product.productSKU}</p>
                  </div>
                  <div
                    className={cx('product-quantity', {
                      'error-color':
                        product.productSKU in productValidationErrorDictionary &&
                        productValidationErrors.length > 0 &&
                        productValidationErrorDictionary[product.productSKU].includes(
                          'there is not enough in Compass inventory',
                        ),
                    })}
                  >
                    {product.quantity}
                  </div>
                  <div
                    className={cx('product-valid', {
                      'success-color':
                        productsValid ||
                        (!(product.productSKU in productValidationErrorDictionary) &&
                          productValidationErrors.length > 0),
                      'error-color':
                        product.productSKU in productValidationErrorDictionary && productValidationErrors.length > 0,
                    })}
                  >
                    <div>
                      {!(product.productSKU in productValidationErrorDictionary) &&
                      productValidationErrors.length > 0 ? (
                        <p>
                          <i className="fa fa-check inline"></i> Verified
                        </p>
                      ) : (
                        verifiedText
                      )}
                      {product.productSKU in productValidationErrorDictionary && productValidationErrors.length > 0 ? (
                        <p>{productValidationErrorDictionary[product.productSKU]}</p>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ProductsTab;
