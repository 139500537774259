import { serverURL } from '../../shared/utilities';
import jwt from 'jsonwebtoken';

export const getApi = (apiUrl: string): any => {
  return new Promise((resolve, reject) => {
    fetch(`${serverURL}/api/${apiUrl}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt.sign({}, process.env.REACT_APP_SERVER_SECRET as string, { expiresIn: '5m' })}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          return res.text().then((data) => {
            throw new Error(data);
          });
        }
        res.json().then((data) => {
          resolve(data);
        });
      })
      .catch((error) => {
        reject(`Something went wrong... Error: ${error.message}`);
      });
  });
};
