import React from 'react';
interface IProps {
  handleChange: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isLoading?: boolean;
  text?: string;
}
const SubmitBtn: React.FC<IProps> = (props) => {
  return (
    <>
      {props.isLoading ? (
        <button className="form-submit-button loading" disabled>
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </button>
      ) : (
        <button className="form-submit-button" onClick={props.handleChange}>
          {props.text || 'Submit Request'}
        </button>
      )}
    </>
  );
};

export default SubmitBtn;
