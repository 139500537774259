import React, { useState, useEffect } from 'react';
import cx from 'classnames';

import './FulfillOrderPage.scss';
import { useGlobalError } from '../../../providers/ErrorProvider';
import { validateFulfillments, fulfillOrders } from '../../../shared/endpointAccess/fulfillments';
import ValidateFulfillmentsTab from './Tabs/ValidateFulfillmentsTab';
import LeftBar from './LeftBar/LeftBar';
import FulfillmentsTab from './Tabs/FulfillmentsTab';
import { IFulfillments, IFulfillment } from '../../../shared/endpointAccess/interfaces/fulfillmentInterfaces';

const FulfillOrderPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [fulfillments, setFulfillments] = useState<IFulfillments>([]);
  const [currentTab, setCurrentTab] = useState<string>('Validate');
  const [fulfillmentsValid, setFulfillmentsValid] = useState<boolean>(false);
  const [ignoreValidation, setIgnoreValidation] = useState<boolean>(false);
  const [fulfillmentValidationErrors, setFulfillmentValidationErrors] = useState<string[]>([]);
  const [fulfillmentResults, setFulfillmentResults] = useState<any>({});

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { setGlobalError } = useGlobalError()!;

  const clearFulfillments = (): void => {
    setFulfillments([]);
    setCurrentTab('Validate');
    setFulfillmentsValid(false);
    setFulfillmentValidationErrors([]);
    setFulfillmentResults({});
  };

  const fulfillOrdersAsync = async (): Promise<void> => {
    const fulfillmentsAttempted = { ...fulfillmentResults };
    for (let i = 0; i < fulfillments.length; i++) {
      if (fulfillments[i].orderId in fulfillmentsAttempted && fulfillmentsAttempted[fulfillments[i].orderId].isSuccess)
        continue;
      fulfillOrders([fulfillments[i]])
        .then((data) => {
          setFulfillmentResults((original: any) => {
            const temp = { ...original };
            temp[fulfillments[i].orderId] = {
              ...data[0],
              attempted: true,
            };
            return temp;
          });
        })
        .catch((error: any) => {
          setFulfillmentResults((original: any) => {
            const temp = { ...original };
            temp[fulfillments[i].orderId] = {
              isSuccess: false,
              attempted: true,
              orderId: fulfillments[i].orderId,
              errorMessage: error.message,
            };
            return temp;
          });
        });
    }
  };

  const retryFulfillment = async (fulfillment: IFulfillment): Promise<void> => {
    setFulfillmentResults((original: any) => {
      const temp = { ...original };
      delete temp[fulfillment.orderId];
      return temp;
    });
    fulfillOrdersAsync();
  };

  useEffect(() => {
    if (fulfillments.length > 0) {
      if (ignoreValidation) {
        setFulfillmentsValid(true);
        setCurrentTab('Fulfillments');
        fulfillOrdersAsync();
      } else {
        setLoading(true);
        validateFulfillments(fulfillments)
          .then((data) => {
            setLoading(false);
            if (!data.isSuccessful) setFulfillmentValidationErrors(data.errors);
            else {
              setFulfillmentsValid(true);
              setCurrentTab('Fulfillments');
              fulfillOrdersAsync();
            }
          })
          .catch((error) => {
            setGlobalError(error);
          });
      }
    }
  }, [fulfillments]);

  return (
    <div className="orderupload-container">
      <div className="order-group">
        <LeftBar
          clearFulfillments={clearFulfillments}
          setLoading={setLoading}
          setFulfillments={setFulfillments}
          setIgnoreValidation={setIgnoreValidation}
        />
      </div>
      <div className="order-carts">
        <div className={cx('fa', 'fa-shopping-cart', 'no-carts', { hidden: loading || fulfillments.length > 0 })}></div>
        <div className={cx('orders-container', { hidden: loading || fulfillments.length <= 0 })}>
          {/* Validate Fulfillments Tab */}
          <ValidateFulfillmentsTab
            currentTab={currentTab}
            fulfillmentsValid={fulfillmentsValid}
            fulfillmentValidationErrors={fulfillmentValidationErrors}
          />
          {/* Fulfillments Tab */}
          <FulfillmentsTab
            fulfillmentsValid={fulfillmentsValid}
            currentTab={currentTab}
            fulfillments={fulfillments}
            fulfillmentsAttempted={fulfillmentResults}
            retryFulfillment={retryFulfillment}
          />
        </div>
        <div className={cx('loading', { hidden: !loading })}>
          Loading <span className="period1">.</span>
          <span className="period2">.</span>
          <span className="period3">.</span>
        </div>
      </div>
    </div>
  );
};

export default FulfillOrderPage;
