import axios from 'axios';

export const addAsanaTask = async (data: any, tried?: boolean): Promise<any> => {
  return await axios({
    method: 'POST',
    url: `https://app.asana.com/api/1.0/tasks`,
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: process.env.REACT_APP_ASANA_BEARER_TOKEN as string,
    },
  })
    .then((res) => res.data)
    .catch(async (error) => {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.length > 0 &&
        error.response.data.errors[0].message &&
        error.response.data.errors[0].message.includes('Not a user in Organization')
      ) {
        if (!tried) {
          data.data.followers = ['technology@iceboxmail.com'];
          return await addAsanaTask(data, true);
        } else {
          throw new Error(`Email does not exist inside Asana.`);
        }
      } else {
        throw new Error(`Something went wrong... Error: ${error.message}`);
      }
    });
};

export const addAttachmentsToAsanaTask = async (taskID: string, uploadedFiles: File[]): Promise<boolean> => {
  let results = true;
  for (const file of uploadedFiles) {
    const fd = new FormData();
    fd.append('file', file);
    await fetch(`https://app.asana.com/api/1.0/tasks/${taskID}/attachments`, {
      method: 'POST',
      body: fd,
      headers: {
        Authorization: process.env.REACT_APP_ASANA_BEARER_TOKEN as string,
      },
    }).catch(() => {
      results = false;
    });
  }
  return results;
};
