import React from 'react';
import Field from './Field/Field';

export interface IProps {
  automationId?: string; // todo: make this not optional
  error?: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  value: number;
  placeholder?: string;
  isFull?: boolean;
  min?: number;
  max?: number;
}

const InputNumberField: React.FC<IProps> = (props) => {
  if (props.min !== undefined && props.max !== undefined) {
    return (
      <Field isFull={props.isFull} label={props.label} error={props.error}>
        <input
          className="field-input"
          placeholder={props.placeholder}
          type="number"
          onChange={props.handleChange}
          value={props.value}
          min={props.min}
          max={props.max}
        />
      </Field>
    );
  }
  return (
    <Field isFull={props.isFull} label={props.label} error={props.error}>
      <input
        className="field-input"
        placeholder={props.placeholder}
        type="number"
        onChange={props.handleChange}
        value={props.value}
      />
    </Field>
  );
};

export default InputNumberField;
