import React, { useState, useEffect } from 'react';
import cx from 'classnames';

import './OrderUploadPage.scss';
import { IFormData, defaultFormData } from './Interfaces';
import { postOrder } from '../../../shared/endpointAccess/orders';
import { useGlobalError } from '../../../providers/ErrorProvider';
import { IOrder } from '../../../shared/endpointAccess/interfaces/orderInterfaces';
import { validateProducts } from '../../../shared/endpointAccess/products';
import { IProduct } from '../../../shared/endpointAccess/interfaces/productInterfaces';
import ProductsTab from './Tabs/ProductsTab';
import SubmitTab from './Tabs/SubmitTab';
import OrderTab from './Tabs/OrderTab';
import LeftBar from './LeftBar/LeftBar';

const OrderUploadPage: React.FC = () => {
  const [formData, setFormData] = useState<IFormData>(defaultFormData);
  const [loading, setLoading] = useState<boolean>(false);
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [ordersValidatedCount, setOrdersValidatedCount] = useState<number>(0);
  const [allOrdersValidated, setAllOrdersValidated] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<string>('Products');
  const [products, setProducts] = useState<IProduct[]>([]);
  const [productsValid, setProductsValid] = useState<boolean>(false);
  const [productValidationErrors, setProductValidationErrors] = useState<string[]>([]);
  const [cartsCreated, setCartsCreated] = useState<any>({});
  const [cartCreationErrorPopup, setCartCreationErrorPopup] = useState<boolean>(false);
  const [stopCartCreationOnError, setStopCartCreationOnError] = useState<boolean>(false);
  const [cartReadyToSubmit, setCartReadyToSubmit] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { setGlobalError } = useGlobalError()!;

  const clearOrders = (): void => {
    setOrders([]);
    setOrdersValidatedCount(0);
    setAllOrdersValidated(false);
    setCurrentTab('Products');
    setProducts([]);
    setProductsValid(false);
    setProductValidationErrors([]);
    setCartsCreated({});
    setCartCreationErrorPopup(false);
    setStopCartCreationOnError(false);
    setCartReadyToSubmit(false);
  };

  useEffect(() => {
    if (ordersValidatedCount === orders.length && orders.length > 0) {
      setAllOrdersValidated(true);
    } else {
      setAllOrdersValidated(false);
    }
  }, [ordersValidatedCount]);

  const retryOrder = async (order: IOrder): Promise<void> => {
    let cartAdded = false;
    setCartsCreated((original: any) => {
      const temp = { ...original };
      delete temp[order.OrderInformation.Title];
      return temp;
    });
    postOrder({ order })
      .then((data) => {
        cartAdded = true;
        setCartsCreated((original: any) => {
          const temp = { ...original };
          temp[order.OrderInformation.Title] = {
            attempted: true,
            isCreated: true,
            compassCartId: data.compassCartId,
            compassContactId: data.compassContactId,
            shippingAccount: order.ShippingInformation.ShippingAccount,
          };
          return temp;
        });
        setCartReadyToSubmit(true);
      })
      .catch((error) => {
        setCartsCreated((original: any) => {
          const temp = { ...original };
          temp[order.OrderInformation.Title] = {
            attempted: true,
            error: error.message,
          };
          return temp;
        });
      });
    if (cartAdded) setCartReadyToSubmit(true);
  };

  const postOrdersAsync = async (): Promise<void> => {
    const cartCreatedData = { ...cartsCreated };
    let cartAdded = false;
    for (let i = 0; i < orders.length; i++) {
      if (
        orders[i].OrderInformation.Title in cartCreatedData &&
        cartCreatedData[orders[i].OrderInformation.Title].attempted
      )
        continue;
      postOrder({ order: orders[i] })
        .then((data) => {
          cartAdded = true;
          setCartsCreated((original: any) => {
            const temp = { ...original };
            temp[orders[i].OrderInformation.Title] = {
              attempted: true,
              isCreated: true,
              compassCartId: data.compassCartId,
              compassContactId: data.compassContactId,
              shippingAccount: orders[i].ShippingInformation.ShippingAccount,
            };
            return temp;
          });
          setCartReadyToSubmit(true);
        })
        .catch(() => {
          // try again if fail
          postOrder({ order: orders[i] })
            .then((data) => {
              cartAdded = true;
              setCartsCreated((original: any) => {
                const temp = { ...original };
                temp[orders[i].OrderInformation.Title] = {
                  attempted: true,
                  isCreated: true,
                  compassCartId: data.compassCartId,
                  compassContactId: data.compassContactId,
                  shippingAccount: orders[i].ShippingInformation.ShippingAccount,
                };
                return temp;
              });
              setCartReadyToSubmit(true);
            })
            .catch(async () => {
              // try one last time if it fails again
              await new Promise((resolve) => setTimeout(resolve, 1000));
              postOrder({ order: orders[i] })
                .then((data) => {
                  cartAdded = true;
                  setCartsCreated((original: any) => {
                    const temp = { ...original };
                    temp[orders[i].OrderInformation.Title] = {
                      attempted: true,
                      isCreated: true,
                      compassCartId: data.compassCartId,
                      compassContactId: data.compassContactId,
                      shippingAccount: orders[i].ShippingInformation.ShippingAccount,
                    };
                    return temp;
                  });
                  setCartReadyToSubmit(true);
                })
                .catch((error) => {
                  setCartsCreated((original: any) => {
                    const temp = { ...original };
                    temp[orders[i].OrderInformation.Title] = {
                      attempted: true,
                      error: error.message,
                    };
                    return temp;
                  });
                  if (stopCartCreationOnError && i !== orders.length - 1) {
                    setCartCreationErrorPopup(true);
                  }
                });
            });
        });
    }
    if (cartAdded) setCartReadyToSubmit(true);
  };

  useEffect(() => {
    if (allOrdersValidated) {
      postOrdersAsync();
    }
  }, [allOrdersValidated]);

  useEffect(() => {
    if (orders.length > 0) {
      const productsInfoObject = {} as any;
      orders.forEach((order) => {
        order.Items.forEach((item) => {
          if (!(item.SKU.toLowerCase() in productsInfoObject))
            productsInfoObject[item.SKU.toLowerCase()] = item.Quantity;
          else productsInfoObject[item.SKU.toLowerCase()] += item.Quantity;
        });
      });
      const productsInfo = Object.keys(productsInfoObject).map((productSku) => {
        return {
          productSKU: productSku,
          quantity: productsInfoObject[productSku],
        };
      });

      setProducts(productsInfo);

      validateProducts(formData.site, productsInfo, formData.checkAgainstTradeport)
        .then((data) => {
          if (!data.isValid) setProductValidationErrors(data.messages);
          else setProductsValid(true);
        })
        .catch((error) => {
          setGlobalError(error);
        });

      // recheck to see if we can start creating carts
      if (ordersValidatedCount === orders.length && orders.length > 0) {
        setAllOrdersValidated(true);
      } else {
        setAllOrdersValidated(false);
      }
    }
  }, [orders]);

  useEffect(() => {
    if (orders.length > 0) postOrdersAsync();
  }, [stopCartCreationOnError]);

  return (
    <div className="orderupload-container">
      <div className="order-group">
        <LeftBar clearOrders={clearOrders} setLoading={setLoading} setOrders={setOrders} setFormData={setFormData} />
      </div>
      <div className="order-carts">
        <div className={cx('fa', 'fa-shopping-cart', 'no-carts', { hidden: loading || orders.length > 0 })}></div>
        <div className={cx('orders-container', { hidden: orders.length <= 0 })}>
          <div className="tab-bar">
            <div
              className={cx('tab', { 'tab-selected': currentTab === 'Products' })}
              onClick={(): void => {
                setCurrentTab('Products');
              }}
            >
              Products
            </div>
            {productsValid && (
              <div
                className={cx('tab', { 'tab-selected': currentTab === 'Orders' })}
                onClick={(): void => {
                  setCurrentTab('Orders');
                }}
              >
                Orders
              </div>
            )}
            {cartReadyToSubmit && (
              <div
                className={cx('tab', { 'tab-selected': currentTab === 'Submit' })}
                onClick={(): void => {
                  setCurrentTab('Submit');
                }}
              >
                Submit Carts
              </div>
            )}
          </div>
          {/* Products Tab */}
          <ProductsTab
            currentTab={currentTab}
            products={products}
            productsValid={productsValid}
            productValidationErrors={productValidationErrors}
          />
          {/* Order Tab */}
          <OrderTab
            productsValid={productsValid}
            currentTab={currentTab}
            orders={orders}
            setOrdersValidatedCount={(): void => setOrdersValidatedCount((oldCount) => oldCount + 1)}
            setOrders={(orders: IOrder[]): void => {
              setOrders(orders);
            }}
            cartsCreated={cartsCreated}
            retryOrder={retryOrder}
            removeCart={(orderTitle: string): void => {
              setCartsCreated((original: any) => {
                const newCartsCreated = { ...original };
                delete newCartsCreated[orderTitle];
                return newCartsCreated;
              });
            }}
          />
          {/* Submit Tab */}
          <SubmitTab currentTab={currentTab} cartsCreated={cartsCreated} orders={orders} setLoading={setLoading} />
        </div>
        <div className={cx('loading', { hidden: !loading })}>
          Loading <span className="period1">.</span>
          <span className="period2">.</span>
          <span className="period3">.</span>
        </div>
        <div className={cx('cartCreationError', { hidden: !cartCreationErrorPopup })}>
          <div className="cartCreationErrorDialogBox">
            <p className="cartCreationErrorMessage">
              There is an issue with one of the carts that tried to get created. Continue to create carts?
            </p>
            <button className="cartCreationErrorButtons" onClick={(): void => setCartCreationErrorPopup(false)}>
              No
            </button>
            <button
              className="cartCreationErrorButtons"
              onClick={(): void => {
                setCartCreationErrorPopup(false);
                if (stopCartCreationOnError) postOrdersAsync();
                else setStopCartCreationOnError(true);
              }}
            >
              Yes (Stop at next failure)
            </button>
            <button
              className="cartCreationErrorButtons"
              onClick={(): void => {
                setCartCreationErrorPopup(false);
                if (!stopCartCreationOnError) postOrdersAsync();
                else setStopCartCreationOnError(false);
              }}
            >
              Yes (Ignore future failures)
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderUploadPage;
