import { webToolsServerURL } from '../utilities';
import { IFulfillments, IFulfillmentsValid, IFulfillOrders } from './interfaces/fulfillmentInterfaces';
import jwt from 'jsonwebtoken';
import axios from 'axios';

export const parseFulfillments = (file: any): Promise<IFulfillments> => {
  const fd = new FormData();
  fd.append('file', file);
  return axios(`${webToolsServerURL}/api/compass/fulfillments/parse`, {
    method: 'POST',
    data: fd,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwt.sign({}, process.env.REACT_APP_SERVER_SECRET as string, { expiresIn: '5m' })}`,
    },
  })
    .then(async (res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const validateFulfillments = (fulfillments: IFulfillments): Promise<IFulfillmentsValid> => {
  return axios(`${webToolsServerURL}/api/compass/fulfillments/validate`, {
    method: 'POST',
    data: JSON.stringify(fulfillments),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwt.sign({}, process.env.REACT_APP_SERVER_SECRET as string, { expiresIn: '5m' })}`,
    },
  })
    .then(async (res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export const fulfillOrders = (fulfillments: IFulfillments): Promise<IFulfillOrders> => {
  return axios(`${webToolsServerURL}/api/compass/fulfillments`, {
    method: 'POST',
    data: JSON.stringify(fulfillments),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwt.sign({}, process.env.REACT_APP_SERVER_SECRET as string, { expiresIn: '5m' })}`,
    },
  })
    .then(async (res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};
