import React from 'react';
import Field from '../Field/Field';
import './ToggleSwitch.scss';

interface IProps {
  label: string;
  error?: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: boolean;
}

const ToggleSwitch: React.FC<IProps> = (props) => {
  return (
    <Field className="switch-container" isFull label={props.label} error={props.error}>
      <label className="switch">
        <input type="checkbox" onChange={props.handleChange} value={props.value.toString()} checked={!!props.value} />
        <span className="slider round"></span>
      </label>
    </Field>
  );
};

export default ToggleSwitch;
