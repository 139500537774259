import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import './SubmitTab.scss';

import { submitCart, updateShippingAccount } from '../../../../shared/endpointAccess/carts';
import { IOrder } from '../../../../shared/endpointAccess/interfaces/orderInterfaces';

interface Props {
  currentTab: string;
  cartsCreated: any;
  orders: IOrder[];
  setLoading: (isLoading: boolean) => void;
}

const SubmitTab: React.FC<Props> = ({
  currentTab = '',
  cartsCreated = {},
  orders = [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setLoading = (): void => {},
}) => {
  const [showSubmitButton, setShowSubmitButton] = useState<boolean>(orders.length === Object.keys(cartsCreated).length);
  const [cartsSubmitted, setCartsSubmitted] = useState<Set<string>>(new Set());
  const [cartsSubmittedErrors, setCartsSubmittedErrors] = useState<Set<string>>(new Set());
  const [shippingAccountSet, setShippingAccountSet] = useState<Set<string>>(new Set());
  const [shippingAccountErrors, setShippingAccountErrors] = useState<Set<string>>(new Set());

  const cartInfo = Object.keys(cartsCreated)
    .map((x) => ({
      title: x,
      cartId: cartsCreated[x].compassCartId,
      submitted: cartsSubmitted.has(cartsCreated[x].compassCartId),
    }))
    .filter((cart) => !!cart.cartId);

  useEffect(() => {
    if (cartsSubmitted.size == Object.keys(cartsCreated).length) {
      setShowSubmitButton(false);
    } else {
      setShowSubmitButton(true);
    }
  }, [cartsSubmitted]);

  useEffect(() => {
    if (cartsSubmitted.size == Object.keys(cartsCreated).length) {
      setShowSubmitButton(false);
    } else if (orders.length !== Object.keys(cartsCreated).length) {
      setShowSubmitButton(false);
    } else {
      setShowSubmitButton(true);
    }
  }, [cartsCreated]);

  const submitCarts = async (): Promise<void> => {
    setShowSubmitButton(false);
    setLoading(true);
    const oldCartTitles = Object.keys(cartsCreated);
    const cartShippingAccounts: { [key: string]: any } = {};
    const newSubmittedCartsSet = new Set<string>(cartsSubmitted);
    const newSubmittedCartsErrorsSet = new Set<string>();

    for (let i = 0; i < oldCartTitles.length; i++) {
      if (
        !!cartsCreated[oldCartTitles[i]]?.isCreated &&
        !cartsSubmitted.has(cartsCreated[oldCartTitles[i]].compassCartId)
      ) {
        cartShippingAccounts[cartsCreated[oldCartTitles[i]].compassCartId as string] =
          cartsCreated[oldCartTitles[i]].shippingAccount;
        await submitCart(cartsCreated[oldCartTitles[i]].compassCartId)
          .then(() => {
            newSubmittedCartsSet.add(cartsCreated[oldCartTitles[i]].compassCartId);
          })
          .catch(async () => {
            await submitCart(cartsCreated[oldCartTitles[i]].compassCartId)
              .then(() => {
                newSubmittedCartsSet.add(cartsCreated[oldCartTitles[i]].compassCartId);
              })
              .catch(() => {
                newSubmittedCartsErrorsSet.add(cartsCreated[oldCartTitles[i]].compassCartId);
                setShowSubmitButton(true);
              });
          });
      }
    }

    setCartsSubmitted(newSubmittedCartsSet);
    setCartsSubmittedErrors(newSubmittedCartsErrorsSet);

    const newCartsArray = Array.from(newSubmittedCartsSet);
    for (let i = 0; i < newCartsArray.length; i++) {
      await updateShippingAccount(newCartsArray[i], cartShippingAccounts[newCartsArray[i]])
        .then(() => {
          setShippingAccountSet((set) => {
            set.add(newCartsArray[i]);
            return set;
          });
        })
        .catch(() => {
          setShippingAccountErrors((set) => {
            set.add(newCartsArray[i]);
            return set;
          });
        });
    }

    setLoading(false);
  };

  return (
    <>
      {currentTab === 'Submit' && (
        <>
          {showSubmitButton && (
            <button
              className="submit-carts-button"
              onClick={(): void => {
                submitCarts();
              }}
            >
              Submit Orders
            </button>
          )}
          <div className="submit-header-bar">
            <div className="submit-header-title">
              <p>Title</p>
            </div>
            <div className="submit-header-cart">
              <p>Cart #</p>
            </div>
            <div className="submit-header-status">
              <p>Submit Status?</p>
            </div>
            <div className="submit-header-shipping-account">
              <p>Shipping Account Changed?</p>
            </div>
          </div>
          <div
            className={cx({
              'all-submit-height-setup': showSubmitButton,
              'all-submit-height-setup-no-submit': !showSubmitButton,
            })}
          >
            <div className="all-submit">
              {cartInfo.map((cart) => (
                <div className="submit-container" key={cart.title}>
                  <div className="submit-title">
                    <p>{cart.title}</p>
                  </div>
                  <div className="submit-cart">
                    <p>{cart.cartId}</p>
                  </div>
                  <div
                    className={cx('submit-status', {
                      'error-color': cartsSubmittedErrors.has(cart.cartId),
                      'success-color': cartsSubmitted.has(cart.cartId),
                    })}
                  >
                    {cartsSubmitted.has(cart.cartId) && <p>Cart was submitted</p>}
                    {cartsSubmittedErrors.has(cart.cartId) && <p>Error when tried to submit cart</p>}
                    {!cartsSubmitted.has(cart.cartId) && !cartsSubmittedErrors.has(cart.cartId) && (
                      <p>Waiting to be submitted</p>
                    )}
                  </div>
                  <div
                    className={cx('submit-shipping-account', {
                      'error-color': shippingAccountErrors.has(cart.cartId),
                      'success-color': shippingAccountSet.has(cart.cartId),
                    })}
                  >
                    {shippingAccountSet.has(cart.cartId) && <p>Successfully changed shipping account</p>}
                    {shippingAccountErrors.has(cart.cartId) && <p>Error when tried to change shipping account</p>}
                    {!shippingAccountSet.has(cart.cartId) && !shippingAccountErrors.has(cart.cartId) && (
                      <p>Waiting to adjust shipping account</p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SubmitTab;
