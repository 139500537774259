export interface IFormData {
  name: string;
  email: string;
  phone: string;
  account: string;
  deliveryMethod: string;
  title: string;
  requestDate: string;
  websiteUpdate: string;
  orderForm: string;
  flyerText: string;
  product: string;
  flyerProducts: IFlyerProduct[];
  comments: string;
  productName: string;
  productColors: string;
  minimumQuantity: string;
  productSku: string;
  pricing: string;
  additionalPricing: string;
  size: string;
}

export const defaultFormData: IFormData = {
  name: '',
  email: '',
  phone: '',
  account: '',
  deliveryMethod: '',
  title: '',
  requestDate: '',
  websiteUpdate: '',
  orderForm: '',
  flyerText: '',
  product: '',
  flyerProducts: [],
  comments: '',
  productName: '',
  productColors: '',
  minimumQuantity: '',
  productSku: '',
  pricing: '',
  additionalPricing: '',
  size: '',
};

export interface IFormErrors {
  name: string;
  email: string;
  phone: string;
  account: string;
  deliveryMethod: string;
  title: string;
  requestDate: string;
  websiteUpdate: string;
  orderForm: string;
  flyerText: string;
  flyerProducts: IFlyerProduct[];
  product: string;
  fileDrop: string;
  comments: string;
}

export const defaultFormErrors: IFormErrors = {
  name: '',
  email: '',
  phone: '',
  account: '',
  deliveryMethod: '',
  title: '',
  requestDate: '',
  websiteUpdate: '',
  orderForm: '',
  flyerText: '',
  product: '',
  flyerProducts: [
    {
      productName: '',
      productColors: '',
      minimumQuantity: '',
      productSku: '',
      pricing: '',
      additionalPricing: '',
      size: '',
    },
  ],
  fileDrop: '',
  comments: '',
};

export const fakeFormData: IFormData = {
  name: 'Anthony Stark',
  email: 'sierra@iceboxmail.com',
  phone: '1800-888-9087',
  account: 'S.H.I.E.D',
  deliveryMethod: 'PDF',
  title: 'this is a test',
  requestDate: '2020-09-28',
  websiteUpdate: 'No',
  orderForm: 'instock',
  flyerText: 'Am I the only one who did the reading?',
  product: 'magnetic fields',
  flyerProducts: [],
  comments: 'this is a comment',
  productName: 'name of the product',
  productColors: 'blue, green',
  minimumQuantity: '100',
  productSku: 'BLUESKU',
  pricing: '100.00',
  additionalPricing: 'more money',
  size: 'M, S, L',
};

export interface IFlyerProduct {
  productName: string;
  productColors: string;
  minimumQuantity: string;
  productSku: string;
  pricing: string;
  additionalPricing: string;
  size: string;
}
