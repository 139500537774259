import { webToolsServerURL } from '../../shared/utilities';
import jwt from 'jsonwebtoken';

export const submitCart = (cartId: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    fetch(`${webToolsServerURL}/api/compass/carts/${cartId}/submit`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt.sign({}, process.env.REACT_APP_SERVER_SECRET as string, { expiresIn: '5m' })}`,
      },
    })
      .then((res) => {
        if (res.status === 204) {
          resolve();
          return;
        }
        if (!res.ok) {
          return res.text().then((data) => {
            throw new Error(data);
          });
        }
        resolve();
      })
      .catch((error) => {
        reject(`Something went wrong... Error: ${error.message}`);
      });
  });
};

export const updateShippingAccount = (cartId: string, shippingAccount: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    fetch(`${webToolsServerURL}/api/compass/carts/${cartId}/shippingAccount`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt.sign({}, process.env.REACT_APP_SERVER_SECRET as string, { expiresIn: '5m' })}`,
      },
      body: JSON.stringify({ shippingAccount: shippingAccount }),
    })
      .then((res) => {
        if (res.status === 204) {
          resolve();
          return;
        }
        if (!res.ok) {
          return res.text().then((data) => {
            throw new Error(data);
          });
        }
        resolve();
      })
      .catch((error) => {
        reject(`Something went wrong... Error: ${error.message}`);
      });
  });
};

export const deleteCart = (cartId: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    fetch(`${webToolsServerURL}/api/compass/carts/${cartId}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt.sign({}, process.env.REACT_APP_SERVER_SECRET as string, { expiresIn: '5m' })}`,
      },
    })
      .then((res) => {
        if (res.status === 204) {
          resolve();
          return;
        }
        if (!res.ok) {
          return res.text().then((data) => {
            throw new Error(data);
          });
        }
        resolve();
      })
      .catch((error) => {
        reject(`Something went wrong... Error: ${error.message}`);
      });
  });
};
