import React, { useState, useEffect } from 'react';
import { nameObject } from './nameObject';
import { useAuthState } from '../../providers/AuthProvider';

import './penguin.css';

const Penguin: React.FC = () => {
  const [userName, setUserName] = useState('');
  const [penguinFirstName, setPenguinFirstName] = useState('');
  const [penguinLastName, setPenguinLastName] = useState('');
  const [penguinMiddleName, setPenguinMiddleName] = useState('');
  const [showErrors, setShowErrors] = useState(true);
  const [generatedName, setGeneratedName] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { authState } = useAuthState()!;

  useEffect(() => {
    if (authState && authState.user) setUserName(authState.user.displayName);
  }, [authState.user]);

  const handleOnChange = (e: any): void => {
    setUserName(e.target.value);
  };

  const handleSubmit = (): void => {
    const str = userName.toLowerCase();
    const initials = str.split(' ').reduce((response, word) => (response += word.slice(0, 1)), '');
    const secondLetter = initials.charAt(1);
    const hasMiddleNames = initials.length >= 3;
    setShowErrors(true);
    setPenguinMiddleName('');
    if (/[^a-zA-Z\-]/.test(str.replace(/\s/g, '')) || initials.length <= 1) {
      setShowErrors(false);
      return;
    }
    setPenguinFirstName(nameObject.penguinNameObject[initials[0]]);

    if (hasMiddleNames) {
      setPenguinMiddleName(/^[a-n]/.test(secondLetter) ? 'Mc' : 'Von');
    }

    setPenguinLastName(nameObject.penguinLastNameObject[initials[initials.length - 1]]);
    setGeneratedName(true);
  };

  return (
    <div className="penguinName">
      <div className="child">
        <div>
          <div className="title">{generatedName ? 'Your Penguin Name Is' : 'Get Your Penguin Name'}</div>
          {!generatedName && (
            <input
              className="penguinNameInput"
              value={userName}
              onChange={handleOnChange}
              type="text"
              placeholder="First Last"
            />
          )}
          {generatedName && (
            <div className="penguinNameResult">
              {penguinFirstName} {penguinMiddleName} {penguinLastName}
            </div>
          )}
          <div className="btnContainer">
            {!generatedName && (
              <div className="submitBtn" onClick={handleSubmit}>
                Submit
              </div>
            )}
            {generatedName && (
              <div
                className="submitBtn"
                onClick={(): void => {
                  setGeneratedName(false);
                }}
              >
                Try Another Name
              </div>
            )}
          </div>
        </div>
        {!showErrors && (
          <div className="error">
            Please enter a name with no special characters or numbers and at least enter a first and last name
          </div>
        )}
      </div>
    </div>
  );
};

export default Penguin;
