/* eslint-disable @typescript-eslint/explicit-function-return-type */
import * as React from 'react';

const defaultError = '';
type IError = {
  globalError: string;
  setGlobalError: (value: string) => void;
};
const ErrorContext = React.createContext<IError | undefined>(undefined);

type Props = {
  children: React.ReactNode;
};

export const ErrorProvider = ({ children }: Props) => {
  const [globalError, setGlobalError] = React.useState(defaultError);

  return <ErrorContext.Provider value={{ globalError, setGlobalError }}>{children}</ErrorContext.Provider>;
};

export const useGlobalError = () => React.useContext(ErrorContext);
