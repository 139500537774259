import React, { useState, useCallback, useEffect } from 'react';
import { defaultFormData, defaultFormErrors, IFormData, IFormErrors, fakeFormData } from './Interfaces';
import InputTextField from '../../../components/form/InputTextField';
import FileDrop from '../../../components/form/FileDrop/FileDrop';
import DropdownField from '../../../components/form/DropdownField';
import InputTextAreaField from '../../../components/form/InputTextAreaField/InputTextAreaField';
import { serverURL, totalEmailUploadSizeLimit, bytesToMB } from '../../../shared/utilities';
import { Redirect } from 'react-router-dom';
import { useGlobalError } from '../../../providers/ErrorProvider';
import '../../../components/form/form.scss';

const WebArtPage: React.FC = () => {
  const [formData, setFormData] = useState<IFormData>(defaultFormData);
  const [formErrors, setFormErrors] = useState<IFormErrors>(defaultFormErrors);
  const [uploadedFiles, setUploadedFiles] = useState<Array<File>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [redirect, setRedirect] = useState<string>('');

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { setGlobalError } = useGlobalError()!;

  const handleUserKeyPress = useCallback((event: KeyboardEvent) => {
    const { key, shiftKey, ctrlKey } = event;
    if (key === 'T' && shiftKey && ctrlKey) {
      setFormData(fakeFormData);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);

    return (): void => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setFormData({ ...formData, name: e.target.value });
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setFormData({ ...formData, email: e.target.value });
  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setFormData({ ...formData, phone: e.target.value });
  const handleSubjectChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setFormData({ ...formData, subject: e.target.value });
  const handleSkuChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setFormData({ ...formData, sku: e.target.value });
  const handleWebChange = (e: React.ChangeEvent<HTMLSelectElement>): void =>
    setFormData({ ...formData, web: e.target.value });
  const handleCommentsChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void =>
    setFormData({ ...formData, comments: e.target.value });

  const setUploadedFilesToState = (filesArray: File[]): void => {
    let totalFileSize = 0;
    const fileNamesFromState = uploadedFiles.map((file) => {
      totalFileSize += file.size;
      return file.name;
    });
    const filesToBeAdded = [...filesArray].filter((file) => {
      if (fileNamesFromState.includes(file.name)) return false;
      totalFileSize += file.size;
      return true;
    });
    if (totalFileSize > totalEmailUploadSizeLimit) {
      return setFormErrors({
        ...formErrors,
        fileDrop: `Attachments(${bytesToMB(totalFileSize)}) will exceeded maximum allowed size of ${bytesToMB(
          totalEmailUploadSizeLimit,
        )}.`,
      });
    }
    setFormErrors({ ...formErrors, fileDrop: '' });
    setUploadedFiles([...uploadedFiles, ...filesToBeAdded]);
  };

  const handleFileRemove = (fileToRemove: string): void => {
    const newUploadedFiles = uploadedFiles.filter((file) => {
      return file.name !== fileToRemove;
    });
    setUploadedFiles([...newUploadedFiles]);
  };

  const postToServer = async (): Promise<void> => {
    const fd = new FormData();
    uploadedFiles.forEach((file) => {
      fd.append('file', file);
    });
    const data = JSON.stringify(formData);
    fd.append('data', data);

    await fetch(`${serverURL}/api/requests/webart`, {
      method: 'POST',
      body: fd,
    })
      .then((res) => {
        if (!res.ok) throw new Error(res.statusText);
        setRedirect('/requests/submitted');
      })
      .catch((error) => {
        setGlobalError(`Something went wrong... Error: ${error.message}`);
      });
  };

  const handleSubmit = async (): Promise<void> => {
    setIsLoading(true);
    const newFormErrors = {} as IFormErrors;
    newFormErrors.email =
      // eslint-disable-next-line
      !formData.email || !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)
        ? 'Please enter a valid email'
        : '';

    newFormErrors.name = !formData.name ? 'Please enter your name' : '';
    newFormErrors.phone = !formData.phone ? 'Please enter your phone number' : '';
    newFormErrors.subject = !formData.subject ? 'Please enter a subject' : '';
    newFormErrors.email = !formData.email ? 'Please enter a email' : '';
    newFormErrors.web = !formData.web ? 'Please enter if this is a web image' : '';

    setFormErrors(newFormErrors);
    if (!Object.values(newFormErrors).find((error) => error !== '')) {
      await postToServer();
    } else {
      setGlobalError('Please fill out entire form.');
      window.scrollTo(0, 0);
    }
    setIsLoading(false);
  };

  return (
    <div className="form-container">
      {redirect && <Redirect to={redirect} />}
      <div className="form-title">Web Image Request Form</div>
      <div className="field-container">
        <div className="form-subtitle">Contact Information</div>
        <InputTextField label="Name:" value={formData.name} handleChange={handleNameChange} error={formErrors.name} />
        <InputTextField
          label="Email:"
          value={formData.email}
          handleChange={handleEmailChange}
          error={formErrors.email}
        />
        <InputTextField
          handleChange={handlePhoneChange}
          label="Phone:"
          value={formData.phone}
          error={formErrors.phone}
        />
        <InputTextField
          handleChange={handleSubjectChange}
          label="Subject:"
          value={formData.subject}
          error={formErrors.subject}
        />
        <InputTextField handleChange={handleSkuChange} label="sku:" value={formData.sku} />
        <DropdownField
          label="Will product(s) be on the web?"
          value={formData.web}
          handleChange={handleWebChange}
          placeholder="Web Image?"
          options={['Yes', 'No']}
          error={formErrors.web}
        />
        <InputTextAreaField
          label="Additional Comments? / Notes"
          value={formData.comments}
          handleChange={handleCommentsChange}
          error={formErrors.comments}
          isFull
        />
        <FileDrop
          handleRemoveListItem={handleFileRemove}
          uploadedFiles={uploadedFiles.map((file) => file.name)}
          dropzoneOptions={{ onDrop: (acceptedFiles: File[]): void => setUploadedFilesToState(acceptedFiles) }}
          error={formErrors.fileDrop}
        />
        {isLoading ? (
          <button className="form-submit-button loading" disabled>
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </button>
        ) : (
          <button className="form-submit-button" onClick={handleSubmit}>
            Submit Request
          </button>
        )}
      </div>
    </div>
  );
};

export default WebArtPage;
