export const formData = [
  {
    type: 'subTitle',
    label: '',
  },
  {
    id: 'name',
    label: 'Name:',
    type: 'textField',
    fakeData: 'John Doe',
    errorMessage: 'Please enter a name',
  },
  {
    id: 'email',
    label: 'Email:',
    type: 'textField',
    fakeData: 'technology@iceboxmail.com',
    errorMessage: 'Please enter a valid email',
    errorValidation: (email: string): boolean => !email || !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email),
  },
  {
    id: 'subject',
    label: 'Subject:',
    type: 'textField',
    fakeData: 'Test Subject',
    errorMessage: 'Please enter a subject',
  },
  {
    id: 'distribution',
    label: 'Distribution:',
    type: 'textField',
    fakeData: 'Test Distribution',
    errorMessage: 'Please enter a distribution',
    errorValidation: (): boolean => false,
    isFull: true,
  },
  {
    id: 'priority',
    label: 'How urgent is this request?',
    type: 'dropdownField',
    fakeData: 'High',
    placeholder: 'Priority',
    options: ['Low', 'Med', 'High', 'Urgent'],
    errorMessage: 'Please select a priority',
  },
  {
    id: 'requestType',
    label: 'What type of request is this?',
    type: 'dropdownField',
    fakeData: 'Bulk Project (High Volume)',
    placeholder: 'Request Type',
    options: [
      'Bulk Project (High Volume)',
      'Special Project Management',
      'International Stock Order',
      'Gift Card/Cost Center Creation',
      'Reporting Delivery',
      'New Customer Set Up',
      'Program Oversight',
      'SKU Creation Assistance',
      'Post Invoice - Order Adjustment',
      'Wire Payment Coordination',
    ],
    errorMessage: 'Please select a request type',
  },
  {
    id: 'cadence',
    label: 'Cadence Rhythm?',
    type: 'dropdownField',
    fakeData: 'None',
    placeholder: 'Cadence',
    options: ['None', 'Monthly', 'Daily', 'Weekly', 'Bi Weekly', 'Quarterly'],
    errorMessage: 'Please select a candence',
  },
  {
    id: 'dueDate',
    label: '(First) Due Date:',
    type: 'dateField',
    fakeData: '2100-01-01',
    errorMessage: 'Please enter a due date',
  },
  {
    id: 'comments',
    label: 'Description:',
    type: 'textArea',
    fakeData: 'This is test data.',
    errorMessage: 'Please tell us about the issue',
    isFull: true,
  },
  {
    type: 'fileDrop',
  },
];

export const extraFields = {
  priority: {
    asanaId: '937527389619138',
    getIdFromValue: (value: string): string => {
      const priorities = {
        low: '937527389619139',
        med: '937527389619140',
        high: '937527389619141',
        urgent: '1108483653588663',
      } as { [field: string]: string };
      return priorities[value.toLowerCase()];
    },
  },
  email: {
    asanaId: '937447804889185',
  },
  requestType: {
    asanaId: '1201252455221843',
    getIdFromValue: (value: string): string => {
      const requests = {
        bulkprojecthighvolume: '1205696239507687',
        specialprojectmanagement: '1205696239507688',
        internationalstockorder: '1205696239507689',
        giftcardcostcentercreation: '1205696239507690',
        reportingdelivery: '1201252455221846',
        newcustomersetup: '1201252455221848',
        programoversight: '1201252455221852',
        skucreationassistance: '1205696239507691',
        postinvoiceorderadjustment: '1205696239507692',
        wirepaymentcoordination: '1205696239507693',
      } as { [field: string]: string };
      return requests[value.toLowerCase().replace(/\s/g, '').replace(/\//g, '').replace(/-/g, '').replace(/\(/g, '').replace(/\)/g, '')];
    },
  },
  cadence: {
    asanaId: '1151405041212666',
    getIdFromValue: (value: string): string => {
      const requests = {
        none: '1151405041212667',
        monthly: '1151405041212668',
        daily: '1151405041212669',
        weekly: '1151405041212670',
        quarterly: '1151405041212671',
        biweekly: '1187310722695387',
      } as { [field: string]: string };
      return requests[value.toLowerCase().replace(/\s/g, '')];
    },
  },
  distribution: {
    asanaId: '1150340775824858',
  },
};
