import * as Msal from 'msal';
import { getUserDetails, getUserMemberGroups } from './GraphService';
import { IAuth } from '../providers/AuthProvider';
import { URL } from '../shared/utilities';

const config = {
  appId: process.env.REACT_APP_MSAD_APPID as string,
  redirectUri: window.location.origin,
  scopes: ['User.Read'],
  authority: process.env.REACT_APP_MSAD_TENANTID as string,
};

export interface IAuthService {
  login: () => Promise<IAuth>;
  logout: () => void;
  getAccessToken: (scopes: string[]) => Promise<string>;
  getAccount: () => Msal.Account;
}

export class MSAuthService implements IAuthService {
  private userAgentApplication: Msal.UserAgentApplication;
  constructor() {
    this.userAgentApplication = new Msal.UserAgentApplication({
      auth: {
        clientId: config.appId,
        redirectUri: config.redirectUri,
        authority: `https://login.microsoftonline.com/${config.authority}`,
        postLogoutRedirectUri: URL,
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true,
      },
    });
  }

  login = async (): Promise<IAuth> => {
    try {
      // Login via popup
      await this.userAgentApplication.loginPopup({
        scopes: config.scopes,
        prompt: 'select_account',
      });
      // After login, get the user's profile
      return await this.getUserProfile();
    } catch (err) {
      return {
        isAuthenticated: false,
        user: {},
        error: {},
      };
    }
  };

  logout = (): void => {
    this.userAgentApplication.logout();
  };

  getAccessToken = async (scopes: string[]): Promise<string> => {
    try {
      // Get the access token silently
      // If the cache contains a non-expired token, this function
      // will just return the cached token. Otherwise, it will
      // make a request to the Azure OAuth endpoint to get a token
      const silentResult = await this.userAgentApplication.acquireTokenSilent({
        scopes: scopes,
      });

      return silentResult.accessToken;
    } catch (err) {
      // If a silent request fails, it may be because the user needs
      // to login or grant consent to one or more of the requested scopes
      if (this.isInteractionRequired(err)) {
        const interactiveResult = await this.userAgentApplication.acquireTokenPopup({
          scopes,
        });

        return interactiveResult.accessToken;
      } else {
        throw err;
      }
    }
  };

  getAccount = (): Msal.Account => {
    return this.userAgentApplication.getAccount();
  };

  getUserProfile = async (): Promise<IAuth> => {
    try {
      const accessToken = await this.getAccessToken(config.scopes);

      if (accessToken) {
        // Get the user's profile from Graph
        const user = await getUserDetails(accessToken);
        const userMemberGroups = await getUserMemberGroups(accessToken);

        return {
          isAuthenticated: true,
          user: {
            id: user.id,
            displayName: user.displayName,
            email: user.mail || user.userPrincipalName,
            groups: new Set(userMemberGroups.value),
          },
          error: null,
        };
      }
      return {
        isAuthenticated: false,
        error: {},
        user: {},
      };
    } catch (err) {
      return {
        isAuthenticated: false,
        error: this.normalizeError(err),
        user: {},
      };
    }
  };

  normalizeError = (error: string | Error): any => {
    let normalizedError = {};
    if (typeof error === 'string') {
      const errParts = error.split('|');
      normalizedError = errParts.length > 1 ? { message: errParts[1], debug: errParts[0] } : { message: error };
    } else {
      normalizedError = {
        message: error.message,
        debug: JSON.stringify(error),
      };
    }
    return normalizedError;
  };

  isInteractionRequired = (error: any): boolean => {
    if (!error.message || error.message.length <= 0) {
      return false;
    }

    return (
      error.errorCode.indexOf('consent_required') > -1 ||
      error.errorCode.indexOf('interaction_required') > -1 ||
      error.errorCode.indexOf('login_required') > -1
    );
  };
}
