import { webToolsServerURL } from '../../shared/utilities';
import { IOrder, IParseOrdersParams, IValidateOrder } from './interfaces/orderInterfaces';
import jwt from 'jsonwebtoken';

export const parseOrders = ({
  siteId,
  useActualShipping,
  useResidentialShipping,
  file,
}: IParseOrdersParams): Promise<IOrder[]> => {
  return new Promise((resolve, reject) => {
    const fd = new FormData();
    fd.append('file', file);
    fd.append('siteId', siteId.toString());
    fd.append('useActualShipping', useActualShipping.toString());
    fd.append('useResidentialShipping', useResidentialShipping.toString());
    fetch(`${webToolsServerURL}/api/compass/orders/parse`, {
      method: 'POST',
      body: fd,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${jwt.sign({}, process.env.REACT_APP_SERVER_SECRET as string, { expiresIn: '5m' })}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          return res.json().then((data) => {
            throw new Error(data.error);
          });
        }
        res.json().then((data: IOrder[]) => {
          resolve(data);
        });
      })
      .catch((error) => {
        reject(`Something went wrong... Error: ${error.message}`);
      });
  });
};

export const validateOrder = ({ order }: { order: IOrder }): Promise<IValidateOrder> => {
  return new Promise((resolve, reject) => {
    fetch(`${webToolsServerURL}/api/compass/orders/validate`, {
      method: 'POST',
      body: JSON.stringify(order),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt.sign({}, process.env.REACT_APP_SERVER_SECRET as string, { expiresIn: '5m' })}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          return res.json().then((data) => {
            throw new Error(data.error);
          });
        }
        res.json().then((data: IValidateOrder) => {
          resolve(data);
        });
      })
      .catch((error) => {
        reject(`Something went wrong... Error: ${error.message}`);
      });
  });
};

export const postOrder = ({
  order,
}: {
  order: IOrder;
}): Promise<{ compassCartId: string; compassContactId: string }> => {
  return new Promise((resolve, reject) => {
    fetch(`${webToolsServerURL}/api/compass/orders/`, {
      method: 'POST',
      body: JSON.stringify(order),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt.sign({}, process.env.REACT_APP_SERVER_SECRET as string, { expiresIn: '5m' })}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          return res.text().then((data) => {
            throw new Error(data);
          });
        }
        res.json().then((data: { compassCartId: string; compassContactId: string }) => {
          resolve(data);
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const setTermIds = ({ orderIds, termId }: { orderIds: string[]; termId: string }): Promise<void> => {
  const updates = orderIds.map((x) => ({ id: x.trim(), termId: parseInt(termId.trim()) }));
  return new Promise((resolve, reject) => {
    fetch(`${webToolsServerURL}/api/compass/orders/`, {
      method: 'PATCH',
      body: JSON.stringify(updates),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt.sign({}, process.env.REACT_APP_SERVER_SECRET as string, { expiresIn: '5m' })}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          return res.text().then((data) => {
            throw new Error(data);
          });
        }
        res.json().then(() => {
          resolve();
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};
