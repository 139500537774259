import * as graph from '@microsoft/microsoft-graph-client';

function getAuthenticatedClient(accessToken: string) {
  // Initialize Graph client
  const client = graph.Client.init({
    // Use the provided access token to authenticate
    // requests
    authProvider: (done: any) => {
      done(null, accessToken);
    },
  });

  return client;
}

export async function getUserDetails(accessToken: string) {
  const client = getAuthenticatedClient(accessToken);

  const user = await client.api('/me').get();
  return user;
}

export async function getUserMemberGroups(accessToken: string) {
  const client = getAuthenticatedClient(accessToken);

  const body = {
    securityEnabledOnly: false,
  };
  const userGroups = await client.api('/me/getMemberGroups').post(body);
  return userGroups;
}
