export const ITProject: any = {
  projects: '1108454788786527',
  priority: '937527389619138',
  requesterEmail: '937447804889185',
  TypeOfRequest: '1108483653588666',
};

export const ITIssueType: any = {
  inquiry: '1108483653588667',
  hardware: '1108483653588668',
  software: '1108483653588669',
  phone: '1108651719143566',
  distribution: '1108663420657183',
};

export const ITpriorityLevel: any = {
  low: '937527389619139',
  med: '937527389619140',
  high: '937527389619141',
  urgent: '1108483653588663',
};

export const WebProject: any = {
  projects: '1108678658252300',
  priority: '937527389619138',
  requesterEmail: '937447804889185',
  TypeOfRequest: '1108966007502495',
};

export const WebIssueType: any = {
  inquiry: '1108966007502496',
  mockup: '1108966007502497',
  newsiterequest: '1108966007502498',
  currentsiteedit: '1108966007502499',
};

export const WebPriorityLevel: any = {
  low: '937527389619139',
  med: '937527389619140',
  high: '937527389619141',
  urgent: '1108483653588663',
};

export const ReportProject: any = {
  projects: '1150089822614131',
  priority: '937527389619138',
  requesterEmail: '937447804889185',
  typeOfRequest: '1150095522852160',
  distribution: '1150340775824858',
  cadence: '1151405041212666',
};

export const ReportIssueType: any = {
  recurring: '1150095522852161',
  adhoc: '1150095522852162',
  other: '1150095522852163',
};

export const ReportPriorityLevel: any = {
  low: '937527389619139',
  med: '937527389619140',
  high: '937527389619141',
  urgent: '1108483653588663',
};

export const ReportCadenceLevel: any = {
  none: '1151405041212667',
  monthly: '1151405041212668',
  daily: '1151405041212669',
  weekly: '1151405041212670',
  quartely: '1151405041212671',
  biweekly: '1187310722695387',
};

export const FlyerProject: any = {
  projects: '1183981036932973',
  account: '1162090095035112',
  projectType: '1183981036932986',
  flyerProject: '1183981036932991',
};
