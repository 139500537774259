import React from 'react';
import notFoundCat from '../images/404.jpeg';

const NotFoundPage: React.FC = () => {
  return (
    <div>
      <img src={notFoundCat} alt="Not Found" />
    </div>
  );
};

export default NotFoundPage;
