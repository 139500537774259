import { webToolsServerURL } from '../utilities';
import { IProduct, IProductValid } from './interfaces/productInterfaces';
import jwt from 'jsonwebtoken';

export const validateProducts = (
  compassSiteId: string,
  products: IProduct[],
  checkAgainstTradeport: boolean,
): Promise<IProductValid> => {
  return new Promise(async (resolve) => {
    const groupSize = 10;
    const productBatches = products
      .map((_, i) => {
        return i % groupSize === 0 ? products.slice(i, i + groupSize) : null;
      })
      .filter((x) => x);

    let isValid = true;
    const messages = [] as string[];
    for (const productBatch of productBatches) {
      await fetch(`${webToolsServerURL}/api/compass/products/validate`, {
        method: 'POST',
        body: JSON.stringify({
          compassSiteId: compassSiteId,
          products: productBatch,
          checkAgainstTradeport: checkAgainstTradeport,
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwt.sign({}, process.env.REACT_APP_SERVER_SECRET as string, { expiresIn: '5m' })}`,
        },
      })
        .then(async (res) => {
          if (!res.ok) {
            return await res.json().then((data) => {
              throw new Error(data.error);
            });
          }
          await res.json().then((data: { isValid: boolean; messages: string[] }) => {
            isValid = isValid && data.isValid;
            if (data.messages && data.messages.length > 0) messages.push(...data.messages);
          });
        })
        .catch((error) => {
          isValid = false;
          if (productBatch && productBatch.length > 0)
            productBatch.forEach((x) => {
              messages.push(`Something went wrong; Product Sku: ${x.productSKU}, Error: ${error.message}`);
            });
        });
    }

    resolve({ isValid, messages });
  });
};

export const addSurveyResults = (
  productSKU: string,
  compassSiteId: string,
  surveyAnswers: string[],
): Promise<string> => {
  return new Promise((resolve, reject) => {
    fetch(`${webToolsServerURL}/api/compass/products/survey`, {
      method: 'POST',
      body: JSON.stringify({
        productSKU: productSKU,
        compassSiteId: compassSiteId,
        surveyAnswers: surveyAnswers,
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt.sign({}, process.env.REACT_APP_SERVER_SECRET as string, { expiresIn: '5m' })}`,
      },
    })
      .then((res) => {
        if (res.status === 204) {
          resolve();
          return;
        }
        if (!res.ok) {
          return res.json().then((data) => {
            throw new Error(data.error);
          });
        }
        res.json().then((data) => {
          resolve(data);
        });
      })
      .catch((error) => {
        reject(`Something went wrong... Error: ${error.message}`);
      });
  });
};
