import React, { useState, useEffect } from 'react';
import {
  getLocations,
  getLocationAvailability,
  book,
  getEmployeeSchedule,
  removeBooking,
  getBookingAdmins,
  getLocationBookings,
} from '../../shared/endpointAccess/booking';
import DropdownField from '../../components/form/DropdownField';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import { useAuthState } from '../../providers/AuthProvider';
import InputNumberField from '../../components/form/InputNumberField';
import { IBookings } from '../../shared/endpointAccess/interfaces/booking/IBookings';
import './BookPage.scss';
import { IBookingAdmins } from '../../shared/endpointAccess/interfaces/booking/IBookingAdmins';
import NavButton from '../../components/form/navButton/navButton';
import InputDateField from '../../components/form/InputDateField';
import { websocketURL } from '../../shared/utilities';
import jwt from 'jsonwebtoken';
import { useGlobalError } from '../../providers/ErrorProvider';
import cx from 'classnames';

const localizer = momentLocalizer(moment);

const BookPage: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { authState } = useAuthState()!;
  const [locations, setLocations] = useState<{ id: string; value: string }[]>([]);
  const [activeLocation, setActiveLocation] = useState<string>('');
  const [locationAvailability, setLocationAvailability] = useState<any>();
  const [locationsBookings, setLocationBookings] = useState<{ [date: string]: IBookings[] }>();
  const [calendarEvents, setCalendarEvents] = useState<
    {
      title: string;
      start: Date;
      end: Date;
      allDay?: boolean;
      resource?: any;
    }[]
  >([]);
  const [employeeSchedule, setEmployeeSchedule] = useState<IBookings[]>([]);
  const [spotsReserve, setSpotsReserve] = useState<number>(0);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [showReservationPopUp, setShowReservationPopUp] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [adminList, setAdminList] = useState<IBookingAdmins[]>([]);
  const [locationMaxAvailability, setLocationMaxAvailability] = useState<{ [id: string]: number }>({});
  const [showSchedules, setShowSchedules] = useState<boolean>(false);
  const [showAdmins, setShowAdmins] = useState<boolean>(false);
  const [scheduleSearchDate, setScheduleSearchDate] = useState<Date>(new Date(Date.parse(new Date().toDateString())));
  const [ws, setWS] = useState<WebSocket>();
  const [loading, setLoading] = useState<boolean>(true);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { setGlobalError } = useGlobalError()!;

  const startUp = (): void => {
    getBookingAdmins().then((adminList) => {
      setAdminList(adminList);
      setIsAdmin(!!adminList.find((admin) => admin.email.toLowerCase() === authState.user.email.toLowerCase()));
      getLocations().then((locationList) => {
        setLocationMaxAvailability(
          (locationList.reduce(
            (o, location) => ({ ...(o as any), [location._id]: location.available }),
            0,
          ) as unknown) as {
            [id: string]: number;
          },
        );
        setLocations(locationList.map((location) => ({ id: location._id, value: location.locationName })));
        setActiveLocation(locationList[0]._id);
      });
    });
  };

  useEffect(() => {
    startUp();
    setWS(new WebSocket(websocketURL));
  }, []);

  const getLocalTime = (date: Date): Date => {
    return moment(date).subtract(moment(date).utcOffset(), 'minutes').toDate();
  };

  const getUTCTime = (date: Date): Date => {
    return moment(date).add(moment(date).utcOffset(), 'minutes').toDate();
  };

  const updateCalendarEvents = (availability: any, currentSchedule: IBookings[]): void => {
    const locationAvail = Object.keys(availability).map((date) => ({
      title: `${availability[date]} spot${availability[date] === 1 ? '' : 's'} available`,
      start: getLocalTime(new Date(parseInt(date))),
      end: getLocalTime(new Date(parseInt(date))),
      allDay: true,
    }));

    const currentScheduleObject = currentSchedule.reduce(
      (obj, item) => ({ ...obj, [item.date.getTime()]: item }),
      {},
    ) as { [date: string]: IBookings };

    const currentReservations = !isAdmin
      ? []
      : Object.keys(availability)
          .filter(
            (date) =>
              locationMaxAvailability[activeLocation] -
                availability[date] -
                (currentScheduleObject[getLocalTime(new Date(parseInt(date))).getTime()]?.amountReserved || 0) >
              0,
          )
          .map((date) => ({
            title: `${
              locationMaxAvailability[activeLocation] -
              availability[date] -
              (currentScheduleObject[getLocalTime(new Date(parseInt(date))).getTime()]?.amountReserved || 0)
            }
         spot${availability[date] === 1 ? '' : 's'} reserved by others`,
            start: getLocalTime(new Date(parseInt(date))),
            end: getLocalTime(new Date(parseInt(date))),
            allDay: true,
          }));
    if (process.env.REACT_APP_BOOKING_HIDE_WEEKENDS) {
      setCalendarEvents([
        ...locationAvail.filter((x) => x.start.getDay() % 6 !== 0),
        ...currentSchedule
          .map((bookings: any) => ({
            title: `${bookings.amountReserved} spot${bookings.amountReserved === 1 ? '' : 's'} scheduled`,
            start: bookings.date,
            end: bookings.date,
            allDay: true,
          }))
          .filter((x) => x.start.getDay() % 6 !== 0),
        ...currentReservations.filter((x) => x.start.getDay() % 6 !== 0),
      ]);
    } else {
      setCalendarEvents([
        ...locationAvail,
        ...currentSchedule.map((bookings: any) => ({
          title: `${bookings.amountReserved} spot${bookings.amountReserved === 1 ? '' : 's'} scheduled`,
          start: bookings.date,
          end: bookings.date,
          allDay: true,
        })),
        ...currentReservations,
      ]);
    }
    setLoading(false);
  };

  const setWSMessage = (): void => {
    if (ws !== undefined)
      ws.onmessage = (evt: any): void => {
        const message = JSON.parse(evt.data);
        if (message.type === 'booking_spot_cancel') {
          if (message.data.locationId !== activeLocation) return;
          let newAvailability = locationAvailability;
          let newEmployeeSchedule = employeeSchedule;
          if (authState.user.id === message.data.employeeId) {
            const changedLocalDate = getLocalTime(new Date(message.data.date));
            newEmployeeSchedule = [...employeeSchedule.filter((x) => changedLocalDate.getTime() != x.date.getTime())];
            setEmployeeSchedule(newEmployeeSchedule);
          }
          const dateChanged = new Date(message.data.date).getTime();
          newAvailability = {
            ...locationAvailability,
            [dateChanged]: locationAvailability[dateChanged] + message.data.amountReserved,
          };
          setLocationAvailability(newAvailability);

          const newBookingTime = getLocalTime(new Date(message.data.date)).getTime();
          const oldLocationBookings = locationsBookings || {};
          setLocationBookings({
            ...oldLocationBookings,
            [newBookingTime]: [
              ...oldLocationBookings[newBookingTime].filter((x) => x.employeeName !== message.data.employeeName),
            ],
          });

          updateCalendarEvents(newAvailability, newEmployeeSchedule);
        }
        if (message.type === 'booking_spot') {
          if (message.data.locationId !== activeLocation) return;
          const dateChanged = new Date(message.data.date).getTime();
          let newAvailability = locationAvailability;
          let newEmployeeSchedule = employeeSchedule;
          let newLocationBookings = locationsBookings || {};
          if (authState.user.id === message.data.employeeId) {
            const changedLocalDate = getLocalTime(new Date(message.data.date));
            if (message.data.newBooking) {
              // created own new booking
              newEmployeeSchedule = [
                ...employeeSchedule,
                {
                  date: changedLocalDate,
                  amountReserved: message.data.amountReserved,
                  employeeId: message.data.employeeId,
                  employeeName: message.data.employeeName,
                  locationId: message.data.locationId,
                },
              ];
              setEmployeeSchedule(newEmployeeSchedule);
            } else {
              // Changing own booking
              newEmployeeSchedule = employeeSchedule.map((x) => {
                if (changedLocalDate.getTime() == x.date.getTime()) {
                  return {
                    ...x,
                    amountReserved: message.data.amountReserved,
                  };
                }
                return x;
              });
              setEmployeeSchedule(newEmployeeSchedule);
            }
          }
          if (message.data.newBooking) {
            const newBookingTime = getLocalTime(new Date(message.data.date)).getTime();
            if (newBookingTime in newLocationBookings) {
              newLocationBookings = {
                ...newLocationBookings,
                [newBookingTime]: [
                  ...newLocationBookings[newBookingTime],
                  {
                    employeeName: message.data.employeeName,
                    amountReserved: message.data.amountReserved,
                  },
                ],
              };
              setLocationBookings(newLocationBookings);
            } else {
              newLocationBookings = {
                ...newLocationBookings,
                [newBookingTime]: [
                  {
                    employeeName: message.data.employeeName,
                    amountReserved: message.data.amountReserved,
                  },
                ],
              };
              setLocationBookings(newLocationBookings);
            }
          } else {
            const newBookingTime = getLocalTime(new Date(message.data.date)).getTime();
            newLocationBookings = {
              ...newLocationBookings,
              [newBookingTime]: [
                ...newLocationBookings[newBookingTime].map((x) => {
                  if (x.employeeName === message.data.employeeName) {
                    return {
                      employeeName: message.data.employeeName,
                      amountReserved: message.data.amountReserved,
                    };
                  }
                  return x;
                }),
              ],
            };
            setLocationBookings(newLocationBookings);
          }
          newAvailability = {
            ...locationAvailability,
            [dateChanged]: locationAvailability[dateChanged] - message.data.difference,
          };
          setLocationAvailability(newAvailability);
          updateCalendarEvents(newAvailability, newEmployeeSchedule);
        }
      };
  };

  useEffect(() => {
    if (ws === undefined) return;

    ws.onopen = (): void => {
      setGlobalError('');
      startUp();

      const message = {
        type: 'authenticate',
        token: jwt.sign({}, process.env.REACT_APP_SERVER_SECRET as string, { expiresIn: '5m' }),
      };
      ws.send(JSON.stringify(message));
    };

    setWSMessage();

    ws.onclose = (): void => {
      setGlobalError('Unable to connect to server. Will retry to connect in 10s.');
      ws.close();
      if (ws.readyState === 3)
        setTimeout(() => {
          setWS(
            (): WebSocket => {
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              ws.onclose = function (): void {};
              ws.close();
              return new WebSocket(websocketURL);
            },
          );
        }, 10000);
    };

    ws.onerror = (): void => {
      setGlobalError('Unable to connect to server. Will retry to connect in 10s.');
      ws.close();
    };
  }, [ws]);

  useEffect(() => {
    setWSMessage();
  }, [locationAvailability, employeeSchedule, locationsBookings]);

  const refreshLocationAvailability = (): void => {
    setLoading(true);
    getLocationAvailability(activeLocation).then((availability) => {
      setLocationAvailability(availability);

      getEmployeeSchedule(authState.user.id).then((schedule) => {
        const currentSchedule = schedule
          .filter((x) => x.locationId === activeLocation)
          .map((x) => ({ ...x, date: getLocalTime(x.date as Date) }));
        setEmployeeSchedule(currentSchedule);

        updateCalendarEvents(availability, currentSchedule);
      });
    });
    getLocationBookings(activeLocation).then((locationBookings) => {
      setLocationBookings(
        !!locationBookings && Object.keys(locationBookings).length > 0
          ? Object.keys(locationBookings)
              .map((x) => ({
                [getLocalTime(new Date(parseInt(x))).getTime()]: locationBookings[x],
              }))
              .reduce((a, b) => Object.assign({}, a, b))
          : {},
      );
    });
  };

  const bookSpot = (): void => {
    book({
      employeeId: authState.user.id,
      employeeName: authState.user.displayName,
      date: getUTCTime(selectedDate as Date),
      amountReserved: spotsReserve,
      locationId: activeLocation,
    }).then((): void => {
      refreshLocationAvailability();
      setShowReservationPopUp(false);

      const message = {
        type: 'booking_spot',
        token: jwt.sign({}, process.env.REACT_APP_SERVER_SECRET as string, { expiresIn: '5m' }),
        payload: {
          employeeId: authState.user.id,
          employeeName: authState.user.displayName,
          date: getUTCTime(selectedDate as Date),
          amountReserved: spotsReserve,
          locationId: activeLocation,
          newBooking: !employeeSchedule.find((x) => Date.parse(x.date.toString()) === selectedDate?.getTime()),
          difference:
            spotsReserve -
            (employeeSchedule.find((x) => Date.parse(x.date.toString()) === selectedDate?.getTime())?.amountReserved ||
              0),
        },
      };
      if (!!ws) ws.send(JSON.stringify(message));
    });
  };

  const cancelReservation = (): void => {
    removeBooking({
      employeeId: authState.user.id,
      employeeName: authState.user.displayName,
      date: getUTCTime(selectedDate as Date),
      amountReserved: 0,
      locationId: activeLocation,
    }).then(() => {
      refreshLocationAvailability();
      setShowReservationPopUp(false);

      const message = {
        type: 'booking_spot_cancel',
        token: jwt.sign({}, process.env.REACT_APP_SERVER_SECRET as string, { expiresIn: '5m' }),
        payload: {
          employeeId: authState.user.id,
          employeeName: authState.user.displayName,
          date: getUTCTime(selectedDate as Date),
          amountReserved:
            employeeSchedule.find((x) => Date.parse(x.date.toString()) === selectedDate?.getTime())?.amountReserved ||
            spotsReserve,
          locationId: activeLocation,
        },
      };
      if (!!ws) ws.send(JSON.stringify(message));
    });
  };

  useEffect(() => {
    if (!activeLocation) return;
    refreshLocationAvailability();
  }, [activeLocation]);

  const currentBookedSpot = employeeSchedule.find((x) => Date.parse(x.date.toString()) === selectedDate?.getTime());

  return (
    <div className="book_container">
      {isAdmin && (
        <>
          <NavButton
            automationId="book_nav_admin_button"
            buttonText="Admin Tools"
            navigation={[
              { id: '1', text: 'View Schedules' },
              { id: '2', text: 'View Admins' },
            ]}
            onChange={(id: string): void => {
              switch (id) {
                case '1':
                  setShowSchedules(true);
                  break;
                case '2':
                  setShowAdmins(true);
                  break;
              }
            }}
          />
        </>
      )}
      <h1 className="book_title">Reserve time at a location:</h1>
      <p className="book_sub_text">
        Select a location and double click the spots available on desired date to schedule or change reservation.
      </p>
      <div className="book_dropdown_field">
        <DropdownField
          label=""
          value={activeLocation}
          handleChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
            setActiveLocation(e.target.value);
          }}
          placeholder="Location"
          options={locations}
          error=""
        />
      </div>
      <div className="book_calendar_container">
        <div className={cx('bookingloading', { hidden: !loading })}>
          Loading <span className="period1">.</span>
          <span className="period2">.</span>
          <span className="period3">.</span>
        </div>
        <Calendar
          localizer={localizer}
          events={calendarEvents}
          startAccessor="start"
          endAccessor="end"
          views={['month']}
          style={{ height: 650 }}
          selectable={true}
          onDoubleClickEvent={(event): void => {
            if (
              isAdmin &&
              (event.title.includes('spot reserved by others') || event.title.includes('spots reserved by others'))
            ) {
              setShowSchedules(true);
              setScheduleSearchDate(event.start);
            } else {
              setShowReservationPopUp(true);
              setSelectedDate(event.start);
              setSpotsReserve(
                employeeSchedule.find((x) => new Date(x.date).getTime() === new Date(event.start).getTime())
                  ?.amountReserved || 0,
              );
            }
          }}
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          eventPropGetter={(event, _start, _end, _isSelected): any => {
            if (event.title === '0 spots available')
              return {
                style: { backgroundColor: 'red' },
              };
            if (event.title.includes('spot scheduled') || event.title.includes('spots scheduled'))
              return {
                style: { backgroundColor: 'green' },
              };

            if (event.title.includes('spot reserved by others') || event.title.includes('spots reserved by others'))
              return {
                style: { backgroundColor: 'darkblue' },
              };
            return {};
          }}
        />
      </div>
      {showReservationPopUp && (
        <>
          <div className="book_pop_up_background" onClick={(): void => setShowReservationPopUp(false)}></div>
          <div className="book_pop_up">
            <h2 className="book_pop_up_title">Reserve a spot:</h2>
            <p className="book_pop_up_description">
              There are currently{' '}
              {locationAvailability[selectedDate != undefined ? getUTCTime(selectedDate).getTime() : -1]} spot
              {locationAvailability[selectedDate != undefined ? getUTCTime(selectedDate).getTime() : -1] === 1
                ? ''
                : 's'}{' '}
              {selectedDate?.toDateString()}. You have {currentBookedSpot?.amountReserved || 0} spot
              {currentBookedSpot?.amountReserved === 1 ? '' : 's'} currently reserved for this date.
            </p>
            {locationAvailability[selectedDate?.getTime() || -1] + currentBookedSpot?.amountReserved !== 0 && (
              <div className="book_pop_up_number_field">
                <InputNumberField
                  label="How many spots to reserve:"
                  value={spotsReserve}
                  handleChange={(e): void => {
                    if (
                      e.target.value <=
                      (locationAvailability[selectedDate != undefined ? getUTCTime(selectedDate).getTime() : -1] +
                        (currentBookedSpot?.amountReserved || 0) || locationMaxAvailability[activeLocation])
                    )
                      setSpotsReserve(parseInt(e.target.value));
                  }}
                  error=""
                  min={0}
                  max={
                    locationAvailability[selectedDate != undefined ? getUTCTime(selectedDate).getTime() : -1] +
                      (currentBookedSpot?.amountReserved || 0) || locationMaxAvailability[activeLocation]
                  }
                />
              </div>
            )}
            <span className="book_pop_up_close" onClick={(): void => setShowReservationPopUp(false)}>
              <i className="fa fa-times" />
            </span>
            {!!currentBookedSpot && (
              <button className="book_pop_up_button" onClick={cancelReservation}>
                Cancel Reservation
              </button>
            )}
            {spotsReserve === 0 ||
            !spotsReserve ||
            spotsReserve >
              (locationAvailability[selectedDate != undefined ? getUTCTime(selectedDate).getTime() : -1] +
                (currentBookedSpot?.amountReserved || 0) || locationMaxAvailability[activeLocation]) ? (
              <button className="book_pop_up_button" onClick={bookSpot} disabled={true}>
                {!currentBookedSpot ? 'Reserve' : 'Change Reservation'}
              </button>
            ) : (
              <button className="book_pop_up_button" onClick={bookSpot}>
                {!currentBookedSpot ? 'Reserve' : 'Change Reservation'}
              </button>
            )}
          </div>
        </>
      )}

      {showAdmins && (
        <>
          <div className="book_pop_up_background" onClick={(): void => setShowAdmins(false)}></div>
          <div className="book_pop_up book_pop_up_admins">
            <h2>Current Admins:</h2>
            {adminList.map((admin) => (
              <p key={admin.email}>{admin.email}</p>
            ))}
            <span className="book_pop_up_close" onClick={(): void => setShowAdmins(false)}>
              <i className="fa fa-times" />
            </span>
          </div>
        </>
      )}

      {showSchedules && (
        <>
          <div className="book_pop_up_background" onClick={(): void => setShowSchedules(false)}></div>
          <div className="book_pop_up">
            <div className="book_pop_up_booking_schedule_flex">
              <div className="book_pop_up_booking_select_date">
                <h3>Location:</h3>
                <p>{locations.find((location) => location.id === activeLocation)?.value}</p>
                <InputDateField
                  label="Select Date"
                  placeholder={new Date().toISOString().split('T')[0]}
                  value={scheduleSearchDate.toISOString().split('T')[0]}
                  handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    setScheduleSearchDate(moment(e.target.value, 'YYYY-MM-DD').toDate());
                  }}
                  error=""
                  isFull
                />
              </div>
              <div className="book_pop_up_booking_display_schedules">
                <h2>Current Schedules:</h2>
                {!locationsBookings ||
                  (!locationsBookings[scheduleSearchDate.getTime()] && <p>No bookings for selected date.</p>)}

                {!!locationsBookings &&
                  !!locationsBookings[scheduleSearchDate.getTime()] &&
                  locationsBookings[scheduleSearchDate.getTime()].map((bookings) => (
                    <p className="book_pop_up_booking_text" key={`${bookings.date}${bookings.employeeId}`}>
                      {bookings.employeeName} - {bookings.amountReserved} spots reserved
                    </p>
                  ))}
              </div>
            </div>
            <span className="book_pop_up_close" onClick={(): void => setShowSchedules(false)}>
              <i className="fa fa-times" />
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default BookPage;
