import React from 'react';
import './FieldLabel.scss';

interface IProps {
  label: string;
  isOptional?: boolean;
}

const FieldLabel: React.FC<IProps> = (props) => {
  return (
    <>
      <div className="field-label">
        {props.label}
        {props.isOptional && <span className="optional-tag"> (optional)</span>}
      </div>
    </>
  );
};

export default FieldLabel;
