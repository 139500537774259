import React from 'react';
import './RemoveableList.scss';
import { isUrl } from '../../../shared/utilities';

interface IProps {
  listToDisplay: Array<any>;
  handleRemoveListItem?: (item: string) => void;
}

const RemoveableList: React.FC<IProps> = (props) => {
  return (
    <>
      <ul className="removeable-list">
        {props.listToDisplay.map((item) => {
          return (
            <li className="removeable-list-item" key={item}>
              <span>
                {isUrl(item) ? (
                  <a href={item} rel="noopener noreferrer" target="_blank">
                    {item}
                  </a>
                ) : (
                  item
                )}
              </span>
              {!!props.handleRemoveListItem && (
                <span
                  className="removeable-list-item-remove"
                  onClick={(): void => {
                    !!props.handleRemoveListItem && props.handleRemoveListItem(item);
                  }}
                >
                  <span role="img" aria-label="X">
                    ❌
                  </span>
                </span>
              )}
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default RemoveableList;
