import { getApi } from './helper';

export const getPermissions = (area: string): Promise<void> => {
  return getApi(`permissions/${area}`);
};

export const verifyPermission = async (area: string, email: string): Promise<boolean> => {
  const response = await getApi(`permissions/${area}`);

  if (
    response &&
    response.allowedUsers &&
    response.allowedUsers.filter((user: string) => user.toLowerCase() === email.toLowerCase()).length > 0
  )
    return true;
  else return false;
};
