import React, { useState } from 'react';
import cx from 'classnames';
import './FulfillmentsInfo.scss';
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';
import {
  IFulfillment,
  IFulfillOrdersResponse,
} from '../../../../shared/endpointAccess/interfaces/fulfillmentInterfaces';

interface Props {
  fulfillment: IFulfillment;
  orderVerified?: Function;
  retryFulfillment?: Function;
  fulfillmentsAttempted: IFulfillOrdersResponse;
}

const FulfillmentsInfo: React.FC<Props> = ({
  fulfillment,
  // eslint-disable-next-line
  retryFulfillment = (): void => { },
  fulfillmentsAttempted,
}) => {
  const [tabClosed, setTabClosed] = useState<boolean>(true);
  console.log(fulfillmentsAttempted);
  return (
    <div>
      <div
        className="fulfillment-container"
        onClick={(): void => {
          setTabClosed(!tabClosed);
        }}
      >
        <div className="fulfillment-order-id">
          <p>{fulfillment.orderId}</p>
        </div>
        <div
          className={cx('order-fulfilled', {
            'error-color': !fulfillmentsAttempted?.isSuccess && fulfillmentsAttempted?.attempted,
            'success-color': fulfillmentsAttempted?.attempted && fulfillmentsAttempted?.isSuccess,
          })}
        >
          {!fulfillmentsAttempted?.isSuccess && fulfillmentsAttempted?.attempted && <p>Error while fulfilling order</p>}
          {!fulfillmentsAttempted?.attempted && <p>Waiting to fulfill order...</p>}
          {fulfillmentsAttempted?.isSuccess && <p>{`Fulfilled Order: #${fulfillmentsAttempted?.orderId}`}</p>}
        </div>
        <div className="order-buttons">
          {!fulfillmentsAttempted?.isSuccess && fulfillmentsAttempted?.attempted && (
            <span>
              <i
                className="fa fa-refresh clickable"
                onClick={(e: any): void => {
                  retryFulfillment(fulfillment);
                  e.stopPropagation();
                }}
              ></i>
            </span>
          )}
        </div>
      </div>
      <div className="order-info">
        <SlideDown closed={tabClosed}>
          {!fulfillmentsAttempted?.isSuccess &&
            fulfillmentsAttempted?.attempted &&
            fulfillmentsAttempted?.errorMessage && (
              <div className="validation-errors">
                <p>{fulfillmentsAttempted.errorMessage}</p>
              </div>
            )}
        </SlideDown>
      </div>
    </div>
  );
};

export default FulfillmentsInfo;
