import React from 'react';
import './ValidateFulfillmentsTab.scss';

interface Props {
  currentTab: string;
  fulfillmentsValid: boolean;
  fulfillmentValidationErrors: string[];
}

const ValidateFulfillmentsTab: React.FC<Props> = ({
  currentTab = '',
  fulfillmentsValid = 'false',
  fulfillmentValidationErrors: fulfillmentValidationErrors = [],
}) => {
  return (
    <>
      {currentTab === 'Validate' && (
        <>
          <div className="all-products-height-setup">
            <div className="all-products">
              {!fulfillmentsValid && (
                <div className="fulfillment-verification-header">Fulfillment Verification Errors:</div>
              )}
              {!fulfillmentsValid &&
                fulfillmentValidationErrors.map((error: string) => (
                  <div key={error}>
                    <p className="fulfillment-error-text">{error}</p>
                  </div>
                ))}
              {fulfillmentsValid && <>Validated Fulfillments Successfully</>}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ValidateFulfillmentsTab;
