import React, { useState } from 'react';
import { Popper } from '@material-ui/core';
import './navButton.scss';

export interface IProps {
  automationId: string;
  buttonText: string;
  navigation: {
    id: string;
    text: string;
  }[];
  onChange: (id: string) => void;
}

const NavButton: React.FC<IProps> = (props) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const anchorRef = React.useRef(null);

  return (
    <div
      onMouseLeave={(): void => {
        setMenuOpen(false);
      }}
    >
      <span
        className="nav-button"
        ref={anchorRef}
        onClick={(): void => {
          setMenuOpen(!menuOpen);
        }}
      >
        {props.buttonText}
        <i className={`fa fa-chevron-${menuOpen ? 'up' : 'down'} nav-button-icon`}></i>
      </span>
      {menuOpen && (
        <Popper placement="bottom-end" anchorEl={anchorRef.current} open={menuOpen}>
          <div className="nav-button-menu" style={{ width: (anchorRef?.current as any)?.clientWidth || 0 }}>
            {props.navigation.map((navigation) => (
              <div
                className="nav-button-menu-item"
                key={navigation.id}
                onClick={(): void => {
                  props.onChange(navigation.id);
                  setMenuOpen(false);
                }}
              >
                <span>{navigation.text}</span>
              </div>
            ))}
          </div>
        </Popper>
      )}
    </div>
  );
};

export default NavButton;
