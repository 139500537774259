import React from 'react';
import Field from '../Field/Field';
import './InputTextAreaField.scss';

interface IProps {
  automationId?: string; // todo: make this not optional
  label: string;
  error?: string;
  handleChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value: string;
  placeholder?: string;
  isFull?: boolean;
}

const InputTextAreaField: React.FC<IProps> = (props) => {
  return (
    <Field isFull={props.isFull} label={props.label} error={props.error}>
      <textarea className="field-input text-area" value={props.value} onChange={props.handleChange} />
    </Field>
  );
};

export default InputTextAreaField;
