import React, { useState, useEffect } from 'react';
import DropdownField from '../../../components/form/DropdownField';
import { ToastContainer, toast } from 'react-toastify';
import IGetNameAndIdResponse from '@theicebox/external-api-integrations-interfaces/distInterfaces/business_layer/interfaces/Compass/IGetNameAndIdResponse';
import { IDropDownFieldData } from '../SettingsPage/Interfaces';
import { getApi } from '../../../shared/endpointAccess/helper';
import { setTermIds } from '../../../shared/endpointAccess/orders';
import InputTextAreaField from '../../../components/form/InputTextAreaField/InputTextAreaField';

import './OrderPaymentTermsPage.css';

const OrderPaymentTermsPage: React.FC = () => {
  const [orderIdsError, setOrderIdsError] = useState<string>('');
  const [orderIds, setOrderIds] = useState<string>('');
  const [paymentTerm, setPaymentTerm] = useState<string>('');
  const [paymentTermError, setPaymentTermError] = useState<string>('');
  const [PaymentTermsResponse, setPaymentTermsResponse] = useState<IDropDownFieldData[]>([]);

  const successNotification = (): number | string => toast.success('The orders payment terms have been updated');

  const deltaClean = 2000291;

  const getPaymentTerms = (): void => {
    getApi(`compass/sites/${deltaClean}/paymentterms`).then((Data: IGetNameAndIdResponse[]) => {
      const newArray = Data.map((siteInfo) => {
        const siteDetails = { id: siteInfo.Id, value: siteInfo.Name };
        return siteDetails;
      });
      setPaymentTermsResponse(newArray);
    });
  };

  const submitOrderPaymentTerms = async (): Promise<void> => {
    setOrderIdsError('');
    setPaymentTermError('');
    let hasError = false;

    if (!paymentTerm || !/^\d+$/.test(paymentTerm)) {
      setPaymentTermError('Please select a payment term');
      hasError = true;
    }

    if (!orderIds || !/^(\d+(, +|,)*)+$/.test(orderIds)) {
      setOrderIdsError('Orders id list is not comma separated list of numbers');
      hasError = true;
    }

    if (hasError) return;

    toast.info('Setting payment terms for order(s)');
    await setTermIds({
      termId: paymentTerm,
      orderIds: orderIds.trim().split(','),
    }).then(() => {
      successNotification();
    });
  };

  useEffect(() => {
    getPaymentTerms();
  }, []);

  return (
    <div className="order-payment-terms-container">
      <div className="order-payment-terms-header">
        <h3>Set Orders Payment Term</h3>
      </div>
      <InputTextAreaField
        handleChange={(e): void => setOrderIds(e.target.value)}
        label="Order Ids (Comma Separated):"
        value={orderIds}
        error={orderIdsError}
        isFull
      />
      <DropdownField
        label="Select A Payment Term:"
        placeholder="Payment Term"
        value={paymentTerm}
        handleChange={(e): void => setPaymentTerm(e.target.value)}
        options={PaymentTermsResponse}
        error={paymentTermError}
        isFull
      />
      <ToastContainer />
      <button className="submit-button" onClick={submitOrderPaymentTerms}>
        Submit Changes
      </button>
    </div>
  );
};

export default OrderPaymentTermsPage;
