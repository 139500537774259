import React from 'react';

import bg from '../../images/drawn-bg.png';
import './LandingPage.scss';
import { useAuthState } from '../../providers/AuthProvider';
import { Redirect } from 'react-router-dom';

const LandingPage: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { login, authState } = useAuthState()!;
  if (authState.isAuthenticated) return <Redirect to="/home" />;
  return (
    <>
      <div className="bg-div" style={{ backgroundImage: `url(${bg})` }}></div>
      <div className="login-container">
        <div id="login-group">
          <div id="login-message">Please log in with your Microsoft work email.</div>
          <div id="login-button" onClick={login}>
            Log In
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
