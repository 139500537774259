import * as React from 'react';

const BookIcon: React.FC<any> = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 107.43" {...props}>
      <path
        // eslint-disable-next-line max-len
        d="M99.89 96.65c2.9 0 5.25.5 5.25 1.12 0 .62-2.35 1.12-5.25 1.12s-5.25-.5-5.25-1.12c-.01-.61 2.34-1.12 5.25-1.12h0zM33.47 0h52.4c.83 0 1.51.68 1.51 1.5v31.18c0 .83-.68 1.51-1.51 1.51h-52.4c-.83 0-1.51-.68-1.51-1.51V1.51c0-.83.68-1.51 1.51-1.51h0zm19.37 36.27H66.5c.03 2.23.9 4.24 3.21 5.86h50.52c.73 0 1.39.3 1.87.77.48.48.78 1.14.78 1.87v7.86c0 .73-.3 1.39-.78 1.87-.48.48-1.14.78-1.87.78h-.17v50.28c0 .51-.21.98-.55 1.31l-.01.01c-.34.34-.8.55-1.31.55H82.07a1.868 1.868 0 01-1.49-.75c-.24-.31-.38-.7-.38-1.12V55.28H22.39v50.28c0 .52-.21.98-.55 1.32-.34.34-.81.55-1.32.55h-8.86c-.51 0-.97-.21-1.31-.55h0c-.34-.34-.55-.8-.55-1.31V55.28H2.64c-.73 0-1.39-.3-1.87-.78C.3 54.02 0 53.36 0 52.63v-7.86c0-.73.3-1.39.78-1.87a2.63 2.63 0 011.87-.77h46.94c1.9-1.48 3.26-3.28 3.25-5.86h0zm6.83-7.16a1.931 1.931 0 010 3.86 1.94 1.94 0 01-1.94-1.93c.01-1.06.87-1.93 1.94-1.93h0zM36.49 2.84h46.35c.73 0 1.33.6 1.33 1.33v22.4c0 .73-.6 1.33-1.33 1.33H36.49c-.73 0-1.33-.6-1.33-1.33V4.17c0-.73.6-1.33 1.33-1.33h0zm46.79 66.65h33.7v-14.2h-33.7v14.2h0zm33.71 3.07h-33.7v14.48h33.7V72.56h0zm0 17.57h-33.7v14.22h33.7V90.13h0zm2.81-44.93H3.08v6.99H119.8V45.2h0zM19.31 55.28h-6.44v49.06h6.44V55.28h0zm80.58 5.84c2.9 0 5.25.47 5.25 1.06s-2.35 1.06-5.25 1.06-5.25-.48-5.25-1.06 2.34-1.06 5.25-1.06h0zm0 17.74c2.9 0 5.25.5 5.25 1.12 0 .62-2.35 1.12-5.25 1.12s-5.25-.5-5.25-1.12c-.01-.62 2.34-1.12 5.25-1.12h0z"
        strokeWidth={3}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default BookIcon;
