export interface IFormData {
  site: string;
  useActualShipping: boolean;
  useResidentialShip: boolean;
  checkAgainstTradeport: boolean;
}

export const defaultFormData: IFormData = {
  site: '',
  useActualShipping: false,
  useResidentialShip: true,
  checkAgainstTradeport: true,
};

export interface IFormErrors {
  site: string;
  fileDrop: string;
  useActualShipping: string;
  useResidentialShip: string;
  checkAgainstTradeport: string;
}

export const defaultFormErrors: IFormErrors = {
  site: '',
  fileDrop: '',
  useActualShipping: '',
  useResidentialShip: '',
  checkAgainstTradeport: '',
};
