import React, { useState, useEffect } from 'react';

import FileDrop from '../../../../components/form/FileDrop/FileDrop';
import { IFormErrors, defaultFormErrors } from '../Interfaces';
import { useGlobalError } from '../../../../providers/ErrorProvider';
import { parseFulfillments } from '../../../../shared/endpointAccess/fulfillments';
import { IFulfillments } from '../../../../shared/endpointAccess/interfaces/fulfillmentInterfaces';
import ToggleSwitch from '../../../../components/form/ToggleSwitch/ToggleSwitch';

interface IProps {
  clearFulfillments: () => void;
  setLoading: (loading: boolean) => void;
  setFulfillments: (fulfillments: IFulfillments) => void;
  setIgnoreValidation: (ignoreValidation: boolean) => void;
}

const LeftBar: React.FC<IProps> = (props) => {
  const [formErrors, setFormErrors] = useState<IFormErrors>(defaultFormErrors);
  const [uploadedFiles, setUploadedFiles] = useState<Array<File>>([]);
  const [isFulfilling, setIsFulfilling] = useState<boolean>(false);
  const [ignoreValidation, setIgnoreValidation] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { setGlobalError } = useGlobalError()!;

  const checkForErrors = ({ addedFile }: { addedFile: boolean } = { addedFile: false }): boolean => {
    const newFormErrors = {} as IFormErrors;
    newFormErrors.fileDrop = uploadedFiles.length <= 0 ? ' Please select a file.' : '';
    if (addedFile) newFormErrors.fileDrop = '';
    setFormErrors(newFormErrors);

    return uploadedFiles.length > 0;
  };

  const setUploadedFilesToState = (filesArray: File[]): void => {
    const fileNamesFromState = uploadedFiles.map((file) => {
      return file.name;
    });
    const filesToBeAdded = [...filesArray].filter((file) => {
      if (fileNamesFromState.includes(file.name)) return false;
      return true;
    });
    setUploadedFiles([...filesToBeAdded]);
    checkForErrors({ addedFile: true });
  };

  const handleFileRemove = (fileToRemove: string): void => {
    const newUploadedFiles = uploadedFiles.filter((file) => {
      return file.name !== fileToRemove;
    });
    setUploadedFiles([...newUploadedFiles]);
  };

  const clearFulfillments = (): void => {
    props.clearFulfillments();
    setIsFulfilling(false);
  };

  const handleSubmit = async (): Promise<void> => {
    if (checkForErrors()) {
      clearFulfillments();
      props.setLoading(true);
      parseFulfillments(uploadedFiles[0])
        .then((data) => {
          props.setFulfillments(data);
          props.setLoading(false);
          setIsFulfilling(true);
          setUploadedFiles([]);
        })
        .catch((error) => {
          setGlobalError(error);
          props.setLoading(false);
        });
    }
  };

  return (
    <>
      <div className="order-header">Order Fulfillment</div>
      {!isFulfilling && (
        <>
          <div className="order-message">
            This is where you can fulfill orders that are in an xls, xlsx, or csv format.
            <br />
            <br />
            The default empty form fulfilling specific items can be found{' '}
            <a href={`${process.env.PUBLIC_URL}/IndividualItemFulfillment.xlsx`} download>
              here,{' '}
            </a>
            and the minimal empty form fulfilling whole orders can be found{' '}
            <a href={`${process.env.PUBLIC_URL}/AllItemFulfillment.xlsx`} download>
              here.
            </a>
          </div>
          <FileDrop
            handleRemoveListItem={handleFileRemove}
            uploadedFiles={uploadedFiles.map((file) => file.name)}
            dropzoneOptions={{
              onDrop: (acceptedFiles: File[]): void => setUploadedFilesToState(acceptedFiles),
              accept:
                // eslint-disable-next-line max-len
                '.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xls, application/vnd.ms-excel',
            }}
            error={formErrors.fileDrop}
          />
          <ToggleSwitch
            label="Ignore Validation?"
            value={ignoreValidation}
            handleChange={(): void => {
              props.setIgnoreValidation(!ignoreValidation);
              setIgnoreValidation(!ignoreValidation);
            }}
            error=""
          />
          <button className="order-submit-button" onClick={handleSubmit}>
            Submit
          </button>
        </>
      )}
      {isFulfilling && (
        <>
          <button className="order-submit-button" onClick={clearFulfillments}>
            Fulfill Other Orders
          </button>
        </>
      )}
    </>
  );
};

export default LeftBar;
