export interface IFormData {
  Title: string;
  InHandsDate: string;
  ScheduledDate: string;
  PromotionCode: string;
  SalesRep: string;
  SiteId: string;
  Items: Item[];
  BillingCompanyName: string;
  BillingEmailAddress: string;
  BillingName: string;
  BillingAddress1: string;
  BillingAddress2: string;
  BillingCity: string;
  BillingState: string;
  BillingZip: string;
  BillingCountry: string;
  ShippingCompanyName: string;
  ShippingEmailAddress: string;
  ShippingName: string;
  ShippingAddress1: string;
  ShippingAddress2: string;
  ShippingCity: string;
  ShippingState: string;
  ShippingZip: string;
  ShippingCountry: string;
  ShippingCharge: string;
  ShipMethod: string;
  ShippingPhone: string;
  ShippingAccount: string;
}

interface Item {
  SKU: string;
  Quantity: number;
}

interface ItemErrors {
  SKU: string;
  Quantity: number;
}

export const defaultFormData: IFormData = {
  Title: '',
  InHandsDate: '',
  ScheduledDate: '',
  PromotionCode: '',
  SalesRep: '',
  SiteId: '',
  Items: [],
  BillingCompanyName: '',
  BillingEmailAddress: '',
  BillingName: '',
  BillingAddress1: '',
  BillingAddress2: '',
  BillingCity: '',
  BillingState: '',
  BillingZip: '',
  BillingCountry: '',
  ShippingCompanyName: '',
  ShippingEmailAddress: '',
  ShippingName: '',
  ShippingAddress1: '',
  ShippingAddress2: '',
  ShippingCity: '',
  ShippingState: '',
  ShippingZip: '',
  ShippingCountry: '',
  ShippingCharge: '',
  ShipMethod: '',
  ShippingPhone: '',
  ShippingAccount: '',
};

export interface IFormErrors {
  Title: string;
  InHandsDate: string;
  ScheduledDate: string;
  PromotionCode: string;
  SalesRep: string;
  SiteId: string;
  Items: ItemErrors[];
  BillingCompanyName: string;
  BillingEmailAddress: string;
  BillingName: string;
  BillingAddress1: string;
  BillingAddress2: string;
  BillingCity: string;
  BillingState: string;
  BillingZip: string;
  BillingCountry: string;
  ShippingCompanyName: string;
  ShippingEmailAddress: string;
  ShippingName: string;
  ShippingAddress1: string;
  ShippingAddress2: string;
  ShippingCity: string;
  ShippingState: string;
  ShippingZip: string;
  ShippingCountry: string;
  ShippingCharge: string;
  ShipMethod: string;
  ShippingPhone: string;
  ShippingAccount: string;
}

export const defaultFormErrors: IFormErrors = {
  Title: '',
  InHandsDate: '',
  ScheduledDate: '',
  PromotionCode: '',
  SalesRep: '',
  SiteId: '',
  Items: [],
  BillingCompanyName: '',
  BillingEmailAddress: '',
  BillingName: '',
  BillingAddress1: '',
  BillingAddress2: '',
  BillingCity: '',
  BillingState: '',
  BillingZip: '',
  BillingCountry: '',
  ShippingCompanyName: '',
  ShippingEmailAddress: '',
  ShippingName: '',
  ShippingAddress1: '',
  ShippingAddress2: '',
  ShippingCity: '',
  ShippingState: '',
  ShippingZip: '',
  ShippingCountry: '',
  ShippingCharge: '',
  ShipMethod: '',
  ShippingPhone: '',
  ShippingAccount: '',
};
