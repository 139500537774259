/* eslint-disable @typescript-eslint/explicit-function-return-type */
import * as React from 'react';
import { useEffect } from 'react';
import { AuthService } from '../shared/dependencies';
import { Redirect } from 'react-router-dom';

const defaultAuthState = {
  error: null,
  isAuthenticated: false,
  user: null,
};

export interface IAuth {
  error: any;
  isAuthenticated: boolean;
  user: any;
}

interface IAuthContext {
  authState: IAuth;
  login: () => void;
  logout: () => void;
  isLoaded: boolean;
  setAuthState: (authState: IAuth) => void;
  setPostLoginRoute: (postLoginRoute: string) => void;
}

const AuthContext = React.createContext<IAuthContext | undefined>(undefined);

interface IProps {
  children: React.ReactNode;
}

export const AuthProvider = ({ children }: IProps) => {
  const [authState, setAuthState] = React.useState(defaultAuthState);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [redirectLocation, setRedirectLocation] = React.useState('');
  const [postLoginRoute, setPostLoginRoute] = React.useState('');

  const login = () => {
    AuthService.login().then((auth) => {
      setAuthState(auth);
      setRedirectLocation(postLoginRoute || '/home');
    });
  };

  const logout = () => {
    AuthService.logout();
    setAuthState(defaultAuthState);
  };

  useEffect(() => {
    const account = AuthService.getAccount();
    if (account) {
      AuthService.getUserProfile().then((auth) => {
        setAuthState(auth);
        setIsLoaded(true);
      });
    } else {
      setIsLoaded(true);
    }
  }, []);

  return (
    <>
      {redirectLocation && <Redirect to={redirectLocation} />}
      <AuthContext.Provider value={{ authState, setAuthState, setPostLoginRoute, login, logout, isLoaded }}>
        {children}
      </AuthContext.Provider>
    </>
  );
};

export const useAuthState = () => React.useContext(AuthContext);
