import React from 'react';
import RequestsTemplate from '../RequestsTemplate/RequestsTemplate';
import { formData, extraFields } from './RequestsArtSettings';

const RequestsITPage: React.FC = () => {
  return (
    <RequestsTemplate
      formName="Art Requests Form"
      formData={formData}
      endpoint="art"
      projects="1200074375870509"
      getAsanaName={(formData: any): string => formData.subject}
      getAsanaNote={(formData: any): string =>
        `${formData.name}
Email: ${formData.email}
Phone: ${formData.phone}

Customer: ${formData.customer}
Item Link:
${formData.itemLink}
Item Color(s):
${formData.itemColor}

Youth Sizes: ${formData.youth}
Garment Sizes: ${formData.garment}
Template Links:
${(formData.templateLinks as any[])?.map((link) => `${link}`)}

Design to be Printed:
${formData.design}

Imprint Location: ${formData.location}
Imprint Size: ${formData.imprintSize}
Imprint Color: ${formData.imprintColor}

Decoration Type: ${formData.decor}
Customer Concerns: ${formData.customerConcern}
Quantity of Production: ${formData.production}

Additional Comments/Notes:
${formData.comments}`
      }
      getAsanaDueOn={(formData: any): string => formData.inHandsDate}
      extraFields={extraFields}
    />
  );
};

export default RequestsITPage;
