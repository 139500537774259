import React from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import RemoveableList from '../RemoveableList/RemoveableList';
import './FileDrop.scss';
import FieldError from '../Field/FieldError/FieldError';

interface IProps {
  dropzoneOptions: DropzoneOptions;
  uploadedFiles: string[];
  handleRemoveListItem: (fileToRemove: string) => void;
  error: string;
}

const FileDrop: React.FC<IProps> = (props) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: props.dropzoneOptions.onDrop,
    accept: props.dropzoneOptions.accept,
  });
  return (
    <div className="file-drop">
      <div className="dropzone" {...getRootProps()}>
        <input className="dropzone-input" {...getInputProps()} />
        {isDragActive ? (
          <div className="dropzone-content dragging">
            <strong>Drop</strong> files here
          </div>
        ) : (
          <div className="dropzone-content">
            <strong>Drag and Drop</strong> or <strong>Click</strong> to select files
          </div>
        )}
      </div>
      <FieldError error={props.error} />
      <RemoveableList listToDisplay={props.uploadedFiles} handleRemoveListItem={props.handleRemoveListItem} />
    </div>
  );
};

export default FileDrop;
