export const formData = [
  {
    type: 'subTitle',
    label: '',
  },
  {
    id: 'name',
    label: 'Name:',
    type: 'textField',
    fakeData: 'John Doe',
    errorMessage: 'Please enter a name',
  },
  {
    id: 'email',
    label: 'Email:',
    type: 'textField',
    fakeData: 'technology@iceboxmail.com',
    errorMessage: 'Please enter a valid email',
    errorValidation: (email: string): boolean => !email || !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email),
  },
  {
    id: 'subject',
    label: 'Subject:',
    type: 'textField',
    fakeData: 'Test Subject',
    errorMessage: 'Please enter a subject',
  },
  {
    id: 'requestType',
    label: 'Request Type?',
    type: 'dropdownField',
    fakeData: 'Internal',
    placeholder: 'Request Type',
    options: ['Internal', 'Customer'],
    errorMessage: 'Please select a request type',
  },
  {
    id: 'distribution',
    label: 'Distribution:',
    type: 'textField',
    fakeData: 'Test Distribution',
    errorMessage: 'Please enter a distribution',
    errorValidation: (): boolean => false,
    isFull: true,
  },
  {
    id: 'priority',
    label: 'How urgent is this request?',
    type: 'dropdownField',
    fakeData: 'High',
    placeholder: 'Priority',
    options: ['Low', 'Med', 'High', 'Urgent'],
    errorMessage: 'Please select a priority',
  },
  {
    id: 'requestRhythm',
    label: 'What is the rhythm of the request?',
    type: 'dropdownField',
    fakeData: 'Other',
    placeholder: 'Request Rhythm',
    options: ['Recurring', 'Ad Hoc', 'Other'],
    errorMessage: 'Please select a request type',
  },
  {
    id: 'cadence',
    label: 'Cadence?',
    type: 'dropdownField',
    fakeData: 'None',
    placeholder: 'Cadence',
    options: ['None', 'Monthly', 'Daily', 'Weekly', 'Bi Weekly', 'Quarterly'],
    errorMessage: 'Please select a candence',
  },
  {
    id: 'dueDate',
    label: '(First) Due Date:',
    type: 'dateField',
    fakeData: '2100-01-01',
    errorMessage: 'Please enter a due date',
  },
  {
    id: 'comments',
    label: 'Description:',
    type: 'textArea',
    fakeData: 'This is test data.',
    errorMessage: 'Please tell us about the issue',
    isFull: true,
  },
  {
    type: 'fileDrop',
  },
];

export const extraFields = {
  priority: {
    asanaId: '937527389619138',
    getIdFromValue: (value: string): string => {
      const priorities = {
        low: '937527389619139',
        med: '937527389619140',
        high: '937527389619141',
        urgent: '1108483653588663',
      } as { [field: string]: string };
      return priorities[value.toLowerCase()];
    },
  },
  email: {
    asanaId: '937447804889185',
  },
  requestType: {
    asanaId: '1201601624280277',
    getIdFromValue: (value: string): string => {
      const requests = {
        internal: '1201601624280278',
        customer: '1201601624280279',
      } as { [field: string]: string };
      return requests[value.toLowerCase().replace(/\s/g, '')];
    },
  },
  requestRhythm: {
    asanaId: '1150095522852160',
    getIdFromValue: (value: string): string => {
      const requests = {
        recurring: '1150095522852161',
        adhoc: '1150095522852162',
        other: '1150095522852163',
      } as { [field: string]: string };
      return requests[value.toLowerCase().replace(/\s/g, '')];
    },
  },
  cadence: {
    asanaId: '1151405041212666',
    getIdFromValue: (value: string): string => {
      const requests = {
        none: '1151405041212667',
        monthly: '1151405041212668',
        daily: '1151405041212669',
        weekly: '1151405041212670',
        quarterly: '1151405041212671',
        biweekly: '1187310722695387',
      } as { [field: string]: string };
      return requests[value.toLowerCase().replace(/\s/g, '')];
    },
  },
  distribution: {
    asanaId: '1150340775824858',
  },
};
