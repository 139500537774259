import React, { useState } from 'react';
import InputTextField from '../../../../components/form/InputTextField';
import { defaultFormErrors, IFormData, IFormErrors } from './OrderFormInterfaces';
import InputDateField from '../../../../components/form/InputDateField';
import { IOrder } from '../../../../shared/endpointAccess/interfaces/orderInterfaces';
import './OrderForm.scss';

const OrderForm: React.FC<{ order: IOrder; onClose?: Function; onSubmit?: Function }> = ({
  order,
  // eslint-disable-next-line
  onClose = (): void => { },
  // eslint-disable-next-line
  onSubmit = (): void => { },
}) => {
  const mapDateToFormDate = (dateString: string): string => {
    return new Date(Date.parse(dateString) || 0).toISOString().split('T')[0];
  };

  const mapOrderToForm = (orderInfo: IOrder): IFormData => {
    return {
      Title: orderInfo.OrderInformation.Title,
      ScheduledDate: mapDateToFormDate(orderInfo.OrderInformation.ScheduledDate.toString()),
      InHandsDate: mapDateToFormDate(orderInfo.OrderInformation.InHandsDate.toString()),
      PromotionCode: orderInfo.OrderInformation.PromotionCode,
      SalesRep: orderInfo.OrderInformation.SalesRep,
      SiteId: orderInfo.OrderInformation.SiteId,
      Items: orderInfo.Items,
      BillingCompanyName: orderInfo.BillingInformation.CompanyName,
      BillingEmailAddress: orderInfo.BillingInformation.EmailAddress,
      BillingName: orderInfo.BillingInformation.Name,
      BillingAddress1: orderInfo.BillingInformation.Address1,
      BillingAddress2: orderInfo.BillingInformation.Address2,
      BillingCity: orderInfo.BillingInformation.City,
      BillingState: orderInfo.BillingInformation.State,
      BillingZip: orderInfo.BillingInformation.Zip,
      BillingCountry: orderInfo.BillingInformation.Country,
      ShippingCompanyName: orderInfo.ShippingInformation.Name,
      ShippingEmailAddress: orderInfo.ShippingInformation.EmailAddress,
      ShippingName: orderInfo.ShippingInformation.Name,
      ShippingAddress1: orderInfo.ShippingInformation.Address1,
      ShippingAddress2: orderInfo.ShippingInformation.Address2,
      ShippingCity: orderInfo.ShippingInformation.City,
      ShippingState: orderInfo.ShippingInformation.State,
      ShippingZip: orderInfo.ShippingInformation.Zip,
      ShippingCountry: orderInfo.ShippingInformation.Country,
      ShippingCharge: orderInfo.ShippingInformation.ShippingCharge,
      ShipMethod: orderInfo.ShippingInformation.ShipMethod,
      ShippingPhone: orderInfo.ShippingInformation.Phone,
      ShippingAccount: orderInfo.ShippingInformation.ShippingAccount,
    };
  };

  const mapDate = (dateString: string): string => {
    const date = new Date(Date.parse(dateString));
    if (date <= new Date(0)) return '';
    return date.toISOString();
  };

  const mapFormToOrder = (orderInfo: IFormData): IOrder => {
    return {
      OrderInformation: {
        Title: order.OrderInformation.Title,
        OrderDate: order.OrderInformation.OrderDate,
        InHandsDate: mapDate(orderInfo.InHandsDate),
        ScheduledDate: mapDate(orderInfo.ScheduledDate),
        PromotionCode: orderInfo.PromotionCode,
        SalesRep: orderInfo.SalesRep,
        SiteId: order.OrderInformation.SiteId,
      },
      Items: order.Items,
      BillingInformation: {
        CompanyName: orderInfo.BillingCompanyName,
        EmailAddress: orderInfo.BillingEmailAddress,
        Name: orderInfo.BillingName,
        Address1: orderInfo.BillingAddress1,
        Address2: orderInfo.BillingAddress2,
        City: orderInfo.BillingCity,
        State: orderInfo.BillingState,
        Zip: orderInfo.BillingZip,
        Country: orderInfo.BillingCountry,
      },
      ShippingInformation: {
        CompanyName: orderInfo.ShippingCompanyName,
        EmailAddress: orderInfo.ShippingEmailAddress,
        Name: orderInfo.ShippingName,
        Address1: orderInfo.ShippingAddress1,
        Address2: orderInfo.ShippingAddress2,
        City: orderInfo.ShippingCity,
        State: orderInfo.ShippingState,
        Zip: orderInfo.ShippingZip,
        Country: orderInfo.ShippingCountry,
        ShippingCharge: orderInfo.ShippingCharge,
        ShipMethod: orderInfo.ShipMethod,
        Phone: order.ShippingInformation.Phone,
        ShippingAccount: order.ShippingInformation.ShippingAccount,
      },
    };
  };

  const [formData, setFormData] = useState<IFormData>(mapOrderToForm(order));
  const [formErrors] = useState<IFormErrors>(defaultFormErrors);

  const handleFormChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    propertyToChange: string,
  ): void => {
    setFormData({ ...formData, [propertyToChange]: e.target.value });
  };

  return (
    <div className="order-form">
      <h2>{formData.Title}</h2>
      <div className="order-form-container">
        <InputTextField
          className="col-12"
          automationId="SalesRep"
          label="SalesRep:"
          value={formData.SalesRep}
          handleChange={(e): void => handleFormChange(e, 'SalesRep')}
          error={formErrors.SalesRep}
        />
        <InputTextField
          className="col-3"
          automationId="PromotionCode"
          label="PromotionCode:"
          value={formData.PromotionCode}
          handleChange={(e): void => handleFormChange(e, 'PromotionCode')}
          error={formErrors.PromotionCode}
        />
        <InputDateField
          className="col-1"
          label="In-Hands Date:"
          value={formData.InHandsDate}
          handleChange={(e): void => handleFormChange(e, 'InHandsDate')}
          error={formErrors.InHandsDate}
        />
        <InputDateField
          className="col-2"
          label="ScheduledDate:"
          value={formData.ScheduledDate}
          handleChange={(e): void => handleFormChange(e, 'ScheduledDate')}
          error={formErrors.ScheduledDate}
        />
        <h3 className="section-title">Billing Information</h3>
        <InputTextField
          className="col-1"
          automationId="BillingCompanyName"
          label="BillingCompanyName:"
          value={formData.BillingCompanyName}
          handleChange={(e): void => handleFormChange(e, 'BillingCompanyName')}
          error={formErrors.BillingCompanyName}
        />
        <InputTextField
          className="col-2"
          automationId="BillingEmailAddress"
          label="BillingEmailAddress:"
          value={formData.BillingEmailAddress}
          handleChange={(e): void => handleFormChange(e, 'BillingEmailAddress')}
          error={formErrors.BillingEmailAddress}
        />
        <InputTextField
          className="col-3"
          automationId="BillingName"
          label="BillingName:"
          value={formData.BillingName}
          handleChange={(e): void => handleFormChange(e, 'BillingName')}
          error={formErrors.BillingName}
        />
        <InputTextField
          className="col-12"
          automationId="BillingAddress1"
          label="BillingAddress1:"
          value={formData.BillingAddress1}
          handleChange={(e): void => handleFormChange(e, 'BillingAddress1')}
          error={formErrors.BillingAddress1}
        />
        <InputTextField
          className="col-3"
          automationId="BillingAddress2"
          label="BillingAddress2:"
          value={formData.BillingAddress2}
          handleChange={(e): void => handleFormChange(e, 'BillingAddress2')}
          error={formErrors.BillingAddress2}
        />
        <InputTextField
          className="col-1"
          automationId="BillingCity"
          label="BillingCity:"
          value={formData.BillingCity}
          handleChange={(e): void => handleFormChange(e, 'BillingCity')}
          error={formErrors.BillingCity}
        />
        <InputTextField
          className="col-2"
          automationId="BillingState"
          label="BillingState:"
          value={formData.BillingState}
          handleChange={(e): void => handleFormChange(e, 'BillingState')}
          error={formErrors.BillingState}
        />
        <InputTextField
          className="col-3"
          automationId="BillingZip"
          label="BillingZip:"
          value={formData.BillingZip}
          handleChange={(e): void => handleFormChange(e, 'BillingZip')}
          error={formErrors.BillingZip}
        />
        <InputTextField
          className="col-1"
          automationId="BillingCountry"
          label="BillingCountry:"
          value={formData.BillingCountry}
          handleChange={(e): void => handleFormChange(e, 'BillingCountry')}
          error={formErrors.BillingCountry}
        />
        <h3 className="section-title">Shipping Information</h3>
        <InputTextField
          className="col-12"
          automationId="ShipMethod"
          label="Ship Via:"
          value={formData.ShipMethod}
          handleChange={(e): void => handleFormChange(e, 'ShipMethod')}
          error={formErrors.ShipMethod}
        />
        <InputTextField
          className="col-3"
          automationId="ShippingCharge"
          label="Shipping Charge:"
          value={formData.ShippingCharge}
          handleChange={(e): void => handleFormChange(e, 'ShippingCharge')}
          error={formErrors.ShippingCharge}
        />
        <InputTextField
          className="col-1"
          automationId="ShippingCompanyName"
          label="ShippingCompanyName:"
          value={formData.ShippingCompanyName}
          handleChange={(e): void => handleFormChange(e, 'ShippingCompanyName')}
          error={formErrors.ShippingCompanyName}
        />
        <InputTextField
          className="col-2"
          automationId="ShippingEmailAddress"
          label="ShippingEmailAddress:"
          value={formData.ShippingEmailAddress}
          handleChange={(e): void => handleFormChange(e, 'ShippingEmailAddress')}
          error={formErrors.ShippingEmailAddress}
        />
        <InputTextField
          className="col-3"
          automationId="ShippingName"
          label="ShippingName:"
          value={formData.ShippingName}
          handleChange={(e): void => handleFormChange(e, 'ShippingName')}
          error={formErrors.ShippingName}
        />
        <InputTextField
          className="col-12"
          automationId="ShippingAddress1"
          label="ShippingAddress1:"
          value={formData.ShippingAddress1}
          handleChange={(e): void => handleFormChange(e, 'ShippingAddress1')}
          error={formErrors.ShippingAddress1}
        />
        <InputTextField
          className="col-3"
          automationId="ShippingAddress2"
          label="ShippingAddress2:"
          value={formData.ShippingAddress2}
          handleChange={(e): void => handleFormChange(e, 'ShippingAddress2')}
          error={formErrors.ShippingAddress2}
        />
        <InputTextField
          className="col-1"
          automationId="ShippingCity"
          label="ShippingCity:"
          value={formData.ShippingCity}
          handleChange={(e): void => handleFormChange(e, 'ShippingCity')}
          error={formErrors.ShippingCity}
        />
        <InputTextField
          className="col-2"
          automationId="ShippingState"
          label="ShippingState:"
          value={formData.ShippingState}
          handleChange={(e): void => handleFormChange(e, 'ShippingState')}
          error={formErrors.ShippingState}
        />
        <InputTextField
          className="col-3"
          automationId="ShippingZip"
          label="ShippingZip:"
          value={formData.ShippingZip}
          handleChange={(e): void => handleFormChange(e, 'ShippingZip')}
          error={formErrors.ShippingZip}
        />
        <InputTextField
          className="col-1"
          automationId="ShippingCountry"
          label="ShippingCountry:"
          value={formData.ShippingCountry}
          handleChange={(e): void => handleFormChange(e, 'ShippingCountry')}
          error={formErrors.ShippingCountry}
        />
      </div>
      <i
        onClick={(): void => {
          onClose();
        }}
        className="fa fa-times inline close-button"
      ></i>
      <button
        className="order-submit-button"
        onClick={(): void => {
          onSubmit(mapFormToOrder(formData));
        }}
      >
        Submit
      </button>
    </div>
  );
};

export default OrderForm;
