import React from 'react';
import FieldLabel from './FieldLabel/FieldLabel';
import FieldError from './FieldError/FieldError';
import './Field.scss';

interface IProps {
  className?: string;
  label: string;
  error?: string;
  isFull?: boolean;
}

const Field: React.FC<IProps> = (props) => {
  const sizeClassName = props.isFull ? 'field-full' : 'field';
  return (
    <div className={`${sizeClassName} ${props.className || ''}`}>
      <FieldLabel label={props.label} isOptional={props.error === undefined} />
      {props.children}
      <FieldError error={props.error} />
    </div>
  );
};

export default Field;
