import React, { useState, useEffect, useCallback } from 'react';
import Penguin from './PenguinName/penguin';
import './EasterEggs.css';

const EasterEggs: React.FC = () => {
  const [showPenguinName, setShowPenguinName] = useState<boolean>(false);

  const handleUserKeyPress = useCallback((event: KeyboardEvent) => {
    const { key, shiftKey, ctrlKey } = event;
    if (key === 'N' && shiftKey && ctrlKey) {
      setShowPenguinName(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);

    return (): void => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);
  return (
    <>
      {showPenguinName && (
        <>
          <button
            className="background"
            onClick={(): void => {
              setShowPenguinName(false);
            }}
          />
          <Penguin />
        </>
      )}
    </>
  );
};

export default EasterEggs;
